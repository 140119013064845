import { QueryClient } from "react-query";

export const isSentryEnabled = process.env.REACT_APP_ENABLE_SENTRY === "true" || process.env.NODE_ENV === "production";

export const isFullstoryEnabled = process.env.NODE_ENV === "test" || location.hostname !== "localhost";

export const firebaseProjectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;

export const autoHideDuration = 5000;

export const isProduction =
  process.env.NODE_ENV === "production" && process.env.REACT_APP_FIREBASE_PROJECT_ID === "me-doit-intl-com";

export const isNordia = process.env.REACT_APP_FIREBASE_PROJECT_ID === "doit-nordia";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const queryKeys = {
  bigQueryGetQuery: "big-query-get-query",
  payerConfigs: "payer-configs",
};
