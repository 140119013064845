import { AppModel, CurrencyCodes, FixerDoc, FixerExchangeRatesModel } from "@doitintl/cmp-models";
import { getCollection, DocumentSnapshotModel } from "@doitintl/models-firestore";
import { DateTime } from "luxon";
import firebase from "firebase/compat/app";
import { Timestamp } from "./firebase";
import DateType from "./dateType";

enum OperatorValue {
  multiplication = "*",
  division = "/",
}

// CurrencyDateType - can be a JS Date or a DateTime or string or firestore timestamp
export type CurrencyDateType = DateTime | Date | string | Timestamp;

type FixerRate = {
  rate: number;
  operator: OperatorValue;
};

export type CurrencyConverterState = {
  loadedYears: number;
};

const getNumberOfLoadedYears = (conversionRates: FixerExchangeRatesModel = {}) => {
  if (!conversionRates || Object.keys(conversionRates).length === 0) {
    return 0;
  }

  const firstDate = Object.keys(conversionRates)
    .map((r) => new Date(r))
    .sort((a, b) => a.getTime() - b.getTime())[0];

  const first = DateTime.fromJSDate(firstDate);
  const last = DateTime.fromJSDate(new Date());
  return last.year - first.year + 1;
};

type Listener = (state: CurrencyConverterState) => void;

export default class CurrencyConverter {
  private static instance: CurrencyConverter;

  // all the currency conversion rates that loaded from firestore
  private _conversionRates: FixerExchangeRatesModel | undefined;

  // Number of years to load from firestore
  private _wantedYears = 0;

  // Number of years/docs loaded from firestore
  private _loadedYears = 0;

  private _lastValidDay = "";

  private _loading = false;

  private listeners: Record<string, Listener> = {};

  public static getInstance(numOfYearsToLoad: number): CurrencyConverter {
    if (!CurrencyConverter.instance) {
      CurrencyConverter.instance = new CurrencyConverter();
      CurrencyConverter.instance._wantedYears = numOfYearsToLoad;
    }

    if (numOfYearsToLoad <= CurrencyConverter.instance._wantedYears) {
      return CurrencyConverter.instance;
    }

    CurrencyConverter.instance._wantedYears = numOfYearsToLoad;
    CurrencyConverter.instance.loadYearsFromFixer(numOfYearsToLoad);

    return CurrencyConverter.instance;
  }

  public on(key: string, listener: Listener) {
    this.listeners[key] = listener;
  }

  public off(key: string) {
    delete this.listeners[key];
  }

  getState(): CurrencyConverterState {
    return {
      loadedYears: this._loadedYears,
    };
  }

  private calculateRate(operator: OperatorValue, rate: number, amount: number): number {
    return operator === OperatorValue.multiplication ? amount * rate : amount / rate;
  }

  isConversionRatesLoaded(): void {
    if (!this._conversionRates) {
      throw new Error("Currency rates not loaded");
    }
  }

  private lastFixerDay(): void {
    this.isConversionRatesLoaded();

    const keyArray = Object.keys(this._conversionRates ?? {}).sort();
    this._lastValidDay = keyArray[keyArray.length - 1];
  }

  private getRate(from: CurrencyCodes, to: CurrencyCodes, formattedDate: string): FixerRate {
    let operator = OperatorValue.multiplication;
    this.isConversionRatesLoaded();

    if (to === CurrencyCodes.USD) {
      operator = OperatorValue.division;
    }
    const selectedRate = operator === OperatorValue.division ? from : to;
    return { rate: this._conversionRates?.[formattedDate][selectedRate], operator };
  }

  listenCurrentYear(): firebase.Unsubscribe {
    const today = DateTime.utc();
    this._loadedYears++;
    return getCollection(AppModel)
      .doc("fixer")
      .subCollection(FixerDoc.ExchangeRates)
      .doc(today.year.toString())
      .onSnapshot((snapshot) => {
        if (snapshot.exists) {
          this._conversionRates = { ...this._conversionRates, ...snapshot.asModelData() };
          this.lastFixerDay();
        }
      });
  }

  /**
   * Load more Years from firestore only on the page that needs it.
   * args: number of years to load in addition to the current loaded years
   * @param wantedYearsAmount
   */
  async loadYearsFromFixer(wantedYearsAmount: number): Promise<void> {
    if (wantedYearsAmount < this._loadedYears) {
      return;
    }

    if (this._loading) {
      return;
    }

    this._loading = true;

    const promises: Promise<DocumentSnapshotModel<FixerExchangeRatesModel>>[] = [];
    const today = DateTime.utc();

    for (let i = this._loadedYears; i < wantedYearsAmount; i++) {
      const year = today.minus({ years: i }).year.toString();
      promises.push(getCollection(AppModel).doc("fixer").subCollection(FixerDoc.ExchangeRates).doc(year).get());
      this._loadedYears++;
    }
    const fixerDocs = await Promise.all(promises);
    this._conversionRates = fixerDocs.reduce((acc, doc) => {
      if (doc.exists) {
        return { ...acc, ...doc.asModelData() };
      }
      return acc;
    }, this._conversionRates ?? {});

    this._loadedYears = getNumberOfLoadedYears(this._conversionRates);
    this._loading = false;

    Object.values(this.listeners).forEach((listener) =>
      listener({
        loadedYears: this._loadedYears,
      })
    );
  }

  /**
   * Load all years from firestore at the start of the app.
   * init will load only the number of years that is set in the constructor.
   * to add more years use loadMoreYears method.
   */
  async load() {
    await this.loadYearsFromFixer(this._wantedYears);
  }

  /**
   * Convert currency amount to the target currecy code by date.
   * Each day have different currency rate so date is required.
   * @param amount
   * @param date
   * @param from
   * @param to
   */
  convert(amount: number, date: CurrencyDateType, from: CurrencyCodes, to: CurrencyCodes): number {
    if (from === to) {
      return amount;
    }

    this.isConversionRatesLoaded();

    let formattedDate = DateType.getFormattedStringDate(date, "yyyy-LL-dd");
    if (!this._conversionRates?.[formattedDate]) {
      // date is not in the fixer
      const currencyDateObject = DateTime.fromFormat(formattedDate, "yyyy-LL-dd");
      if (currencyDateObject.year === DateTime.utc().year) {
        this.lastFixerDay();
        // if the date is in the same year as today then use the last day of the year
        formattedDate = this._lastValidDay;
      } else {
        throw new Error(`${currencyDateObject.toLocaleString()} date is not in the fixer, you can load more years`);
      }
    }

    // convert to USD first and then convert to the target currency
    const tempUSDRate = this.getRate(from, CurrencyCodes.USD, formattedDate);
    const tempAmount = this.calculateRate(tempUSDRate.operator, tempUSDRate.rate, amount);
    const targetToRate = this.getRate(CurrencyCodes.USD, to, formattedDate);
    return this.calculateRate(targetToRate.operator, targetToRate.rate, tempAmount);
  }
}
