// noinspection JSUnusedGlobalSymbols

import { collection, Reference, subCollection, Timestamp } from "@doitintl/models-types";
import type { EmojiData } from "emoji-mart";
import { AccountManagerModel } from "./AccountManager";
import { AssetModel } from "./Asset";
import { ProductEnum } from "./Collection";
import { UpdatedBy } from "./Contract";
import { CurrencyCodes } from "./Currency";
import { DashboardModelAttributionModel, DashboardType } from "./Dashboard";
import { CustomerEnrichment } from "./Enrichment";
import { EntityModel } from "./Entity";
import { ChannelPartnerModel } from "./Partner";
import { RoleModel } from "./Role";
import { CloudAnalyticsModelAttributionGroupsModel } from "./CloudAnalytics";

type SupportedFeature = {
  hasRequiredPermissions: boolean;
  name: string;
};

export enum AccessLevels {
  ORGANIZATION = "organization",
  PROJECT = "project",
}

export type AccessLevel = `${AccessLevels}`;

@subCollection("accountAssumption")
export class CustomerModelAccountAssumption {
  customer!: Reference<CustomerModel>;
  accountAssumptionUntil!: Timestamp;
  requestedBy!: string;
}

@subCollection("cloudServices")
export class CustomerModelCloudServices {
  customer!: Reference<CustomerModel>;
  entity!: Reference<EntityModel>;
  serviceName!: string;
  type!: string;
  costCurrentMonth!: number;
  costLastMonth!: number;
  costLastThreeMonth!: number;
}

export type CloudConnectModelGoogleCloudStandalone = {
  billingAccountId: string;
  customer: Reference<CustomerModel>;
  serviceAccountEmail: string;
  type: "google-cloud-standalone";
};

export type CloudConnectModelAWSStandalone = {
  accountId: string;
  arn: string;
  billing_etl: {
    manifest_file_history?: { [key: string]: Timestamp | null };
    settings: {
      active: boolean;
      bucket: string;
      cur_base_path: string;
      doit_arn: "arn:aws:iam::068664126052:role/doitintl_cmp_prod";
    };
  };
  customer: Reference<CustomerModel>;
  type: "amazon-web-services-standalone";
};

enum CloudConnectAmazonWebServicesErrors {
  Empty = "",
  RoleIsNotValid = "role is not valid",
  UnauthorizedPermissionRequest = "unauthorized permission request",
}

type CloudConnectAmazonWebServicesError = `${CloudConnectAmazonWebServicesErrors}`;

export type CloudConnectAmazonWebServices = {
  accountId: string;
  arn: string;
  cloudPlatform: "amazon-web-services";
  customer: Reference<CustomerModel>;
  error: CloudConnectAmazonWebServicesError;
  roleId: string;
  roleName: string;
  status: number;
  supportedFeatures: SupportedFeature[];
  timeLinked: Timestamp | null;
};

export type CloudConnectGoogleCloud = {
  billing_etl?: {
    manifest_file_history: { [key: string]: Timestamp };
  };
  categoriesStatus: {
    "bigquery-finops": number;
    "bigquery-finops-advanced": number;
    core: number;
    gke_cost_analytics: number;
    governance?: number;
    "rightsizing-implementation": number;
    "rightsizing-recommendation": number;
    sandboxes?: number;
  };
  clientEmail: string;
  clientId: string;
  cloudPlatform: "google-cloud";
  customer: Reference<CustomerModel>;
  key: {
    data: number[];
    type: "Buffer";
  };
  organizations:
    | {
        displayName: string;
        name: string;
      }[]
    | null;
  projectId?: "revolut-prod-apps" | "";
  roleId?: string;
  scope?: "organization" | "project";
  sinkDestination?: string;
  status: number;
  superQueryCostOptimization?: {
    lastCompleteSimulation: string;
    udfs?: Timestamp;
  };
  superQueryCostSimulation?: {
    lastCompleteSimulation: string;
    udfs?: Timestamp;
  };
  workloadIdentityFederationStatus: number;
};

@subCollection("cloudConnect")
export class CustomerModelCloudConnectModel {
  accountId!: string;
  arn!: string;
  cloudPlatform!: "amazon-web-services" | "google-cloud";
  customer!: Reference<CustomerModel>;
  error?: CloudConnectAmazonWebServicesError;
  roleId?: string;
  roleName?: string;
  status!: number;
  supportedFeatures?: SupportedFeature[];
  timeLinked?: Timestamp | null;
  billing_etl?: {
    manifest_file_history?: { [key: string]: Timestamp | null };
    settings?: {
      active: boolean;
      bucket: string;
      cur_base_path: string;
      doit_arn: "arn:aws:iam::068664126052:role/doitintl_cmp_prod";
    };
  };
  categoriesStatus?: {
    "bigquery-finops"?: number;
    "bigquery-finops-advanced"?: number;
    core?: number;
    gke_cost_analytics?: number;
    governance?: number;
    "rightsizing-implementation"?: number;
    "rightsizing-recommendation"?: number;
    sandboxes?: number;
  };
  clientEmail?: string;
  clientId?: string;
  key?: {
    data: number[];
    type: "Buffer";
  };
  organizations?:
    | {
        displayName: string;
        name: string;
      }[]
    | null;
  projectId?: "revolut-prod-apps" | "";
  scope?: "organization" | "project";
  sinkDestination?: string;
  superQueryCostOptimization?: {
    lastCompleteSimulation: string;
    udfs?: Timestamp;
  };
  superQueryCostSimulation?: {
    lastCompleteSimulation: string;
    udfs?: Timestamp;
  };
  workloadIdentityFederationStatus?: number;
  billingAccountId?: string;
  serviceAccountEmail?: string;
  type?: "google-cloud-standalone" | "amazon-web-services-standalone";
}

export type Widget = {
  cardWidth?: number | null;
  name: string;
};

@subCollection("publicDashboards")
export class CustomerModelPublicDashboardsModel {
  ID?: string;
  allowToEdit!: boolean;
  customerId!: string;
  dashboardType?: DashboardType;
  email?: string;
  hasCloudReports?: boolean | number;
  icon?: string | EmojiData | null;
  isPublic?: true;
  name!: string;
  ownerId!: string;
  requiredPermissions?: string[] | null;
  sortNumber?: number;
  widgetHeight?: number | null;
  widgets!: Widget[];
}

export type AccountManagersRole =
  | "account_manager"
  | "customer_engineer"
  | "partner_sales_engineer"
  | "strategic_accounts_manager"
  | "customer_success_manager"
  | "technical_account_manager"
  | "all";

export enum AccountManagerCompanies {
  GCP = "google_cloud_platform",
  AWS = "amazon_web_services",
  MicrosoftAzure = "microsoft_azure",
  DOIT = "doit",
}

export type AccountManagersCompany = `${AccountManagerCompanies}`;

export enum AccountManagerStatuses {
  INIT = "INIT",
  HIRED = "HIRED",
  ACCEPTED = "ACCEPTED",
  ACTIVE = "ACTIVE",
  TERMINATED = "TERMINATED",
}

export type AccountManagersStatus = `${AccountManagerStatuses}`;

@subCollection("customerOrgs")
export class CustomerModelOrganizationModel {
  allowCustomDashboards!: boolean;
  customer!: Reference<CustomerModel>;
  dashboards!: string[];
  description!: string;
  disableAccountDashboard?: boolean;
  lastAccessed?: Timestamp;
  name!: string;
  parent!: Reference<CustomerModelOrganizationModel> | null;
  scope!: Reference<DashboardModelAttributionModel>[];
  timeCreated!: Timestamp;
  timeModified!: Timestamp;
}

@subCollection("slackChannel")
export class CustomerModelSlackChannelModel {
  invitationUrl?: string;
  num_members!: number;
  name!: string;
  notifications!: string[];
  dailyDigests?: Reference<DashboardModelAttributionModel>[];
  anomalySeverity?: number;
  anomalyCost?: number;
}

export type CompanyAccountManager = {
  ref: Reference<AccountManagerModel>;
  notification: number;
};

export enum Classification {
  Business = "business",
  Inactive = "inactive",
  Terminated = "terminated",
  SuspendedForNonPayment = "suspendedForNonPayment",
}

type Alert = {
  trigger: boolean;
  remainingAmount: number;
  lastMonthAmount: number;
};

export type CreditType = ProductEnum.GoogleCloud | ProductEnum.AmazonWebServices;

@subCollection("customerCredits")
export class CustomerModelCustomerCreditsModel {
  alerts?: Record<string, Alert>;
  amount!: number;
  assets!: Reference<AssetModel>[] | null;
  currency!: CurrencyCodes;
  customer!: Reference<CustomerModel>;
  depletionDate!: Timestamp | null;
  endDate!: Timestamp;
  entity!: Reference<EntityModel>;
  metadata!: Record<string, any>;
  name!: string;
  scope?: any[];
  startDate!: Timestamp;
  timestamp!: Timestamp;
  type!: CreditType;
  updatedBy!: UpdatedBy | string;
  utilization!: Record<string, Record<string, number>>;
}

@subCollection("sandboxPolicies")
export class CustomerModelSandboxPoliciesModel {
  action!: string;
  active!: boolean;
  amount!: number;
  billingAccount!: string;
  email!: string;
  folder!: null | SandboxFolder;
  interval!: string;
  limit!: number;
  namePrefix!: string;
  organization!: SandboxOrganization;
  timestamp!: Timestamp;
  type!: string;
}

export type SandboxFolder = {
  displayName: string;
  name: string;
};

export type SandboxOrganization = {
  displayName: string;
  name: string;
};

export type AccountManagers = Partial<
  Record<AccountManagersCompany, Partial<Record<AccountManagersRole, CompanyAccountManager | null>>>
>;

export type AccountTeamMember = {
  company: AccountManagersCompany;
  ref: Reference<AccountManagerModel>;
  supportNotificationLevel: number;
};

export type AccountTeam = AccountTeamMember[];

export type CustomerSettingsInvoicing = {
  maxLineItems: number;
};

export type CustomerSettings = {
  currency: CurrencyCodes;
  invoicing?: CustomerSettingsInvoicing;
  timezone?: string;
};

export enum CreatedBy {
  Superquery = "superquery",
  TrialSignup = "trial-signup",
}

export enum FlexsaveType {
  AWS = "AWS",
  GCP = "GCP",
}

export enum EarlyAccessFeature {
  Governance = "Governance",
  AWS_TCO = "AWS TCO Analysis",
  DASHBOARD_V2 = "Dashboard V2",
  GCP_CLOUD_CONNECT_V2 = "GCP Cloud Connect V2",
  FSGCP_DISABLED = "FSGCP Disabled",
  FSGCP_MARKETPLACE_DISABLED = "FSGCP Marketplace disabled",
  FLEXSAVE_GCP_STANDALONE = "Flexsave GCP Standalone",
  FLEXSAVE_AWS_STANDALONE = "Flexsave AWS Standalone",
  FSAWS_DISABLED = "FSAWS Disabled",
  DAILY_DIGEST = "Daily Digest",
  COUNT_AGGREGATION = "Count Aggregation",
  GKE_COST_ALLOCATION = "GKE Cost Allocation",
  ATTRIBUTION_GROUPS = "Attribution Groups",
  DISABLE_CREDIT_CARD_FEES = "Disable Credit Card Fees",
  ALGOLIA_SEARCH = "Algolia Search",
  THREE_DOTS_MENU_ALL_TABLE = "Three Dots Menu in All Table",
  ACCELERATOR_PROGRAM = "Accelerator Program",
}

@subCollection("customerPricebooks")
export class GCPCustomerPricebooksModel {
  customer!: Reference<CustomerModel>;
  endDate!: Timestamp;
  startDate!: Timestamp;
  entity!: Reference<EntityModel>;
  tableRef!: string;
  type?: string;
  updatedBy?: string;
  metadata?: GCPCustomerPricebookModelMetadata;
}

export type CustomerType = "standalone" | "trial";

export type GCPCustomerPricebookModelMetadata = {
  customer: {
    name: string;
    primaryDomain: string;
  };
  entity: {
    priorityId: string;
  };
};

@subCollection("customerInvoiceAdjustments")
export class CustomerInvoiceAdjustmentModel {
  amount!: number;
  currency!: CurrencyCodes;
  customer!: Reference<CustomerModel>;
  description!: string;
  details!: string;
  entity!: Reference<EntityModel> | null;
  finalized!: boolean;
  invoiceMonths!: Timestamp[];
  metadata!: Record<string, any>;
  timestamp!: Timestamp;
  type!: string;
  updatedBy!: UpdatedBy | string;
}

export type CustomerAuthSso = {
  useSsoWithoutProxy?: boolean;
  saml?: "enabled" | "disabled" | "configured";
  oidc?: "enabled" | "disabled" | "configured";
};

export type CustomerAuthAutoProvision = {
  enabled?: boolean;
  allowedDomains?: string[];
};

export type CustomerAuth = {
  sso?: CustomerAuthSso;
  autoProvision?: CustomerAuthAutoProvision;
};

export const CustomerSecurityMode = {
  NONE: "none",
  RESTRICTED: "restricted",
};

@subCollection("flexsaveStandalone")
export class FlexsaveStandaloneModel {
  billingImportStatus!: {
    maxStartTime: Timestamp;
    maxTotalExecutionTime: Timestamp;
    status: string;
    error?: string;
  };
  customer!: Reference<CustomerModel>;
}

export class Onboarding {
  providers?: ProductEnum[];
  isOnboardingCallDone?: boolean;
}

export class Salesforce {
  id?: string;
  opportunityId?: string;
}

class Marketplace {
  accountExists?: boolean;
}

export class CustomerMarketplaces {
  AWS?: Marketplace;
  GCP?: Marketplace;
}

export enum AuthenticationProvider {
  Google = "google",
  Microsoft = "microsoft",
  Password = "password",
}

type CustomerModelAdminModel = {
  "g-suite"?: string[];
};

@collection("customers")
export class CustomerModel {
  _name!: string;
  accountManager!: null | Reference<AccountManagerModel>;
  accountManagers!: AccountManagers;
  accountTeam?: AccountTeam;
  allowAuthProvider?: null | AuthenticationProvider;
  assets?: ProductEnum[];
  classification?: Classification;
  createdBy?: CreatedBy;
  defaultRole?: Reference<RoleModel>;
  domains!: string[];
  earlyAccessFeatures?: EarlyAccessFeature[];
  enabledFlexsave?: Record<FlexsaveType, boolean>; // flexsave refers here to SaaS flow only
  enrichment!: CustomerEnrichment;
  entities!: Reference<EntityModel>[];
  invoiceAttributionGroup?: Reference<CloudAnalyticsModelAttributionGroupsModel>;
  name!: string;
  onboarding?: Onboarding;
  primaryDomain!: string;
  salesforce?: Salesforce;
  securityMode?: "none" | "restricted";
  selfEarlyAccessFeature?: EarlyAccessFeature[];
  settings?: CustomerSettings;
  sharedDriveFolderId!: null | string;
  skipRemedyBreach?: boolean;
  subscribers?: string[];
  timeCreated?: Timestamp;
  treemapRenderConfig?: string;
  trialEndDate?: Timestamp | null;
  auth?: CustomerAuth;
  type?: CustomerType;
  marketplace?: CustomerMarketplaces;
  gcpCustomerSegment?: string;
  concedefyDisabled?: boolean;
  partnerOfRecord?: Reference<ChannelPartnerModel>;
  static AccountAssumption = CustomerModelAccountAssumption;
  static CloudConnect = CustomerModelCloudConnectModel;
  static PublicDashboards = CustomerModelPublicDashboardsModel;
  static Organizations = CustomerModelOrganizationModel;
  static Credits = CustomerModelCustomerCreditsModel;
  static GCPPricebooks = GCPCustomerPricebooksModel;

  static SandboxPolicies = CustomerModelSandboxPoliciesModel;

  subCollections!: {
    publicDashboards: CustomerModelPublicDashboardsModel;
    cloudConnect: CustomerModelCloudConnectModel;
    customerOrgs: CustomerModelOrganizationModel;
    accountAssumption: CustomerModelAccountAssumption;
    GCPPricebooks: GCPCustomerPricebooksModel;
    customerCredits: CustomerModelCustomerCreditsModel;
    flexsaveStandalone: FlexsaveStandaloneModel;
    customerInvoiceAdjustments: CustomerInvoiceAdjustmentModel;
    slackChannel: CustomerModelSlackChannelModel;
    admins: CustomerModelAdminModel;
    cloudServices: CustomerModelCloudServices;
  };
}
