import { getCollection } from "@doitintl/models-firestore";
import { CustomerToTenantModel, TenantModel } from "@doitintl/cmp-models";
import { firebaseProjectId } from "../../constants";
import { doitCustomerId } from "../customers";

export function customerIdToTenantDocId(customerId: string) {
  switch (customerId) {
    case "amazon_web_services":
      return "amazon.com";
    case "google_cloud_platform":
      return "google.com";
    case "doit":
      return doitCustomerId;
    default:
      return customerId;
  }
}

export async function getTenantId(customerId: string): Promise<string> {
  const actualCustomerId = customerIdToTenantDocId(customerId);
  const customerToTenantSnap = await getCollection(TenantModel)
    .doc(firebaseProjectId)
    .collection("customerToTenant")
    .doc(actualCustomerId)
    .get();

  const tenantId = customerToTenantSnap.get("tenantId");
  if (!tenantId) {
    throw new Error(`could not get tenant id for customer ${actualCustomerId}`);
  }

  return tenantId;
}

export const monitorTenantId = (customerId: string, callback: (data?: CustomerToTenantModel) => void) => {
  const actualCustomerId = customerIdToTenantDocId(customerId);
  getCollection(TenantModel)
    .doc(firebaseProjectId)
    .collection("customerToTenant")
    .doc(actualCustomerId)
    .onSnapshot((docSnapshot) => {
      if (docSnapshot.exists) {
        const data = docSnapshot.data();
        callback(data);
      }
    });
};
