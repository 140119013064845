import { collection, Reference } from "@doitintl/models-types";
import { AccountManagersCompany, AccountManagersRole, AccountManagersStatus, CustomerModel } from "./Customer";

@collection("accountManagers")
export class AccountManagerModel {
  name!: string;
  customer?: {
    name: string;
    _name: string;
    ref: Reference<CustomerModel>;
  };
  company!: AccountManagersCompany;
  email!: string;
  phone?: null | string;
  photoURL?: null | string;
  role!: AccountManagersRole;
  manager?: Reference<AccountManagerModel>;
  status?: AccountManagersStatus;
  lineManager?: string;
}
