import { collection, Reference, Timestamp } from "@doitintl/models-types";
import { CustomerModel } from "./Customer";

type RampPlanCommitmentPeriods = {
  actuals: number[];
  planned: number[];
  startDate: Timestamp;
  endDate: Timestamp;
  value: number;
};

@collection("rampPlans")
export class RampModel {
  customerRef!: Reference<CustomerModel>;
  name!: string;
  attainment!: number;
  active!: boolean;
  platform!: "google-cloud" | "amazon-web-services";
  targetAmount!: number;
  origEstEndDate!: Timestamp;
  startDate!: Timestamp;
  commitmentPeriods!: RampPlanCommitmentPeriods[] | null;
}
