import { collection, subCollection, Timestamp } from "@doitintl/models-types";
import { AssetTypeAmazonWebServices, AssetTypeGoogleCloud, AssetTypeGSuite, AssetTypeMicrosoftAzure } from "./Asset";

export type PerkModule = {
  description: string;
  title: string;
};

export type PerkPlatform = "google-cloud" | "amazon-web-services" | "g-suite" | "microsoft-azure";
export const perkPlatformList: PerkPlatform[] = ["amazon-web-services", "google-cloud", "g-suite", "microsoft-azure"];
export const perkPlatformListShortened: Record<PerkPlatform, string> = {
  [AssetTypeAmazonWebServices]: "aws/platform",
  [AssetTypeGoogleCloud]: "gcp/platform",
  [AssetTypeGSuite]: "gsuite/platform",
  [AssetTypeMicrosoftAzure]: "azure/platform",
};
export type PerkPlatformUrl = Partial<Record<PerkPlatform, string>>;
export type PerkType = "training" | "promotion" | "pro-serv" | "isv-solution" | "workshop";

export type PerkSolutionType =
  | "analytics"
  | "big-data"
  | "developer-tools"
  | "security-and-monitoring"
  | "networking"
  | "ml-and-ai"
  | "database"
  | "compute"
  | "blog-and-cms"
  | "data-sources"
  | "operating-systems"
  | "storage"
  | "maps"
  | "advertising-and-gtm"
  | "other";

export const perkSolutionTypeList: PerkSolutionType[] = [
  "analytics",
  "big-data",
  "developer-tools",
  "security-and-monitoring",
  "networking",
  "ml-and-ai",
  "database",
  "compute",
  "blog-and-cms",
  "data-sources",
  "operating-systems",
  "storage",
  "maps",
  "advertising-and-gtm",
  "other",
];

export type PerkEnrichment = {
  logoUrl?: string;
  providerIsDoitCustomer?: boolean;
};

export type PerkField = {
  active: boolean;
  checklist: string;
  description: string;
  shortDescription?: string;
  title: string;
  name: string;
  platform: PerkPlatform[];
  platformUrl?: PerkPlatformUrl;
  solutionType?: PerkSolutionType[];
  type: PerkType;
  videoUrl?: string;
  deleted: boolean;
  zendeskTags: string[];
  acceleratorProgram?: boolean;
};

@collection("perks")
export class PerkModel {
  agenda!: string;
  enrichment?: PerkEnrichment;
  fields!: PerkField;
  modules?: PerkModule[];
  timeCreated!: Timestamp;
  timeModified!: Timestamp;
  timePublished!: Timestamp | null;
}

@subCollection("requestedUsers")
export class PerkRequestedUsersModel {
  ticketId!: number;
}
