import { collection, doc, Reference, subCollection, Timestamp } from "@doitintl/models-types";
import { CustomerModel, CustomerModelSandboxPoliciesModel } from "./Customer";
import { EntityModel } from "./Entity";

@subCollection("flexibleReservedInstances")
export class IntegrationModelFlexibleReservedInstancesModel {
  id!: number;
  autopilot?: AutopilotData;
  status!: "new" | "active" | "canceled" | "pending" | "retired" | "failed";
  uid!: string;
  customer?: Reference<CustomerModel>;
  utilization!: Record<string, unknown>;
  entity!: Reference<EntityModel>;
  invoiceAdjustments!: Reference<any>;
  metadata!: Record<string, unknown>;
  normalizedUnits!: NormalizedUnits;
  pricing!: Pricing;
}

@subCollection("configuration")
export class FlexsaveConfigurationModel {
  id?: string;
  GCP?: FlexsaveCloudSavingsModel;
  AWS?: AWSFlexsaveConfigurationModel;
}

@subCollection("googleKubernetesEngineTables")
export class IntegrationModelGKETablesModel {
  customer!: Reference<CustomerModel>;
  completedSuccesfully!: boolean | null;
  timeCreated!: Timestamp;
  timeFirstCompleted!: Timestamp | null;
  timeLastCompleted!: Timestamp | null;
  timeUpdated!: Timestamp;
}

@subCollection("sandboxAccounts")
export class IntegrationModelSandboxAccountModel {
  billingAccountResourceName!: string;
  budgetResourceName!: string;
  createdAt!: Timestamp;
  customer!: Reference<CustomerModel>;
  email!: string;
  policy!: Reference<CustomerModelSandboxPoliciesModel>;
  projectId!: string;
  projectNumber!: number;
  projectResourceName!: string;
  status!: SandboxStatus;
  updatedAt!: Timestamp;
  utilization!: Record<string, number>;
}

export enum SandboxStatus {
  ACTIVE = "active",
  DISABLED = "disabled",
  DELETED = "deleted",
  ALERTED = "alerted",
}

export enum Status {
  active = "active",
  disabled = "disabled",
  pending = "pending",
}

export enum Criteria {
  flexsave = "FLEXSAVE",
}

export enum PricebookStatus {
  active = "active",
  retired = "retired",
}

export type DiscountDetails = { discount?: number; effectiveDate?: Date; criteria?: string };

export enum FlexsavePayerType {
  resold = "aws-flexsave-resold",
  standalone = "aws-flexsave-standalone",
}

export enum ManagedType {
  auto = "auto",
  manual = "manual",
}

@subCollection("flexsave-payer-configs")
export class FlexsavePayerConfigs {
  customerId!: string;
  accountId!: string;
  status!: Status;
  type!: FlexsavePayerType;
  managed?: ManagedType;
  discountDetails?: DiscountDetails[];
  lastUpdated!: Date;
  timeEnabled?: Date;
  timeDisabled?: Date;
  maxSpend?: number;
  minSpend?: number;
  targetPercentage?: number;
  primaryDomain?: string;
}

@subCollection("gkeClients")
export class IntegrationModelGKEClients {
  customer!: Reference<CustomerModel>;
  tables!: GkeClientsTable[];
  timestamp!: Timestamp;
}

@subCollection("service-limits")
export class IntegrationModelServiceLimitModel {}

@subCollection("cloudhealthPricebooks")
export class IntegrationModelCloudhealthPricebooksModel {
  assignments!: Assignment[] | null;
  bookName!: string;
  createdAt!: string;
  editable!: boolean;
  fileHash?: string;
  id!: number;
  specification!: Specification;
  updatedAt!: string;
  updatedBy!: string;
  payerId?: string;
  customerId?: string;
  customerName?: string;
  status?: PricebookStatus;
}

@subCollection("stripeCustomers")
export class IntegrationModelStripeCustomerModel {}

export type StandaloneOnboardingAWS = {
  accountId: string;
  savings?: StandaloneEstimationsAWS;
  completed?: boolean;
  agreedToContract?: boolean;
  selectedPriorityId?: string;
  errors?: StandaloneOnboardingErrors;
  timeActivated?: Timestamp;
  isMissingAWSRole?: boolean;
};

@subCollection("fs-onboarding")
export class FlexSaveStandAloneOnBoardingAWSModel {
  accounts?: Record<string, StandaloneOnboardingAWS>;
}

@subCollection("fs-onboarding")
export class FlexsaveStandaloneOnboardingGCPModel {
  type!: "GCP";
  orgId?: string;
  savings?: StandaloneEstimationsGCP;
  serviceAccountEmail?: string;
  completed?: boolean;
  agreedToContract?: boolean;
  selectedPriorityId?: string;
  errors?: StandaloneOnboardingErrors;
  billingAccountId?: string;
}

@doc("flexsave")
export class FlexsaveDoc {
  static Configuration = FlexsaveConfigurationModel;
  static PayerConfigs = FlexsavePayerConfigs;

  totalSavings?: { AWS: FlexsaveTotalSavings };

  subCollections!: {
    configuration: FlexsaveConfigurationModel;
    "gcp-avg-usage": GCPAverageUsage;
    "gcp-global-purchase-rules": GlobalRule;
    "gcp-global-purchase-config": RulesConfig;
    "gcp-total-avg-usage": TotalAverageUsageModal;
    "flexsave-gcp-sku-prices": GCPSkuPricesModal;
    "gcp-ops-workloads": GCPWorkloadModel;
    "gcp-ops-customers-aggr": GCPCustomersAggregationModel;
    "customer-savings-plans": CustomerSavingsPlansModel;
    "gcp-operations": GCPOperations;
  };
}

export class PricebooksAssignmentsModel {
  assigendAt?: Timestamp;
  assignedAt?: Timestamp;
  contractId!: string;
  customerId!: string;
  customerName!: string;
  payerId!: string;
  pricebookId!: number;
}

@doc("cloudhealth")
export class CloudHealthDoc {
  static Pricebooks = IntegrationModelCloudhealthPricebooksModel;
  subCollections!: {
    cloudhealthPricebooks: IntegrationModelCloudhealthPricebooksModel;
    pricebooksAssignments: PricebooksAssignmentsModel;
  };
}

export type GcpRecommendation = {
  category: "COST";
  currentInstance: string;
  description: string;
  duration: "2592000s";
  etag: string;
  instance: string;
  lastRefreshTime: Timestamp;
  name: string;
  projectId: string;
  projectNumber: number;
  recommenderSubtype: "CHANGE_MACHINE_TYPE";
  replaceResource: string;
  saving: string;
  state: "ACTIVE" | "CLAIMED" | "DISMISSED" | "FAILED" | "SUCCEEDED";
  zone: string;
};

@collection("recommender")
export class RecommenderModel {
  customer!: Reference<CustomerModel>;
  isServiceEnabled!: boolean;
  recommendations!: GcpRecommendation[] | null;
  errorMsg?: string;
}

@doc("google-cloud")
export class GoogleCloudDoc {
  static GKETables = IntegrationModelGKETablesModel;
  static SandboxAccounts = IntegrationModelSandboxAccountModel;

  subCollections!: {
    recommender: RecommenderModel;
  };
}

@doc("flexsave-standalone")
export class FlexsaveStandaloneDoc {
  static OnBoardingAWS = FlexSaveStandAloneOnBoardingAWSModel;
  static OnBoardingGCP = FlexsaveStandaloneOnboardingGCPModel;
}

type Party = {
  id: string;
  type: string;
};

export interface HandshakeModel {
  action: string;
  arn: string;
  customer: Reference<CustomerModel>;
  entity: Reference<EntityModel>;
  expirationTimestamp: Timestamp;
  id: string;
  parties: Party[];
  payerAccount: {
    displayName: string;
    id: string;
  };
  requestedTimestamp: Timestamp;
  requester: string;
  state: string;
  target: string;
  timestamp: Timestamp;
  visible: boolean;
}

export class AccountModel {
  id!: string;
  name!: string;
  status!: string;
  arn!: string;
  email!: string;
  joinedMethod!: string;
  joinedTimestamp!: Timestamp;
  timestamp!: Timestamp;
  payerAccount?: Reference<PayerAccount>;
}

type PayerAccount = {
  id: string;
  displayName: string;
};

@doc("amazon-web-services")
export class AmazonWebServicesDoc {
  static FlexibleReservedInstances = IntegrationModelFlexibleReservedInstancesModel;
  subCollections!: {
    handshakes: HandshakeModel;
    accounts: AccountModel;
  };
}

@collection("stripeCustomers")
class StripeCustomerModel {
  email!: string;
  id!: string;
  livemode!: boolean;
  metadata!: {
    customer_id?: string;
    customer_name?: string;
    entity_id?: string;
    priority_id: string;
  };
  timestamp!: Timestamp;
}

@doc("stripe")
export class StripeDoc {
  subCollections!: {
    stripeCustomers: StripeCustomerModel;
  };
}

type UserMessagingToken = {
  displayName?: string;
  email: string;
  token: string;
  topics: string[];
  timestamp: Timestamp;
};

class MessagingDoc {
  subCollections!: {
    tokens: UserMessagingToken;
  };
}

@collection("integrations")
export class IntegrationModel {
  docs!: {
    flexsave: FlexsaveDoc;
    cloudhealth: CloudHealthDoc;
    "google-cloud": GoogleCloudDoc;
    "flexsave-standalone": FlexsaveStandaloneDoc;
    "amazon-web-services": AmazonWebServicesDoc;
    stripe: StripeDoc;
    fcm: MessagingDoc;
  };
}

@doc("zendesk")
export class ZendeskModel {
  timeZones!: Map<string, string>;
}

export type ZendeskCategoryModel = { platforms: string[]; title: string; value: string };
export type ZendeskPlatformModel = {
  asset: string;
  helperText: string;
  label: string;
  order: number;
  title: string;
  value: string;
  resourceDisabled?: boolean;
};

@subCollection("ticketFields")
export class TicketFieldsModel {
  categories!: { values: ZendeskCategoryModel[] };
  platforms!: { values: ZendeskPlatformModel[] };
}

export interface AutopilotData {
  mtdFlexRIPenalty: number;
  mtdFlexRISavings: number;
  mtdFlexRIUtilization: number;
  mtdNonFlexRIUtilization: number;
  utilization: Record<string, unknown>;
}

export interface Pricing {
  savingsPerHourNormalized: number;
  flexibleNormalized: number;
}

export interface NormalizedUnits {
  utilized: number;
  underUtilized: number;
}

export type PurchaseStatus =
  | "pending"
  | "in-progress"
  | "optimized"
  | "error"
  | "covered"
  | "purchase-required"
  | "purchased"
  | "waiting-for-optimizer"
  | "waiting-for-recommendations"
  | "not-enough-data"
  | "refreshing"
  | "rejected";

export type OptInDetails = {
  timeOptIn: Timestamp;
  optInBy: string;
};

export type WorkloadDetails = {
  billingAccountId: string;
  region: string;
  hardware: string;
  type: string;
  currentDoitCoverage: number;
  currentCustomerCoverage: number;
  currentOverallCoverage: number;
  usageOnDemand: number;
};

export type TotalUsageStatistics = {
  totalCuds: number;
  coverage: number;
  doitCuds: number;
  customerShare: number;
};

export type TotalAverageUsageModal = {
  id: string;
  familyType: string;
  hardware: string;
  region: string;
  totalOnDemand: number;
  totalCustomers: number;
} & Omit<TotalUsageStatistics, "customerShare">;

export type GCPSkuPricesModal = {
  cud1Yr: number;
  cud3Yr: number;
  description: string;
  familyType: string;
  hardware: string;
  price: number;
  region: string;
  skuId: string;
  usageType: string;
};

export type PlanDetails = WorkloadDetails & {
  price: number;
  googleRecommendations: number;
};

export type DoneDetails = {
  total: number;
  success: number;
  fail: number;
};

export type PurchasePlan = {
  timeCreated: Timestamp;
  lastRefreshTime: Timestamp;
  timeUpdated: Timestamp;
  timeApproved?: Timestamp;
  status: PurchaseStatus;
  refreshing: boolean;
  errorMessage?: string;
  approvedBy: string;
  cost: number;
  plan: PlanDetails[];
  doneDetails: DoneDetails;
  currentDoitCoverage: number;
  currentOverallCoverage: number;
};

export type GCPAverageUsage = {
  workloads: WorkloadDetails[];
};

@collection("gcp-global-purchase-config")
export class RulesConfig {
  docs!: {
    regions: Region;
    familyTypes: FamilyType;
  };
}

type Region = {
  regions: string[];
};

type FamilyType = {
  familyTypes: string[];
};

export type BaseRule = {
  id?: string;
  region: string[];
  familyType: string[];
  hardware: string[];
  ruleCoverage: number;
};

export type GlobalRule = BaseRule & {
  timeLastUpdate: Timestamp;
  updatedBy: string;
};

export type FlexsaveOperations = {
  primaryDomain: string;
  maxCoverageConsideration: number;
  customerType: string;
  optIn: OptInDetails;
  purchase: PurchasePlan;
  targetCoverages?: number[];
  enableOverrideGlobalRules?: boolean[];
};

export type MonthSavings = {
  savings: number;
  onDemandSpend: number;
  totalOnDemandSpend?: number;
  savingsRate?: number;
};

export type TotalSavings = {
  savings: number;
  savingsRate: number;
};

export type CurrentMonthSavings = {
  monthName: string;
  savings: number;
};

export type CommitTypeRaw = "on-demand" | "commited";

export type FlexsaveCloudSavingsModel = {
  enabled: boolean;
  disableNotified?: boolean;
  reasonCantEnable?: string;
  savingsSummary: {
    annualNetRevenueEstimation: number;
    currentMonth: {
      month: string;
      projectedSavings: number;
    };
    nextMonth: MonthSavings;
    flexsaveEligibleUsageDays?: number;
  };
  savingsHistory: Record<string, MonthSavings>;
  operations?: FlexsaveOperations;
  commitType?: CommitTypeRaw;
};

export type ReasonCantEnableAWS =
  | ""
  | "no contract"
  | "no spend"
  | "low spend"
  | "no assets"
  | "no billing profile"
  | "other"
  | "cloudhealth not configured"
  | "no spend in over thirty days"
  | "aws activate credits"
  | "no contract";

export type AWSFlexsaveConfigurationModel = {
  timeDisabled: Timestamp | null;
  timeEnabled: Timestamp | null;
  reasonCantEnable: ReasonCantEnableAWS;
  enabled: boolean;
  dailySavingsHistory?: Record<string, MonthSavings>;
  savingsSummary: null | {
    currentMonth: {
      month: string;
    };
    nextMonth: MonthSavings;
  };
  savingsHistory: Record<string, MonthSavings> | null;
};

export type Assignment = {
  accountAssignments: AccountAssignment[];
  createdAt: string;
  customerId: number;
  customerName: string;
  id: number;
  pricebookId: number;
  updatedAt: string;
};

export type AccountAssignment = {
  billingAccountOwnerId: string;
  billingAccountOwnerIds: string[] | null;
  customerId: number;
  id: number;
  pricebookAssignmentId: number;
};

export type Specification = {
  comment: string;
  createdBy: string;
  date: string;
  ruleGroups: RuleGroup[] | null;
};

export type RuleGroup = {
  billingRules: BillingRule[];
  enabled: boolean;
  startDate: string;
};

export type BillingRule = {
  basicBillingRule: BasicBillingRule;
  includeDataTransfer: boolean;
  name: string;
  product: Product;
};

export type BasicBillingRule = {
  billingAdjustment: number;
  billingRuleType: string;
};

export type Product = {
  lineItemDescription?: LineItemDescription[];
  productName: string;
  endDate?: string;
  startDate?: string;
  usageType?: string[];
  operation?: string[];
  region?: string[];
};

export type LineItemDescription = {
  contains?: string;
  startsWith?: string;
  matchesRegex?: string;
};

export type GkeClientsTable = {
  dataset: string;
  location: string;
  project: string;
};

export type StandaloneEstimationsGCP = {
  monthlySavings: number;
  annualSavings: number;
};

export type StandaloneOnboardingErrorType =
  | "general"
  | "empty_savings"
  | "insufficient_permissions"
  | "linked_account"
  | "incorrect_cur_file";

export type StandaloneOnboardingError = {
  type: StandaloneOnboardingErrorType;
  message: string;
  timeLastUpdated: Timestamp;
};

export type StandaloneOnboardingErrors = {
  savings?: StandaloneOnboardingError;
  billingProfile?: StandaloneOnboardingError;
  initOnboarding?: StandaloneOnboardingError;
  contractAgreement?: StandaloneOnboardingError;
  activation?: StandaloneOnboardingError;
};

export type StandaloneEstimationsAWS = {
  estimatedSavings: number;
  lastMonthComputeSpend: number;
};

export type FlexsaveTotalSavings = {
  customersNumber: number;
  totalSavings: number;
};

export type PurchaseRisk = {
  causes: {
    customerTotalCost?: number;
    customerShare?: number;
    newTotalCoverage?: number;
    excludeCustomerFromBulk?: boolean;
  };
  approvedAt?: Timestamp;
  approvedBy?: string;
  rejectedAt?: Timestamp;
  rejectedBy?: string;
};

export type GCPWorkloadModel = {
  id: string;
  billingAccountId: string;
  customerId: string;
  familyType: string;
  hardware: string;
  targetCoverage?: number;
  doitRecommendation?: {
    unitCost: number;
    recommendation: number;
    timeUpdated: Timestamp;
  };
  optimizerStats?: {
    currentCudCoverage: number;
    onDemandBaseline: number;
  };
  lastPurchase?: {
    timeExecuted: Timestamp;
    error: string;
  };
  purchaseStats: {
    currentCudCoverage: number;
    onDemandBaseline: number;
    currentDoitCudCoverage: number;
    currentCustomerCudCoverage: number;
  };
  purchasePlan?: {
    amount: number;
    targetCoverage: number;
    cost: number;
    risks?: PurchaseRisk;
    newCustomerShare: number;
    newGlobalDoitCoverage: number;
    newGlobalTotalCoverage: number;
  };
  region: string;
  status: PurchaseStatus;
  timeUpdated?: Timestamp;
  forceTargetCoverage: boolean;
};

export type GCPOpsPageState = {
  bulkRefreshing: boolean;
  excludeCustomersBulkChangedAt: Timestamp;
  latestBulkRefresh: Timestamp;
};

export type GCPBillingProcessState = {
  googleExportDelay: boolean;
  googleExportDelayUpdated: Timestamp;
};

@collection("gcp-operations")
export class GCPOperations {
  docs!: {
    opsPageState: GCPOpsPageState;
    billingProcessState: GCPBillingProcessState;
  };
}

export type GCPCustomersAggregationModel = {
  customerId: string;
  targetCoverage?: number;
  purchaseStats?: {
    currentCudCoverage: number;
  };
  optimizerStats?: {
    currentCudCoverage: number;
  };
  flexCuds?: {
    activeFlexcuds: number;
  }[];
  forceTargetCoverage: boolean;
  bulkPurchase?: {
    excludeCustomerFromBulk: boolean;
    timeUpdated: Timestamp;
  };
};

export type CustomerSavingsPlansModel = {
  savingsPlans: Array<CustomerSavingsPlan>;
};

export type CustomerSavingsPlan = {
  savingsPlanID: string;
  paymentOption: string;
  upfrontPayment: number;
  recurringPayment: number;
  commitment: number;
  mtdSavings: number;
  expirationDate: Timestamp;
};
