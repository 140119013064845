import { collection, Reference, Timestamp } from "@doitintl/models-types";
import { CustomerModel } from "./Customer";

@collection("customerNotes")
export class CustomerNoteModel {
  body!: string;
  timestamp!: Timestamp;
  customer!: Reference<CustomerModel>;
  customerName!: string;
  email!: string | null;
  name!: string | null;
  photoURL!: string | null;
  pinned!: boolean;
  subscribers!: string[];
  tags!: {
    collection: boolean;
    sales: boolean;
    engineering: boolean;
    operations: boolean;
    legal: boolean;
    flexsave: boolean;
    payment: boolean;
    reminder: boolean;
  };
  edited!: null;
  display!: boolean;
}
