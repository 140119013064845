import "reflect-metadata";
const NAME_KEY = Symbol("@coll");

export function collection(className: string) {
  return (Reflect as any).metadata(NAME_KEY, className);
}

export function doc(className: string) {
  return (Reflect as any).metadata(NAME_KEY, className);
}

export const subCollection = collection;

export function getCollectionName(type: any): string {
  return (Reflect as any).getMetadata(NAME_KEY, type);
}
