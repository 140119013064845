import { DateTime } from "luxon";
import { Timestamp } from "./firebase";

// DateTypeValue - can be a JS Date or a DateTime or string or firestore timestamp
export type DateTypeValue = DateTime | Date | string | Timestamp;

export default class DateType {
  static isTimestamp(d: DateTypeValue): d is Timestamp {
    return (d as Timestamp).seconds !== undefined;
  }

  static isDateTime(d: DateTypeValue): d is DateTime {
    return (d as DateTime).day !== undefined;
  }

  static isJSDate(d: DateTypeValue): d is Date {
    return (d as Date).getDay() !== undefined;
  }

  static isDateValid(date: DateTime): void {
    if (!date.isValid) {
      throw new Error("Date is not valid");
    }
  }

  /**
   * getDateTime - get DateTime object from DateTypeValue
   * @param date - DateTime or Date or string
   */
  static getDateTime(date: DateTypeValue): DateTime {
    if (typeof date === "string") {
      return DateTime.fromISO(date);
    }

    if (this.isTimestamp(date)) {
      return DateTime.fromJSDate(new Date(date.seconds * 1000));
    }
    if (this.isDateTime(date)) {
      return date;
    }
    if (this.isJSDate(date)) {
      return DateTime.fromJSDate(date);
    }
    throw new Error("Date is not valid");
  }

  /**
   * getDateTimeFromString - get formatted string from DateTypeValue
   * @param date - DateTime or Date or string
   * @param format - format string
   */
  static getFormattedStringDate(date: DateTypeValue, format: string): string {
    if (typeof date === "string") {
      const dateTime = DateTime.fromISO(date);
      this.isDateValid(dateTime);
      return dateTime.toFormat(format);
    }

    if (this.isTimestamp(date)) {
      const dateTime = DateTime.fromJSDate(date.toDate());
      this.isDateValid(dateTime);
      return dateTime.toFormat(format);
    }

    if (this.isDateTime(date)) {
      this.isDateValid(date);
      return date.toFormat(format);
    }

    if (this.isJSDate(date)) {
      const dateTime = DateTime.fromJSDate(date);
      this.isDateValid(dateTime);
      return dateTime.toFormat(format);
    }

    return date;
  }
}
