import { doc, collection, Reference, subCollection, Timestamp } from "@doitintl/models-types";
import { CustomerModel } from "./Customer";
import { UserModel } from "./User";

export type GcpMarketplaceAccountApprovalState = "PENDING" | "APPROVED" | "REJECTED";

export type GcpMarketplaceAccountApproval = {
  name: "signup";
  state: GcpMarketplaceAccountApprovalState;
  updateTime: Timestamp;
};

export type GcpMarketplaceAccountState = "ACCOUNT_STATE_UNSPECIFIED" | "ACCOUNT_ACTIVE" | "ACCOUNT_DELETED";

export type GcpMarketplaceAccount = {
  approvals: GcpMarketplaceAccountApproval[];
  createTime: Timestamp;
  name: string;
  provider: string;
  state: GcpMarketplaceAccountState;
  updateTime: Timestamp;
};

@subCollection("gcpMarketplaceAccounts")
export class GcpMarketplaceAccountModel {
  billingAccountId?: string;
  billingAccountType?: string;
  customer?: Reference<CustomerModel>;
  procurementAccount?: GcpMarketplaceAccount;
  user?: {
    email: string;
    uid: string;
  };
}

export type GcpMarketplaceEntitlementState =
  | "ENTITLEMENT_STATE_UNSPECIFIED"
  | "ENTITLEMENT_ACTIVATION_REQUESTED"
  | "ENTITLEMENT_ACTIVE"
  | "ENTITLEMENT_PENDING_CANCELLATION"
  | "ENTITLEMENT_CANCELLED"
  | "ENTITLEMENT_PENDING_PLAN_CHANGE"
  | "ENTITLEMENT_PENDING_PLAN_CHANGE_APPROVAL"
  | "ENTITLEMENT_SUSPENDED"
  | "ENTITLEMENT_DELETED";

export type GcpMarketplaceEntitlement = {
  account: string;
  createTime: Timestamp;
  name: string;
  plan: string;
  product: string;
  productExternalName: string;
  provider: string;
  state: GcpMarketplaceEntitlementState;
  updateTime: Timestamp;
  usageReportingId: string;
};

@subCollection("gcpMarketplaceEntitlements")
export class GcpMarketplaceEntitlementModel {
  procurementEntitlement!: GcpMarketplaceEntitlement;
}

export enum GCPMarketplaceAdjustmentStatus {
  PENDING = "PENDING",
  DONE = "DONE",
}

@subCollection("gcpMarketplaceAdjustments")
export class GcpMarketplaceAdjustmentModel {
  createdBy!: string;
  customer!: Reference<CustomerModel>;
  operationId!: string | null;
  status!: GCPMarketplaceAdjustmentStatus;
  targetTime!: Timestamp;
  timeCreated!: Timestamp;
  timeReported!: Timestamp | null;
  timeUpdated!: Timestamp;
  value!: number;
  description!: string;
  transactionFee!: number;
  procurementAccount!: Reference<GcpMarketplaceAccountModel>;
}

@doc("gcp-marketplace")
export class GcpMarketplaceDoc {
  static Accounts = GcpMarketplaceAccountModel;
  static Adjustments = GcpMarketplaceAdjustmentModel;
  static Entitlements = GcpMarketplaceEntitlementModel;
}

export enum AwsMarketplaceAccountStatus {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED",
}

@subCollection("awsMarketplaceAccounts")
export class AwsMarketplaceAccountModel {
  createdAt!: Timestamp;
  createdBy!: Reference<UserModel>;
  updatedAt!: Timestamp;
  status!: AwsMarketplaceAccountStatus;
  customer!: Reference<CustomerModel>;
  awsToken!: string;
  disabledAt?: Timestamp;
  awsAccountData?: {
    CustomerAWSAccountId: string;
    CustomerIdentifier: string;
    ProductCode: string;
  };
}

@doc("aws-marketplace")
export class AwsMarketplaceDoc {
  subCollections!: {
    awsMarketplaceAccounts: AwsMarketplaceAccountModel;
  };
}

@collection("marketplace")
export class MarketplaceModel {
  docs!: {
    "gcp-marketplace": GcpMarketplaceDoc;
    "aws-marketplace": AwsMarketplaceDoc;
  };
}
