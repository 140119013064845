import "reflect-metadata";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

// we need to have the same firestore client js package version as the firestore websdk so we don't
// declare it in package.json as the websdk will install it
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  where,
  or,
  and,
  query,
  QueryCompositeFilterConstraint,
  QueryFilterConstraint,
  QueryConstraint,
  QueryFieldFilterConstraint,
} from "@firebase/firestore";
import {
  getCollectionName,
  TModelCtor,
  Reference,
  Primitive,
  Timestamp,
  WhereFilterOp,
  ArrayPropertyOperatorsSingle,
  BaseFieldValueTypeConvertedType,
  BaseFieldValueType,
  ArrayValueOperators,
  ArrayPropertyOperatorsAny,
  Where,
} from "@doitintl/models-types";
import type { PartialDeep } from "type-fest";

type FirebaseErrorCallback = (error: firebase.firestore.FirestoreError) => void;
export type ErrorCallback = (error: Error) => void;

class LeanDocumentSnapshot {
  constructor(private readonly docSnapshot: firebase.firestore.DocumentSnapshot) {}

  get id(): string {
    return this.docSnapshot.id;
  }

  get exists(): boolean {
    return this.docSnapshot.exists;
  }

  get metadata(): firebase.firestore.SnapshotMetadata {
    return this.docSnapshot.metadata;
  }

  get ref(): firebase.firestore.DocumentReference {
    return this.docSnapshot.ref;
  }

  isEqual(other: firebase.firestore.DocumentSnapshot): boolean {
    return other.isEqual(this.docSnapshot);
  }

  get(fieldPath: string | firebase.firestore.FieldPath, options?: firebase.firestore.SnapshotOptions): any {
    return this.docSnapshot.get(fieldPath, options);
  }

  data(options?: firebase.firestore.SnapshotOptions): firebase.firestore.DocumentData | undefined {
    const data = this.docSnapshot.data(options);

    if (!data) {
      return data;
    }
    return convertorSingleton.toFirestore(data);
  }
}

function getProp(valGetter: { get: (prop: string) => any }, ks: string): any {
  const val = valGetter.get(ks);

  if (!val) {
    return val;
  }

  return convertorSingleton.decodeVal(val);
}

type FieldValueTypeConvertedType<TModel extends firebase.firestore.DocumentData> = BaseFieldValueTypeConvertedType<
  TModel,
  firebase.firestore.Timestamp,
  firebase.firestore.FieldValue,
  firebase.firestore.DocumentReference
>;

export type FieldValueType<TModel extends firebase.firestore.DocumentData> = BaseFieldValueType<
  TModel,
  firebase.firestore.Timestamp,
  firebase.firestore.FieldValue,
  firebase.firestore.DocumentReference
>;

class BaseFirebaseDocumentSnapshotModel<TModel extends firebase.firestore.DocumentData> {
  protected constructor(protected readonly firebaseDoc: firebase.firestore.DocumentSnapshot<TModel>) {}

  get<K1 extends string & keyof TModel>(k1: K1): WithFirebaseModel<TModel[K1]>;
  get<K1 extends string & keyof TModel, K2 extends string & keyof NonNullable<TModel[K1]>>(
    ks: `${K1}.${K2}`
  ): WithFirebaseModel<NonNullable<TModel[K1]>[K2]>;
  get<
    K1 extends string & keyof TModel,
    K2 extends string & keyof NonNullable<TModel[K1]>,
    K3 extends string & keyof NonNullable<NonNullable<TModel[K1]>[K2]>
  >(ks: `${K1}.${K2}.${K3}`): WithFirebaseModel<NonNullable<NonNullable<TModel[K1]>[K2]>[K3]>;
  get<
    K1 extends string & keyof TModel,
    K2 extends string & keyof NonNullable<TModel[K1]>,
    K3 extends string & keyof NonNullable<NonNullable<TModel[K1]>[K2]>,
    K4 extends string & keyof NonNullable<NonNullable<NonNullable<TModel[K1]>[K2]>[K3]>
  >(ks: `${K1}.${K2}.${K3}.${K4}`): WithFirebaseModel<NonNullable<NonNullable<NonNullable<TModel[K1]>[K2]>[K3]>[K4]>;

  get(ks: string): any {
    return getProp(this.firebaseDoc, ks);
  }

  asFirestoreSnapshot(): firebase.firestore.DocumentSnapshot {
    return new LeanDocumentSnapshot(this.firebaseDoc);
  }

  get id(): string {
    return this.firebaseDoc.id;
  }

  get metadata(): firebase.firestore.SnapshotMetadata {
    return this.firebaseDoc.metadata;
  }

  get exists(): boolean {
    return this.firebaseDoc.exists;
  }

  get ref(): firebase.firestore.DocumentReference<TModel> {
    return this.firebaseDoc.ref;
  }

  get modelRef(): FirebaseModelReference<TModel> {
    return new FirebaseModelReference(this.firebaseDoc.ref);
  }
}

export class FirebaseDocumentSnapshotModel<
  TModel extends firebase.firestore.DocumentData
> extends BaseFirebaseDocumentSnapshotModel<TModel> {
  constructor(firebaseDoc: firebase.firestore.DocumentSnapshot<TModel>) {
    super(firebaseDoc);
  }

  // to be used only on legacy code that doesn't store models yet
  get snapshot(): firebase.firestore.DocumentSnapshot<TModel> {
    return this.firebaseDoc;
  }

  asFirestoreData(options?: firebase.firestore.SnapshotOptions): WithFirebase<TModel> | undefined {
    const data = this.firebaseDoc.data(options);

    if (!data) {
      return data;
    }

    const val = convertorSingleton.toFirestore(data);

    if (val) {
      return val as WithFirebase<TModel>;
    }

    return val;
  }

  asModelData(options?: firebase.firestore.SnapshotOptions): WithFirebaseModel<TModel> | undefined {
    const nativeData = this.firebaseDoc.data(options);
    if (!nativeData) {
      return undefined;
    }
    return (convertorSingleton as ModelRefConverter<TModel>).fromFirestoreData(nativeData);
  }

  data(options?: firebase.firestore.SnapshotOptions): WithFirebase<TModel> | undefined {
    return this.asFirestoreData(options);
  }
}

export class FirebaseQueryDocumentSnapshotModel<
  TModel extends firebase.firestore.DocumentData
> extends BaseFirebaseDocumentSnapshotModel<TModel> {
  constructor(private readonly firebaseQueryDoc: firebase.firestore.QueryDocumentSnapshot<TModel>) {
    super(firebaseQueryDoc);
  }

  // to be used only on legacy code that doesn't store models yet
  get snapshot(): firebase.firestore.QueryDocumentSnapshot<TModel> {
    return this.firebaseQueryDoc;
  }

  asFirestoreData(options?: firebase.firestore.SnapshotOptions): WithFirebase<TModel> {
    const val = convertorSingleton.toFirestore(this.firebaseQueryDoc.data(options));

    return val as WithFirebase<TModel>;
  }

  asModelData(options?: firebase.firestore.SnapshotOptions): WithFirebaseModel<TModel> {
    const nativeData = this.firebaseQueryDoc.data(options);
    return (convertorSingleton as ModelRefConverter<TModel>).fromFirestoreData(nativeData);
  }

  data(options?: firebase.firestore.SnapshotOptions): WithFirebase<TModel> {
    return this.asFirestoreData(options);
  }
}

function handleListenError<TModel>(
  listenOn: firebase.firestore.DocumentReference<TModel> | firebase.firestore.Query<TModel>,
  execCallback: (snapshot: any) => void,
  path: string,
  errorCallback: FirebaseErrorCallback | undefined,
  marker: Error,
  options: firebase.firestore.SnapshotListenOptions = {}
): firebase.Unsubscribe {
  const callback = (err: firebase.firestore.FirestoreError) => {
    listenErrorCallbackSingleton?.(path, err, marker);
    if (errorCallback) {
      errorCallback(err);
    }
  };

  if (listenOn instanceof firebase.firestore.DocumentReference) {
    return listenOn.onSnapshot(options, execCallback, callback);
  }

  return listenOn.onSnapshot(options, execCallback, callback);
}

export type FirebaseDocumentSnapshotCallback<TModel extends firebase.firestore.DocumentData> = (
  snapshot: FirebaseDocumentSnapshotModel<TModel>
) => void;

export type FirebaseTimestamp = firebase.firestore.Timestamp;

export type WithFirebase<T> = T extends Primitive
  ? T
  : T extends Timestamp
  ? FirebaseTimestamp
  : T extends Reference<infer Item>
  ? firebase.firestore.DocumentReference<Item>
  : // eslint-disable-next-line @typescript-eslint/ban-types
  T extends {}
  ? {
      [K in keyof T]: WithFirebase<T[K]>;
    }
  : T;

export type WithFirebaseModel<T> = T extends Primitive
  ? T
  : T extends Timestamp
  ? FirebaseTimestamp
  : T extends Reference<infer Item extends firebase.firestore.DocumentData>
  ? FirebaseModelReference<Item>
  : // eslint-disable-next-line @typescript-eslint/ban-types
  T extends {}
  ? {
      [K in keyof T]: WithFirebaseModel<T[K]>;
    }
  : T;

class PathError extends Error {
  constructor(error: any, path: string) {
    super(`PathError: ${error.message} in path: ${path}`);
  }
}

type HaveSubCollections = {
  subCollections: Record<string, any>;
};

type OmitDeclFields<TModel> = Omit<TModel, "docs" | "subCollections">;

type SubCollectionIdOfTModel<TModel> = TModel extends HaveSubCollections
  ? string & keyof TModel["subCollections"]
  : never;

type SubCollectionOfTMModel<TModel, TSubCollectionId> = TModel extends HaveSubCollections
  ? TSubCollectionId extends string
    ? TModel["subCollections"][TSubCollectionId]
    : never
  : never;

export class FirebaseModelReference<TModel extends firebase.firestore.DocumentData> {
  constructor(private readonly docRef: firebase.firestore.DocumentReference<TModel>) {}

  subCollection<TSubCollectionModel extends firebase.firestore.DocumentData>(
    subCollection: TModelCtor<TSubCollectionModel>
  ): FirebaseCollectionReferenceModel<TSubCollectionModel> {
    const collectionName = getCollectionName(subCollection);

    const collectionRef = this.docRef
      .collection(collectionName)
      .withConverter<TSubCollectionModel>(convertorSingleton as ModelRefConverter<TSubCollectionModel>);

    return new FirebaseCollectionReferenceModel<TSubCollectionModel>(collectionRef);
  }

  collection<TSubCollectionModel extends SubCollectionIdOfTModel<TModel>>(
    collectionName: TSubCollectionModel
  ): FirebaseCollectionReferenceModel<SubCollectionOfTMModel<TModel, TSubCollectionModel>> {
    const collectionRef = this.docRef
      .collection(collectionName)
      .withConverter<SubCollectionOfTMModel<TModel, TSubCollectionModel>>(
        convertorSingleton as ModelRefConverter<SubCollectionOfTMModel<TModel, TSubCollectionModel>>
      );

    return new FirebaseCollectionReferenceModel<SubCollectionOfTMModel<TModel, TSubCollectionModel>>(collectionRef);
  }

  onSnapshotWithOptions(
    options: firebase.firestore.SnapshotListenOptions,
    onNext: FirebaseDocumentSnapshotCallback<TModel>,
    onError?: FirebaseErrorCallback
  ): firebase.Unsubscribe {
    const marker = new Error();
    return handleListenError(
      this.docRef,
      (snapshot: firebase.firestore.DocumentSnapshot<TModel>) => onNext(new FirebaseDocumentSnapshotModel(snapshot)),
      this.docRef.path,
      onError,
      marker,
      options
    );
  }

  onSnapshot(onNext: FirebaseDocumentSnapshotCallback<TModel>, onError?: FirebaseErrorCallback): firebase.Unsubscribe {
    return this.onSnapshotWithOptions({}, onNext, onError);
  }

  isEqual(other: FirebaseModelReference<TModel>): boolean {
    return this.docRef.isEqual(other.docRef);
  }

  get ref(): firebase.firestore.DocumentReference<TModel> {
    return this.docRef;
  }

  get id(): string {
    return this.docRef.id;
  }

  get parent(): FirebaseCollectionReferenceModel<TModel> {
    return new FirebaseCollectionReferenceModel(this.docRef.parent);
  }

  get firestore(): firebase.firestore.Firestore {
    return this.docRef.firestore;
  }

  get path(): string {
    return this.docRef.path;
  }

  async get(options?: firebase.firestore.GetOptions): Promise<FirebaseDocumentSnapshotModel<TModel>> {
    const firebaseDoc = await this.docRef.get(options);
    return new FirebaseDocumentSnapshotModel(firebaseDoc);
  }

  async delete(): Promise<void> {
    return this.docRef.delete();
  }

  update(data: Partial<FieldValueType<TModel>>): Promise<void>;

  update(
    field: string & keyof TModel,
    value: FieldValueTypeConvertedType<TModel[typeof field]>,
    ...moreFieldsAndValues: any[]
  ): Promise<void>;

  update<K1 extends string & keyof TModel, K2 extends string & keyof NonNullable<TModel[K1]>>(
    field: `${K1}.${K2}`,
    value: FieldValueTypeConvertedType<NonNullable<TModel[K1]>[K2]>,
    ...moreFieldsAndValues: any[]
  ): Promise<void>;

  update<
    K1 extends string & keyof TModel,
    K2 extends string & keyof NonNullable<TModel[K1]>,
    K3 extends string & keyof NonNullable<NonNullable<TModel[K1]>[K2]>
  >(
    field: `${K1}.${K2}.${K3}`,
    value: FieldValueTypeConvertedType<NonNullable<NonNullable<TModel[K1]>[K2]>[K3]>,
    ...moreFieldsAndValues: any[]
  ): Promise<void>;

  update<
    K1 extends string & keyof TModel,
    K2 extends string & keyof NonNullable<TModel[K1]>,
    K3 extends string & keyof NonNullable<NonNullable<TModel[K1]>[K2]>,
    K4 extends string & keyof NonNullable<NonNullable<NonNullable<TModel[K1]>[K2]>[K3]>
  >(
    field: `${K1}.${K2}.${K3}.${K4}`,
    value: FieldValueTypeConvertedType<NonNullable<NonNullable<NonNullable<TModel[K1]>[K2]>[K3]>[K4]>,
    ...moreFieldsAndValues: any[]
  ): Promise<void>;

  update(
    fieldOrData: Partial<FieldValueType<TModel>> | string,
    value?: any,
    ...moreFieldsAndValues: any[]
  ): Promise<void> {
    const convertData = (v: any) => convertorSingleton.toFirestore(v as TModel);

    const updaterWithCustomMessage = async (path: string, callback: () => Promise<void>) => {
      try {
        return await callback();
      } catch (err) {
        throw new PathError(err, path);
      }
    };

    if (typeof fieldOrData !== "string") {
      const convertedData = convertData(fieldOrData);
      return updaterWithCustomMessage(this.docRef.path, () => this.docRef.update(convertedData));
    }

    const keyValuesPair = moreFieldsAndValues.map((val, index) => {
      if (index % 2 === 0) {
        return val;
      }

      return convertData(val);
    });

    return updaterWithCustomMessage(this.docRef.path, () =>
      this.docRef.update(fieldOrData, convertData(value), ...keyValuesPair)
    );
  }

  set(data: FieldValueType<OmitDeclFields<TModel>>): Promise<void>;
  set(data: Partial<FieldValueType<TModel>>, options: firebase.firestore.SetOptions): Promise<void>;

  async set(
    data: Partial<FieldValueType<TModel>> | FieldValueType<OmitDeclFields<TModel>>,
    options: firebase.firestore.SetOptions = {}
  ): Promise<void> {
    const marker = new Error();
    try {
      return await this.docRef.set(data as Partial<TModel>, options);
    } catch (error: any) {
      listenErrorCallbackSingleton?.(this.docRef.path, error, marker);
      throw error;
    }
  }

  narrow<TNarrow extends PartialDeep<TModel>>() {
    return new FirebaseModelReference<TNarrow>(this.docRef as firebase.firestore.DocumentReference<any>);
  }
}

export class FirebaseDocumentChangeModel<TModel extends firebase.firestore.DocumentData> {
  constructor(private readonly snapshotObj: firebase.firestore.DocumentChange<TModel>) {}

  get type(): firebase.firestore.DocumentChangeType {
    return this.snapshotObj.type;
  }

  get doc(): FirebaseQueryDocumentSnapshotModel<TModel> {
    return new FirebaseQueryDocumentSnapshotModel<TModel>(this.snapshotObj.doc);
  }

  get oldIndex(): number {
    return this.snapshotObj.oldIndex;
  }

  get newIndex(): number {
    return this.snapshotObj.newIndex;
  }
}

type FirebaseQueryDocumentSnapshotCallback<TModel extends firebase.firestore.DocumentData> = (
  snapshot: FirebaseQueryDocumentSnapshotModel<TModel>
) => void;

export class FirebaseQuerySnapshotModel<TModel extends firebase.firestore.DocumentData> {
  constructor(private readonly querySnapshot: firebase.firestore.QuerySnapshot<TModel>) {}

  get size(): number {
    return this.querySnapshot.size;
  }

  get empty(): boolean {
    return this.querySnapshot.empty;
  }

  get docs(): Array<FirebaseQueryDocumentSnapshotModel<TModel>> {
    return this.querySnapshot.docs.map((currentDoc) => new FirebaseQueryDocumentSnapshotModel<TModel>(currentDoc));
  }

  docChanges(options?: firebase.firestore.SnapshotListenOptions): Array<FirebaseDocumentChangeModel<TModel>> {
    return this.querySnapshot.docChanges(options).map((snapshot) => new FirebaseDocumentChangeModel<TModel>(snapshot));
  }

  forEach(callback: FirebaseQueryDocumentSnapshotCallback<TModel>, thisArg?: any): void {
    return this.querySnapshot.forEach(
      (snapshot) => callback(new FirebaseQueryDocumentSnapshotModel(snapshot)),
      thisArg
    );
  }
}

export type FirebaseQuerySnapshotCallback<TModel extends firebase.firestore.DocumentData> = (
  snapshot: FirebaseQuerySnapshotModel<TModel>
) => void;

export class Filter<
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  TModel extends firebase.firestore.DocumentData,
  TFilter extends QueryCompositeFilterConstraint | QueryConstraint = QueryConstraint
> {
  protected constructor(private readonly queryFilterConstraint: TFilter) {}
  get queryCompositeFilterConstraint(): TFilter {
    return this.queryFilterConstraint;
  }

  static where<TModel extends firebase.firestore.DocumentData>(
    fieldPath: firebase.firestore.FieldPath,
    opStr: ArrayValueOperators,
    value: unknown[]
  ): Filter<TModel, QueryFieldFilterConstraint>;

  static where<TModel extends firebase.firestore.DocumentData, K1 extends string & keyof TModel>(
    fieldPath: K1,
    opStr: ArrayValueOperators,
    value: FieldValueTypeConvertedType<TModel[K1]>[]
  ): Filter<TModel, QueryFieldFilterConstraint>;

  static where<
    TModel extends firebase.firestore.DocumentData,
    K1 extends string & keyof TModel,
    K2 extends string & keyof NonNullable<TModel[K1]>
  >(
    fieldPath: `${K1}.${K2}`,
    opStr: ArrayValueOperators,
    value: FieldValueTypeConvertedType<NonNullable<NonNullable<TModel[K1]>[K2]>>[]
  ): Filter<TModel, QueryFieldFilterConstraint>;

  static where<
    TModel extends firebase.firestore.DocumentData,
    K1 extends string & keyof TModel,
    K2 extends string & keyof NonNullable<TModel[K1]>,
    K3 extends string & keyof NonNullable<NonNullable<TModel[K1]>[K2]>
  >(
    fieldPath: `${K1}.${K2}.${K3}`,
    opStr: ArrayValueOperators,
    value: FieldValueTypeConvertedType<NonNullable<NonNullable<NonNullable<TModel[K1]>[K2]>[K3]>>[]
  ): Filter<TModel, QueryFieldFilterConstraint>;

  static where<
    TModel extends firebase.firestore.DocumentData,
    K1 extends string & keyof TModel,
    K2 extends string & keyof NonNullable<TModel[K1]>,
    K3 extends string & keyof NonNullable<NonNullable<TModel[K1]>[K2]>,
    K4 extends string & keyof NonNullable<NonNullable<NonNullable<TModel[K1]>[K2]>[K3]>
  >(
    fieldPath: `${K1}.${K2}.${K3}.${K4}`,
    opStr: ArrayValueOperators,
    value: FieldValueTypeConvertedType<NonNullable<NonNullable<NonNullable<NonNullable<TModel[K1]>[K2]>[K3]>[K4]>>[]
  ): Filter<TModel, QueryFieldFilterConstraint>;

  static where<TModel extends firebase.firestore.DocumentData>(
    fieldPath: firebase.firestore.FieldPath,
    opStr: ArrayPropertyOperatorsAny,
    value: unknown[]
  ): Filter<TModel, QueryFieldFilterConstraint>;

  static where<TModel extends firebase.firestore.DocumentData, K1 extends string & keyof TModel>(
    fieldPath: K1,
    opStr: ArrayPropertyOperatorsAny,
    value: NonNullable<TModel[K1]> extends (infer U)[] | any ? U[] : never
  ): Filter<TModel, QueryFieldFilterConstraint>;

  static where<
    TModel extends firebase.firestore.DocumentData,
    K1 extends string & keyof TModel,
    K2 extends string & keyof NonNullable<TModel[K1]>
  >(
    fieldPath: `${K1}.${K2}`,
    opStr: ArrayPropertyOperatorsAny,
    value: NonNullable<NonNullable<TModel[K1]>[K2]> extends (infer U)[] | any ? U[] : never
  ): Filter<TModel, QueryFieldFilterConstraint>;

  static where<
    TModel extends firebase.firestore.DocumentData,
    K1 extends string & keyof TModel,
    K2 extends string & keyof NonNullable<TModel[K1]>,
    K3 extends string & keyof NonNullable<NonNullable<TModel[K1]>[K2]>
  >(
    fieldPath: `${K1}.${K2}.${K3}`,
    opStr: ArrayPropertyOperatorsAny,
    value: NonNullable<NonNullable<NonNullable<TModel[K1]>[K2]>[K3]> extends (infer U)[] | any ? U[] : never
  ): Filter<TModel, QueryFieldFilterConstraint>;

  static where<
    TModel extends firebase.firestore.DocumentData,
    K1 extends string & keyof TModel,
    K2 extends string & keyof NonNullable<TModel[K1]>,
    K3 extends string & keyof NonNullable<NonNullable<TModel[K1]>[K2]>,
    K4 extends string & keyof NonNullable<NonNullable<NonNullable<TModel[K1]>[K2]>[K3]>
  >(
    fieldPath: `${K1}.${K2}.${K3}.${K4}`,
    opStr: ArrayPropertyOperatorsAny,
    value: NonNullable<NonNullable<NonNullable<NonNullable<TModel[K1]>[K2]>[K3]>[K4]> extends (infer U)[] | any
      ? U[]
      : never
  ): Filter<TModel, QueryFieldFilterConstraint>;

  static where<TModel extends firebase.firestore.DocumentData>(
    fieldPath: firebase.firestore.FieldPath,
    opStr: ArrayPropertyOperatorsSingle,
    value: unknown[]
  ): Filter<TModel, QueryFieldFilterConstraint>;
  static where<TModel extends firebase.firestore.DocumentData, K1 extends string & keyof TModel>(
    fieldPath: K1,
    opStr: ArrayPropertyOperatorsSingle,
    value: NonNullable<TModel[K1]> extends (infer U)[] | any ? U : never
  ): Filter<TModel, QueryFieldFilterConstraint>;

  static where<
    TModel extends firebase.firestore.DocumentData,
    K1 extends string & keyof TModel,
    K2 extends string & keyof NonNullable<TModel[K1]>
  >(
    fieldPath: `${K1}.${K2}`,
    opStr: ArrayPropertyOperatorsSingle,
    value: NonNullable<NonNullable<TModel[K1]>[K2]> extends (infer U)[] | any ? U : never
  ): Filter<TModel, QueryFieldFilterConstraint>;

  static where<
    TModel extends firebase.firestore.DocumentData,
    K1 extends string & keyof TModel,
    K2 extends string & keyof NonNullable<TModel[K1]>,
    K3 extends string & keyof NonNullable<NonNullable<TModel[K1]>[K2]>
  >(
    fieldPath: `${K1}.${K2}.${K3}`,
    opStr: ArrayPropertyOperatorsSingle,
    value: NonNullable<NonNullable<NonNullable<TModel[K1]>[K2]>[K3]> extends (infer U)[] | any ? U[] : never
  ): Filter<TModel, QueryFieldFilterConstraint>;

  static where<
    TModel extends firebase.firestore.DocumentData,
    K1 extends string & keyof TModel,
    K2 extends string & keyof NonNullable<TModel[K1]>,
    K3 extends string & keyof NonNullable<NonNullable<TModel[K1]>[K2]>,
    K4 extends string & keyof NonNullable<NonNullable<NonNullable<TModel[K1]>[K2]>[K3]>
  >(
    fieldPath: `${K1}.${K2}.${K3}.${K4}`,
    opStr: ArrayPropertyOperatorsSingle,
    value: NonNullable<NonNullable<NonNullable<NonNullable<TModel[K1]>[K2]>[K3]>[K4]> extends (infer U)[] | any
      ? U
      : never
  ): Filter<TModel, QueryFieldFilterConstraint>;

  static where<TModel extends firebase.firestore.DocumentData>(
    fieldPath: firebase.firestore.FieldPath,
    opStr: WhereFilterOp,
    value: unknown
  ): Filter<TModel, QueryFieldFilterConstraint>;

  static where<TModel extends firebase.firestore.DocumentData, K1 extends string & keyof TModel>(
    fieldPath: K1,
    opStr: WhereFilterOp,
    value: FieldValueTypeConvertedType<TModel[K1]>
  ): Filter<TModel, QueryFieldFilterConstraint>;

  static where<
    TModel extends firebase.firestore.DocumentData,
    K1 extends string & keyof TModel,
    K2 extends string & keyof NonNullable<TModel[K1]>
  >(
    fieldPath: `${K1}.${K2}`,
    opStr: WhereFilterOp,
    value: FieldValueTypeConvertedType<NonNullable<NonNullable<TModel[K1]>[K2]>>
  ): Filter<TModel, QueryFieldFilterConstraint>;

  static where<
    TModel extends firebase.firestore.DocumentData,
    K1 extends string & keyof TModel,
    K2 extends string & keyof NonNullable<TModel[K1]>,
    K3 extends string & keyof NonNullable<NonNullable<TModel[K1]>[K2]>
  >(
    fieldPath: `${K1}.${K2}.${K3}`,
    opStr: WhereFilterOp,
    value: FieldValueTypeConvertedType<NonNullable<NonNullable<NonNullable<TModel[K1]>[K2]>[K3]>>
  ): Filter<TModel, QueryFieldFilterConstraint>;

  static where<
    TModel extends firebase.firestore.DocumentData,
    K1 extends string & keyof TModel,
    K2 extends string & keyof NonNullable<TModel[K1]>,
    K3 extends string & keyof NonNullable<NonNullable<TModel[K1]>[K2]>,
    K4 extends string & keyof NonNullable<NonNullable<NonNullable<TModel[K1]>[K2]>[K3]>
  >(
    fieldPath: `${K1}.${K2}.${K3}.${K4}`,
    opStr: WhereFilterOp,
    value: FieldValueTypeConvertedType<NonNullable<NonNullable<NonNullable<NonNullable<TModel[K1]>[K2]>[K3]>[K4]>>
  ): Filter<TModel, QueryFieldFilterConstraint>;

  static where<TModel extends firebase.firestore.DocumentData = firebase.firestore.DocumentData>(
    fieldPath: string | firebase.firestore.FieldPath,
    opStr: firebase.firestore.WhereFilterOp,
    value: unknown
  ): Filter<TModel, QueryFieldFilterConstraint> {
    return new Filter<TModel, QueryFieldFilterConstraint>(where(fieldPath, opStr, value));
  }

  static or<TModel extends firebase.firestore.DocumentData>(
    ...queryConstraints: Filter<TModel, QueryFilterConstraint>[]
  ) {
    return new Filter<TModel, QueryCompositeFilterConstraint>(
      or(...queryConstraints.map((queryConstraint) => queryConstraint.queryCompositeFilterConstraint))
    );
  }

  static and<TModel extends firebase.firestore.DocumentData = firebase.firestore.DocumentData>(
    ...queryConstraints: Filter<TModel, QueryFilterConstraint>[]
  ) {
    return new Filter<TModel, QueryCompositeFilterConstraint>(
      and(...queryConstraints.map((queryConstraint) => queryConstraint.queryCompositeFilterConstraint))
    );
  }
}

interface WhereComposite<TModel extends firebase.firestore.DocumentData, TResult> {
  where(compositeFilter: Filter<TModel, QueryCompositeFilterConstraint>): TResult;
}

export class FirebaseQueryModel<TModel extends firebase.firestore.DocumentData>
  implements
    Where<TModel, FirebaseQueryModel<TModel>, firebase.firestore.FieldPath, firebase.firestore.DocumentReference>,
    WhereComposite<TModel, FirebaseQueryModel<TModel>>
{
  constructor(
    private readonly collectionName: string,
    private readonly firebaseQuery: firebase.firestore.Query<TModel>
  ) {}

  isEqual(other: FirebaseQueryModel<TModel>): boolean {
    return this.firebaseQuery.isEqual(other.firebaseQuery);
  }

  where(
    fieldPathOrCompositeFilter: firebase.firestore.FieldPath | string | Filter<TModel, QueryCompositeFilterConstraint>,
    opStr?: firebase.firestore.WhereFilterOp,
    value?: unknown
  ): FirebaseQueryModel<TModel> {
    if (fieldPathOrCompositeFilter instanceof Filter) {
      const whereExpQuery = query<TModel>(
        (this.firebaseQuery as any)._delegate,
        fieldPathOrCompositeFilter.queryCompositeFilterConstraint
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const whereQuery = new firebase.firestore.Query<TModel>(this.firestore, whereExpQuery);
      return new FirebaseQueryModel<TModel>(this.collectionName, whereQuery);
    }

    const actualValue = convertorSingleton.toFirestore(value as TModel);

    const whereQuery = this.firebaseQuery.where(
      fieldPathOrCompositeFilter,
      opStr as NonNullable<typeof opStr>,
      actualValue
    );

    return new FirebaseQueryModel<TModel>(this.collectionName, whereQuery);
  }

  limit(limitResult: number): FirebaseQueryModel<TModel> {
    const limitQuery = this.firebaseQuery.limit(limitResult);

    return new FirebaseQueryModel<TModel>(this.collectionName, limitQuery);
  }

  orderBy<K1 extends string & keyof TModel>(
    fieldPath: K1,
    direction?: firebase.firestore.OrderByDirection
  ): FirebaseQueryModel<TModel>;

  orderBy<K1 extends string & keyof TModel, K2 extends string & keyof TModel[K1]>(
    fieldPath: `${K1}.${K2}`,
    direction?: firebase.firestore.OrderByDirection
  ): FirebaseQueryModel<TModel>;

  orderBy(fieldPath: string, direction?: firebase.firestore.OrderByDirection): FirebaseQueryModel<TModel> {
    const orderByQuery = this.firebaseQuery.orderBy(fieldPath, direction);

    return new FirebaseQueryModel<TModel>(this.collectionName, orderByQuery);
  }

  onSnapshotWithOptions(
    options: firebase.firestore.SnapshotListenOptions,
    onNext: FirebaseQuerySnapshotCallback<TModel>,
    onError?: FirebaseErrorCallback
  ): firebase.Unsubscribe {
    const marker = new Error();
    return handleListenError(
      this.firebaseQuery,
      (snapshot: firebase.firestore.QuerySnapshot<TModel>) => onNext(new FirebaseQuerySnapshotModel(snapshot)),
      this.collectionName,
      onError,
      marker,
      options
    );
  }

  onSnapshot(onNext: FirebaseQuerySnapshotCallback<TModel>, onError?: FirebaseErrorCallback): firebase.Unsubscribe {
    return this.onSnapshotWithOptions({}, onNext, onError);
  }

  async get(options?: firebase.firestore.GetOptions): Promise<FirebaseQuerySnapshotModel<TModel>> {
    const marker = new Error();
    try {
      const firebaseQuery = await this.firebaseQuery.get(options);
      return new FirebaseQuerySnapshotModel<TModel>(firebaseQuery);
    } catch (err: any) {
      listenErrorCallbackSingleton?.(this.collectionName, err, marker);
      throw err;
    }
  }

  get firestore(): firebase.firestore.Firestore {
    return this.firebaseQuery.firestore;
  }

  narrow<TNarrow extends PartialDeep<TModel>>() {
    return new FirebaseQueryModel<TNarrow>(this.collectionName, this.firebaseQuery as firebase.firestore.Query<any>);
  }
}

type HaveDocs = {
  docs: Record<string, any>;
};

type DocIdOfTModel<TModel> = TModel extends HaveDocs ? string & keyof TModel["docs"] : string;

type DocModelOfTModel<TModel, TDocId extends string> = TModel extends HaveDocs ? TModel["docs"][TDocId] : TModel;

export class FirebaseCollectionReferenceModel<
  TModel extends firebase.firestore.DocumentData
> extends FirebaseQueryModel<TModel> {
  constructor(private readonly firebaseCollection: firebase.firestore.CollectionReference<TModel>) {
    super(firebaseCollection.path, firebaseCollection);
  }

  isEqual(other: FirebaseCollectionReferenceModel<TModel>): boolean {
    return this.firebaseCollection.isEqual(other.firebaseCollection);
  }

  doc<TDocId extends DocIdOfTModel<TModel>>(
    documentPath?: TDocId
  ): FirebaseModelReference<DocModelOfTModel<TModel, TDocId>> {
    const firebaseCollection = this.firebaseCollection as firebase.firestore.CollectionReference<
      DocModelOfTModel<TModel, TDocId>
    >;
    const docRef = documentPath ? firebaseCollection.doc(documentPath) : firebaseCollection.doc();
    return new FirebaseModelReference<DocModelOfTModel<TModel, TDocId>>(docRef);
  }

  async add(data: FieldValueType<OmitDeclFields<TModel>>): Promise<FirebaseModelReference<TModel>> {
    const marker = new Error();
    try {
      const firebaseDoc = await this.firebaseCollection.add(data as any);
      return new FirebaseModelReference(firebaseDoc);
    } catch (err: any) {
      listenErrorCallbackSingleton?.(this.firebaseCollection.path, err, marker);
      throw err;
    }
  }

  get path(): string {
    return this.firebaseCollection.path;
  }

  get id(): string {
    return this.firebaseCollection.id;
  }
}

class ModelRefConverter<TModel extends firebase.firestore.DocumentData> {
  protected constructor() {
    // this is expected
  }

  static create<TModel extends firebase.firestore.DocumentData>() {
    return new ModelRefConverter<TModel>();
  }

  static encodeObj(obj: any, encodeFunc: (val: any) => any, result?: any): any {
    if (obj) {
      result = result || {};

      Object.keys(obj).forEach((key) => {
        const val = obj[key];
        if (val === undefined) {
          return;
        }
        result[key] = encodeFunc(val);
      });

      return result;
    }

    return obj;
  }

  private static isPlainObject(val: any) {
    return val && typeof val === "object" && Object.getPrototypeOf(val) === Object.prototype;
  }

  private static isDocumentReference(val: any) {
    return val?._delegate?.type === "document";
  }

  private static isArrayRemoveOrUnion(val: any, encodeVal: (val: any) => any) {
    if (!val) {
      return;
    }

    if (
      val._delegate?._methodName !== "FieldValue.arrayRemove" &&
      val._delegate?._methodName !== "FieldValue.arrayUnion"
    ) {
      return;
    }

    const [[elementsKey, elementsVal]] = Object.entries(val._delegate).filter(([key]) => key !== "_methodName");

    val._delegate._elements = encodeVal(val._delegate?._elements);
    val._delegate[elementsKey] = encodeVal(elementsVal);

    return val;
  }

  toFirestore(data: TModel): firebase.firestore.DocumentData {
    function encodeVal(val: any): any {
      if (val instanceof FirebaseModelReference) {
        return (val as FirebaseModelReference<TModel>).ref;
      }

      if (Array.isArray(val)) {
        return val.map((v: any) => encodeVal(v));
      }

      if (ModelRefConverter.isArrayRemoveOrUnion(val, encodeVal)) {
        return val;
      }

      if (ModelRefConverter.isDocumentReference(val)) {
        return val;
      }

      if (ModelRefConverter.isPlainObject(val)) {
        return ModelRefConverter.encodeObj(val, encodeVal);
      }

      return val;
    }

    return encodeVal(data);
  }

  decodeVal(val: any): any {
    if (ModelRefConverter.isDocumentReference(val)) {
      return new FirebaseModelReference(val);
    }

    if (Array.isArray(val)) {
      // We need to verify that no array value contains a document transform
      return val.map((v) => this.decodeVal(v));
    }

    if (ModelRefConverter.isPlainObject(val)) {
      return ModelRefConverter.encodeObj(val, (decodedVal) => this.decodeVal(decodedVal));
    }

    return val;
  }

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): TModel {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const data = snapshot.data(options)!;

    return ModelRefConverter.encodeObj(data, (val) => this.decodeVal(val)) as TModel;
  }

  fromFirestoreData<IDField extends string = "", RefField extends string = "">(
    data: firebase.firestore.DocumentData,
    creator?: { new (): TModel }
  ): ModelData<TModel, IDField, RefField> {
    return ModelRefConverter.encodeObj(data, (val) => this.decodeVal(val), creator ? new creator() : {});
  }
}

type ListenErrorCallback = (path: string, err: Error, marker: Error) => void;

const convertorSingleton = ModelRefConverter.create();

let firestoreSingleton: firebase.firestore.Firestore | null;
let listenErrorCallbackSingleton: ListenErrorCallback | null;

export function setFirestoreSingleton(
  singleton: firebase.firestore.Firestore | null,
  { listenErrorCallback }: { listenErrorCallback?: ListenErrorCallback } = {}
) {
  listenErrorCallbackSingleton = listenErrorCallback || listenErrorCallbackSingleton;
  firestoreSingleton = singleton;
}

function getDefaultClientIfNeeded(firestoreInstance: firebase.firestore.Firestore | undefined) {
  if (firestoreInstance) {
    return firestoreInstance;
  }

  if (!firestoreSingleton) {
    throw new Error("setFirestoreSingleton() was not called");
  }

  return firestoreSingleton;
}

export function getModelReference<TModel extends firebase.firestore.DocumentData>(
  ref: firebase.firestore.DocumentReference
): FirebaseModelReference<TModel> {
  return new FirebaseModelReference<TModel>(ref as firebase.firestore.DocumentReference<TModel>);
}

export type ModelData<
  T extends firebase.firestore.DocumentData,
  IDField extends string | void = void,
  RefField extends string | void = void
> = IDField extends string
  ? RefField extends string
    ? WithFirebaseModel<T> & Record<IDField, string> & Record<RefField, FirebaseModelReference<T>>
    : WithFirebaseModel<T> & Record<IDField, string>
  : RefField extends string
  ? WithFirebaseModel<T> & Record<RefField, FirebaseModelReference<T>>
  : WithFirebaseModel<T>;

export type IDOptions<IDField extends string = "", RefField extends string = ""> = {
  idField?: IDField;
  refField?: RefField;
};

export type FirebaseIdRef<TModel extends firebase.firestore.DocumentData> = WithFirebase<TModel> & {
  id: string;
  ref: firebase.firestore.DocumentReference<TModel>;
};

export type ModelIdRef<TModel extends firebase.firestore.DocumentData> = ModelData<TModel, "id", "ref">;
export type ModelId<TModel extends firebase.firestore.DocumentData> = ModelData<TModel, "id">;
export type ModelDataRef<TModel extends firebase.firestore.DocumentData> = ModelData<TModel, "", "ref">;
export type ModelDataId<TModel extends firebase.firestore.DocumentData> = ModelData<TModel, "id">;

export type ModelWithIdRef<TModel extends firebase.firestore.DocumentData> = TModel & ModelIdRef<TModel>;
export type ModelWithIdRefData<TModel extends firebase.firestore.DocumentData> = ModelIdRef<TModel> & {
  data: TModel;
};

interface Updater {
  update(documentRef: firebase.firestore.DocumentReference<any>, data: FieldValueType<any>): this;
  update(
    documentRef: firebase.firestore.DocumentReference<any>,
    field: string | firebase.firestore.FieldPath,
    value: unknown,
    ...moreFieldsAndValues: any[]
  ): this;
}

function updateEntity<TResult extends Updater, TModel extends firebase.firestore.DocumentData>(
  callee: TResult,
  documentRef: firebase.firestore.DocumentReference<TModel> | FirebaseModelReference<TModel>,
  fieldOrData: Partial<FieldValueType<TModel> | TModel> | string,
  value?: any,
  ...moreFieldsAndValues: any[]
): TResult {
  const actualRef = documentRef instanceof FirebaseModelReference ? documentRef.ref : documentRef;

  if (typeof fieldOrData === "string") {
    const convertedData = convertorSingleton.toFirestore(value as TModel);
    return callee.update(actualRef, fieldOrData, convertedData, ...moreFieldsAndValues);
  }

  const convertedData = convertorSingleton.toFirestore(fieldOrData as TModel);
  return callee.update(actualRef, convertedData);
}

interface Setter {
  set<TModel extends firebase.firestore.DocumentData>(
    documentRef: FirebaseModelReference<TModel> | firebase.firestore.DocumentReference<TModel>,
    data: FieldValueType<OmitDeclFields<TModel>>
  ): this;

  set<TModel extends firebase.firestore.DocumentData>(
    documentRef: FirebaseModelReference<TModel> | firebase.firestore.DocumentReference<TModel>,
    data: FieldValueType<OmitDeclFields<TModel>>,
    options: firebase.firestore.SetOptions
  ): this;
}

function setEntity<TResult extends Setter, TModel extends firebase.firestore.DocumentData>(
  callee: TResult,
  documentRef: FirebaseModelReference<TModel> | firebase.firestore.DocumentReference<TModel>,
  data: Partial<FieldValueType<TModel>> | FieldValueType<OmitDeclFields<TModel>>,
  options?: firebase.firestore.SetOptions
): TResult {
  const actualRef = documentRef instanceof FirebaseModelReference ? documentRef.ref : documentRef;

  const convertedData = convertorSingleton.toFirestore(data as TModel);

  if (options) {
    return callee.set(actualRef, convertedData as any, options);
  }

  return callee.set(actualRef, convertedData as any);
}

export class FirebaseModelWriteBatch {
  constructor(private readonly batch: firebase.firestore.WriteBatch) {}

  set<TModel extends firebase.firestore.DocumentData>(
    documentRef: FirebaseModelReference<TModel> | firebase.firestore.DocumentReference<TModel>,
    data: FieldValueType<TModel>
  ): FirebaseModelWriteBatch;
  set<TModel extends firebase.firestore.DocumentData>(
    documentRef: FirebaseModelReference<TModel> | firebase.firestore.DocumentReference<TModel>,
    data: Partial<FieldValueType<TModel>>,
    options: firebase.firestore.SetOptions
  ): FirebaseModelWriteBatch;

  set<TModel extends firebase.firestore.DocumentData>(
    documentRef: FirebaseModelReference<TModel> | firebase.firestore.DocumentReference<TModel>,
    data: FieldValueType<TModel> | Partial<FieldValueType<TModel>>,
    options?: firebase.firestore.SetOptions
  ): FirebaseModelWriteBatch {
    const batch = setEntity(this.batch, documentRef, data, options);

    return new FirebaseModelWriteBatch(batch);
  }

  update<TModel extends firebase.firestore.DocumentData, K1 extends string & keyof TModel>(
    documentRef: firebase.firestore.DocumentReference<TModel> | FirebaseModelReference<TModel>,
    fieldPath: K1,
    value: FieldValueTypeConvertedType<TModel[K1]>
  ): FirebaseModelWriteBatch;

  update<
    TModel extends firebase.firestore.DocumentData,
    K1 extends string & keyof TModel,
    K2 extends string & keyof NonNullable<TModel[K1]>
  >(
    documentRef: firebase.firestore.DocumentReference<TModel> | FirebaseModelReference<TModel>,
    fieldPath: `${K1}.${K2}`,
    value: FieldValueTypeConvertedType<NonNullable<TModel[K1]>[K2]>
  ): FirebaseModelWriteBatch;

  update<
    TModel extends firebase.firestore.DocumentData,
    K1 extends string & keyof TModel,
    K2 extends string & keyof NonNullable<TModel[K1]>,
    K3 extends string & keyof NonNullable<NonNullable<TModel[K1]>[K2]>
  >(
    documentRef: firebase.firestore.DocumentReference<TModel> | FirebaseModelReference<TModel>,
    fieldPath: `${K1}.${K2}.${K3}`,
    value: FieldValueTypeConvertedType<NonNullable<NonNullable<TModel[K1]>[K2]>[K3]>
  ): FirebaseModelWriteBatch;

  update<
    TModel extends firebase.firestore.DocumentData,
    K1 extends string & keyof TModel,
    K2 extends string & keyof NonNullable<TModel[K1]>,
    K3 extends string & keyof NonNullable<NonNullable<TModel[K1]>[K2]>,
    K4 extends string & keyof NonNullable<NonNullable<NonNullable<TModel[K1]>[K2]>[K3]>
  >(
    documentRef: firebase.firestore.DocumentReference<TModel> | FirebaseModelReference<TModel>,
    fieldPath: `${K1}.${K2}.${K3}.${K4}`,
    value: FieldValueTypeConvertedType<NonNullable<NonNullable<NonNullable<TModel[K1]>[K2]>[K3]>[K4]>
  ): FirebaseModelWriteBatch;

  update<TModel extends firebase.firestore.DocumentData>(
    documentRef: firebase.firestore.DocumentReference<TModel> | FirebaseModelReference<TModel>,
    data: Partial<FieldValueType<TModel> | TModel>
  ): FirebaseModelWriteBatch;

  update<TModel extends firebase.firestore.DocumentData>(
    documentRef: firebase.firestore.DocumentReference<TModel> | FirebaseModelReference<TModel>,
    fieldOrData: Partial<FieldValueType<TModel> | TModel> | string,
    value?: any,
    ...moreFieldsAndValues: any[]
  ): FirebaseModelWriteBatch {
    const batch = updateEntity(this.batch, documentRef, fieldOrData, value, ...moreFieldsAndValues);

    return new FirebaseModelWriteBatch(batch);
  }

  delete(documentRef: FirebaseModelReference<any>): FirebaseModelWriteBatch;
  delete<TModel>(documentRef: firebase.firestore.DocumentReference<TModel>): FirebaseModelWriteBatch;

  delete<TModel>(
    documentRef:
      | firebase.firestore.DocumentReference<TModel>
      | FirebaseModelReference<firebase.firestore.DocumentReference>
  ): FirebaseModelWriteBatch {
    const actualRef = documentRef instanceof FirebaseModelReference ? documentRef.ref : documentRef;

    return new FirebaseModelWriteBatch(this.batch.delete(actualRef));
  }

  commit(): Promise<void> {
    return this.batch.commit();
  }
}

export class TransactionModel {
  constructor(private readonly transaction: firebase.firestore.Transaction) {}

  async get<TModel extends firebase.firestore.DocumentData>(
    documentRef: FirebaseModelReference<TModel> | firebase.firestore.DocumentReference<TModel>
  ): Promise<FirebaseDocumentSnapshotModel<TModel>> {
    const actualRef = documentRef instanceof FirebaseModelReference ? documentRef.ref : documentRef;

    return new FirebaseDocumentSnapshotModel(await this.transaction.get(actualRef));
  }

  delete<TModel extends firebase.firestore.DocumentData>(
    documentRef: FirebaseModelReference<TModel> | firebase.firestore.DocumentReference<TModel>
  ): TransactionModel {
    const actualRef = documentRef instanceof FirebaseModelReference ? documentRef.ref : documentRef;

    return new TransactionModel(this.transaction.delete(actualRef));
  }

  update<TModel extends firebase.firestore.DocumentData, K1 extends string & keyof TModel>(
    documentRef: FirebaseModelReference<TModel> | firebase.firestore.DocumentReference<TModel>,
    field: K1,
    value: TModel[K1],
    ...moreFieldsAndValues: any[]
  ): TransactionModel;

  update<
    TModel extends firebase.firestore.DocumentData,
    K1 extends string & keyof TModel,
    K2 extends string & keyof NonNullable<TModel[K1]>
  >(
    documentRef: FirebaseModelReference<TModel> | firebase.firestore.DocumentReference<TModel>,
    fieldPath: `${K1}.${K2}`,
    value: FieldValueTypeConvertedType<NonNullable<TModel[K1]>[K2]>,
    ...moreFieldsAndValues: any[]
  ): TransactionModel;

  update<
    TModel extends firebase.firestore.DocumentData,
    K1 extends string & keyof TModel,
    K2 extends string & keyof NonNullable<TModel[K1]>,
    K3 extends string & keyof NonNullable<NonNullable<TModel[K1]>[K2]>
  >(
    documentRef: FirebaseModelReference<TModel> | firebase.firestore.DocumentReference<TModel>,
    fieldPath: `${K1}.${K2}.${K3}`,
    value: FieldValueTypeConvertedType<NonNullable<NonNullable<TModel[K1]>[K2]>[K3]>,
    ...moreFieldsAndValues: any[]
  ): TransactionModel;
  update<TModel extends firebase.firestore.DocumentData>(
    documentRef: FirebaseModelReference<TModel> | firebase.firestore.DocumentReference<TModel>,
    data: FieldValueType<Partial<TModel>>
  ): TransactionModel;

  update<TModel extends firebase.firestore.DocumentData>(
    documentRef: FirebaseModelReference<TModel> | firebase.firestore.DocumentReference<TModel>,
    fieldOrData: Partial<FieldValueType<TModel>> | (string & keyof TModel),
    value?: any,
    ...moreFieldsAndValues: any[]
  ): TransactionModel {
    const tx = updateEntity(this.transaction, documentRef, fieldOrData, value, ...moreFieldsAndValues);

    return new TransactionModel(tx);
  }

  set<TModel extends firebase.firestore.DocumentData>(
    documentRef: FirebaseModelReference<TModel> | firebase.firestore.DocumentReference<TModel>,
    data: FieldValueType<OmitDeclFields<TModel>>
  ): TransactionModel;

  set<TModel extends firebase.firestore.DocumentData>(
    documentRef: FirebaseModelReference<TModel> | firebase.firestore.DocumentReference<TModel>,
    data: Partial<FieldValueType<TModel>>,
    options: firebase.firestore.SetOptions
  ): TransactionModel;

  set<TModel extends firebase.firestore.DocumentData>(
    documentRef: FirebaseModelReference<TModel> | firebase.firestore.DocumentReference<TModel>,
    data: Partial<FieldValueType<TModel>> | FieldValueType<OmitDeclFields<TModel>>,
    options?: firebase.firestore.SetOptions
  ): TransactionModel {
    const tx = setEntity(this.transaction, documentRef, data, options);

    return new TransactionModel(tx);
  }
}

export async function runTransaction<TModel>(
  updateFunction: (transaction: TransactionModel) => Promise<TModel>,
  firestoreInstance?: firebase.firestore.Firestore
): Promise<TModel> {
  const firestore = getDefaultClientIfNeeded(firestoreInstance);

  return firestore.runTransaction((transaction) => {
    const transactionWrap = new TransactionModel(transaction);
    return updateFunction(transactionWrap);
  });
}

export function asFirestoreModel<TModel extends firebase.firestore.DocumentData>(
  data: firebase.firestore.DocumentData | undefined | null,
  creator: { new (): TModel }
): WithFirebaseModel<TModel> | undefined | null {
  if (!data) {
    return data;
  }

  return (convertorSingleton as ModelRefConverter<TModel>).fromFirestoreData(data, creator);
}

function addIdRef<
  TModel extends firebase.firestore.DocumentData,
  IDField extends string = "",
  RefField extends string = ""
>(
  modelData: TModel,
  doc: firebase.firestore.DocumentSnapshot<TModel | firebase.firestore.DocumentData>,
  options?: IDOptions<IDField, RefField>
) {
  if (options?.idField) {
    (modelData as any)[options.idField] = doc.id;
  }

  if (options?.refField) {
    (modelData as any)[options.refField] = new FirebaseModelReference(doc.ref);
  }

  return modelData;
}

export function asFirestoreModelFromDocument<
  TModel extends firebase.firestore.DocumentData,
  IDField extends string = "",
  RefField extends string = ""
>(
  documentSnapshot: firebase.firestore.DocumentSnapshot<TModel | firebase.firestore.DocumentData>,
  creator: { new (): TModel },
  options?: IDOptions<IDField, RefField>
): ModelData<TModel, IDField, RefField> | undefined {
  const data = documentSnapshot.data();
  if (!data) {
    return undefined;
  }

  const modelData = (convertorSingleton as ModelRefConverter<TModel>).fromFirestoreData<IDField, RefField>(
    data,
    creator
  );

  return addIdRef(modelData, documentSnapshot, options);
}

export function asFirestoreModelFromSnapshot<
  TModel extends firebase.firestore.DocumentData,
  IDField extends string = "",
  RefField extends string = ""
>(
  querySnapshot: firebase.firestore.QueryDocumentSnapshot<TModel | firebase.firestore.DocumentData>,
  creator: { new (): TModel },
  options?: IDOptions<IDField, RefField>
): ModelData<TModel, IDField, RefField> {
  const modelData = (convertorSingleton as ModelRefConverter<TModel>).fromFirestoreData<IDField, RefField>(
    querySnapshot.data(),
    creator
  );

  return addIdRef(modelData, querySnapshot, options);
}

export function asModelRefFromFirestoreRef<TModel extends firebase.firestore.DocumentData>(
  ref: firebase.firestore.DocumentReference
): FirebaseModelReference<TModel> {
  return new FirebaseModelReference<TModel>(ref as firebase.firestore.DocumentReference<TModel>);
}

export const modelFromPath = <TModel extends firebase.firestore.DocumentData>(
  path: string,
  firestoreInstance?: firebase.firestore.Firestore
): FirebaseModelReference<TModel> => {
  const firestoreClient = getDefaultClientIfNeeded(firestoreInstance);

  return new FirebaseModelReference<TModel>(firestoreClient.doc(path) as firebase.firestore.DocumentReference<TModel>);
};

export function getCollection<TModel extends firebase.firestore.DocumentData>(
  collectionType: TModelCtor<TModel>,
  firestoreInstance?: firebase.firestore.Firestore
): FirebaseCollectionReferenceModel<TModel> {
  const collectionName = getCollectionName(collectionType);
  const firestoreClient = getDefaultClientIfNeeded(firestoreInstance);

  const firestoreCollection = firestoreClient
    .collection(collectionName)
    .withConverter<TModel>(convertorSingleton as ModelRefConverter<TModel>);

  return new FirebaseCollectionReferenceModel<TModel>(firestoreCollection);
}

export function getCollectionGroup<TModel extends firebase.firestore.DocumentData>(
  collectionType: TModelCtor<TModel>,
  firestoreInstance?: firebase.firestore.Firestore
): FirebaseQueryModel<TModel> {
  const collectionName = getCollectionName(collectionType);

  const firestore = getDefaultClientIfNeeded(firestoreInstance);

  const firebaseQuery = firestore
    .collectionGroup(collectionName)
    .withConverter(convertorSingleton) as firebase.firestore.CollectionReference<TModel>;

  return new FirebaseQueryModel<TModel>(collectionName, firebaseQuery);
}

export function getBatch(firestoreInstance?: firebase.firestore.Firestore): FirebaseModelWriteBatch {
  const firebaseClient = getDefaultClientIfNeeded(firestoreInstance);

  return new FirebaseModelWriteBatch(firebaseClient.batch());
}

export function docChangesToToArray<TModel extends firebase.firestore.DocumentData, TValue>(
  querySnapshot: FirebaseQuerySnapshotModel<TModel>,
  processValue: (docSnapshot: FirebaseQueryDocumentSnapshotModel<TModel>) => TValue,
  items: TValue[]
) {
  querySnapshot.docChanges().forEach((change) => {
    switch (change.type) {
      case "added":
        items.splice(change.newIndex, 0, processValue(change.doc));
        break;

      case "modified":
        items.splice(change.newIndex, 1, processValue(change.doc));
        break;

      case "removed":
        items.splice(change.oldIndex, 1);
        break;
    }
  });
}

// iterate query documents using a callback
export function forEachDoc<TModel extends firebase.firestore.DocumentData>(
  querySnapshot: FirebaseQuerySnapshotModel<TModel>,
  processValue: (docSnapshot: FirebaseQueryDocumentSnapshotModel<TModel>) => void
) {
  querySnapshot.docs.forEach((firebaseDoc) => {
    processValue(firebaseDoc);
  });
}

// map a query documents using a callback
export function mapDocsToArray<TModel extends firebase.firestore.DocumentData, TValue>(
  querySnapshot: FirebaseQuerySnapshotModel<TModel>,
  processValue: (docSnapshot: FirebaseQueryDocumentSnapshotModel<TModel>) => TValue
) {
  return querySnapshot.docs.map((firebaseDoc) => processValue(firebaseDoc));
}

// convert TModel into firestore document data
export function convertModel(data: any) {
  return convertorSingleton.toFirestore(data);
}
