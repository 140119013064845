import firebase from "firebase/compat/app";
import { QueryDocumentSnapshotModel, QuerySnapshotModel } from "@doitintl/models-firestore";

export function arrayFromDocChange<T extends firebase.firestore.DocumentData, TReturn>(
  arr: TReturn[],
  querySnapshot: QuerySnapshotModel<T>,
  addProps: (item: QueryDocumentSnapshotModel<T>) => TReturn
) {
  querySnapshot.docChanges().forEach((change) => {
    if (change.type === "added" || change.type === "modified") {
      const deleteCount = change.type === "added" ? 0 : 1;
      const item = addProps(change.doc);

      arr.splice(change.newIndex, deleteCount, item);
    } else if (change.type === "removed") {
      arr.splice(change.oldIndex, 1);
    }
  });
}
