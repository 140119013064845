import { collection, subCollection, Timestamp } from "@doitintl/models-types";
import { CurrencyCode, OldCurrency } from "./Currency";

@subCollection("services")
export class CatalogModelServiceModel {
  id!: number;
  payment!: "MONTHLY" | "YEARLY";
  plan!: "ANNUAL" | "FLEXIBLE";
  prevPrice?: Record<OldCurrency, number>;
  price!: { USD: number; GBP: number; EUR: number; AUD: number } & Partial<Record<CurrencyCode, number>>;
  prevPriceEndDate?: Timestamp;
  skuId!: string;
  skuName!: string;
  skuPriority!: string;
  type!: "OFFLINE" | "ONLINE";
}

@collection("catalog")
export class CatalogModel {
  static Services = CatalogModelServiceModel;
}
