import { collection, Reference, Timestamp } from "@doitintl/models-types";
import { AccountManagerModel } from "./AccountManager";
import { MasterPayerAccountsModel } from "./App";
import { AssetModel } from "./Asset";
import { CustomerModel } from "./Customer";
import { EntityModel } from "./Entity";
import { VendorContractModel } from "./VendorContract";

export type UpdatedBy = {
  email: string;
  name: string;
};

type ContractUpdates = {
  type: string;
  timestamp: Timestamp;
};

@collection("contracts")
export class ContractModel {
  accountManager!: Reference<AccountManagerModel>;
  active!: boolean;
  assets?: Reference<AssetModel>[];
  commitmentPeriods?: CommitmentPeriod[];
  commitmentRollover?: boolean;
  contractFile!: ContractFile;
  credits?: Credits;
  customer!: Reference<CustomerModel>;
  discount!: number;
  discountEndDate?: Timestamp;
  endDate!: Timestamp | null;
  entity!: Reference<EntityModel> | null;
  estimatedValue!: number;
  isCommitment!: boolean;
  isRenewal!: boolean;
  notes!: string;
  properties!: Properties | null;
  purchaseOrder!: string;
  startDate!: Timestamp;
  timeCreated!: Timestamp;
  timestamp!: Timestamp;
  type!: ContractModelType;
  updatedBy?: UpdatedBy | string;
  vendorContract?: Reference<VendorContractModel>;
  subCollections!: {
    contractUpdates: ContractUpdates;
  };
}

export interface CommitmentPeriod {
  endDate: Timestamp;
  startDate: Timestamp;
  value: number;
  discount?: number;
}

export interface ContractFile {
  id: string;
  name: string;
  parentId: null | string;
  storage: null | string;
  url: string;
}

export interface ContactSupport {
  discount: number | null;
  flatRate: number | null;
  lastUpdateTime: Timestamp | null;
  mpaRef: Reference<MasterPayerAccountsModel> | null;
}

export type SkuData = {
  label: string;
  monthlyListPrice: number;
  googleSku: string;
};

export type LookerSku = {
  skuName: SkuData;
  months: number;
  quantity: number;
  monthlySalesPrice: number;
};

interface Properties {
  awsFlexSaveOverwrite?: number;
  discountPreemptible?: boolean;
  domains?: string[];
  flexRI?: number;
  freeUntil?: Timestamp;
  gcpFlexSaveOverwrite?: number | string;
  pricelistSheet?: ContractFile | null;
  rebaseModifier?: number;
  specialDiscount?: number | null;
  subscriptionFilter?: boolean[];
  supportPlan?: string;
  support?: Record<number, ContactSupport> | null;
  userEmail?: string;
  skus?: LookerSku[];
}

export type ContractModelType =
  | "amazon-web-services"
  | "bettercloud"
  | "g-suite"
  | "google-cloud"
  | "looker"
  | "microsoft-azure"
  | "office-365"
  | "zendesk"
  | "google-cloud-standalone"
  | "amazon-web-services-standalone"
  | "google-geolocation-services";

type Credits = {
  doitToCustomer: {
    amount: number;
    currency: string;
  };
  vendorToCustomer: {
    amount: number;
    currency: string;
  };
};
