import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { CircularProgressWithLabel, LinearProgressWithLabel } from "../Components/ProgressWithLabel";

import { images } from "../assets";
import { useFullScreen } from "../utils/dialog";

const useStyles = makeStyles((theme) => ({
  verticalImageSpacing: {
    [theme.breakpoints.up("sm")]: {
      margin: "161px 0 73px 0",
    },
  },
  verticalProgressBarSpacing: {
    margin: "0 0 38px 0",
  },
  loadingImage: {
    height: "380px",
    width: "80vw",
    backgroundImage: `url(${images.buildingTestDrive})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
}));

export type LoadingPageProps = {
  topSpacer: number;
  demoModeReady: boolean;
};

const LoadingPage = ({ topSpacer, demoModeReady }: LoadingPageProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [value, setValue] = React.useState(0);
  const { isMobile } = useFullScreen("sm");

  React.useEffect(() => {
    let valueInterval: NodeJS.Timeout | null = null;
    if (!demoModeReady) {
      valueInterval = setInterval(
        () =>
          setValue((prevValue) => {
            if (prevValue >= 80 && valueInterval) {
              clearInterval(valueInterval);
              return prevValue;
            }
            return prevValue + 1;
          }),
        500
      );
    }
    if (demoModeReady) {
      if (valueInterval) {
        clearInterval(valueInterval);
      }
      setValue(80);
      valueInterval = setInterval(
        () =>
          setValue((prevValue) => {
            if (prevValue >= 100 && valueInterval) {
              clearInterval(valueInterval);
              return prevValue;
            }
            return prevValue + 1;
          }),
        150
      );
    }
  }, [demoModeReady]);
  return (
    <div style={{ marginTop: topSpacer, height: "80vh" }}>
      <Grid
        container
        alignContent="center"
        alignItems="center"
        justifyContent="center"
        className={classes.verticalImageSpacing}
      >
        <Grid item>
          <div className={classes.loadingImage} />
        </Grid>
      </Grid>
      <Grid
        container
        alignContent="center"
        alignItems="center"
        justifyContent="center"
        spacing={1}
        className={classes.verticalProgressBarSpacing}
      >
        {isMobile ? (
          <CircularProgressWithLabel value={value} />
        ) : (
          <Grid item sm={4}>
            <LinearProgressWithLabel value={value} />
          </Grid>
        )}
      </Grid>
      <Grid container alignContent="center" alignItems="center" justifyContent="center">
        <Grid item>
          <Typography variant={isMobile ? "subtitle1" : "h5"} align={isMobile ? "center" : "left"}>
            We are building your environment. {isMobile ? <br /> : <></>} Perfection takes time...
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default LoadingPage;
