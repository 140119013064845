import React, {
  ComponentType,
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  ReactNode,
} from "react";
import { CustomerModel, AssetTypeAmazonWebServices } from "@doitintl/cmp-models";
import { getCollection, useCollectionData, WithFirebaseModel } from "@doitintl/models-firestore";
import { DateTime } from "luxon";
import { getDisplayName } from "recompose";
import { Customer } from "../types";
import { getTenantId, monitorTenantId } from "../utils/Auth/tenant";
import { useDemoModeContext } from "../utils/DemoModeContext";
import { DocumentReference } from "../utils/firebase";
import { useAuthContext } from "./AuthContext";
import { EntitiesContextProvider, useEntitiesContext, EntitiesContextType } from "./customer/EntitiesContext";
import { AssetsContextProvider, FullAssetsContextType, useAssetsContext } from "./customer/AssetContext";
import { ContractsContextProvider, ContractsContextType, useContractsContext } from "./customer/ContractsContext";
import { OrgsContextProvider, OrgsContextType, useOrgsContext } from "./customer/OrgsProvider";
import { DebtContextProvider } from "./customer/CustomerDebt";
import { AccountManagersProvider } from "./customer/AccountManagers";
import { CloudConnectProvider } from "./customer/CloudCOnnectContext";
import { AttributionsContextProvider } from "./AttributionsContext";

export type CustomerContextType = {
  customer: Customer | null;
};

export type FullCustomerContextType = CustomerContextType &
  FullAssetsContextType &
  ContractsContextType &
  EntitiesContextType &
  OrgsContextType & {
    tenantId: string;
    freeTrialActive?: boolean;
    trialUser?: boolean;
    awsFeatures?: string[];
    init: boolean;
  };

const customerContext = createContext<Partial<FullCustomerContextType>>({});

export function useCustomerContext(): Omit<FullCustomerContextType, "customer"> & { customer: Customer };
export function useCustomerContext({ allowNull }: { allowNull: true | undefined }): Partial<FullCustomerContextType>;
export function useCustomerContext({
  allowNull,
}: {
  allowNull: true;
}): Partial<Omit<FullCustomerContextType, "customer"> & { customer: Customer | null }>;
export function useCustomerContext(
  { allowNull }: { allowNull?: boolean } = { allowNull: false }
): Partial<CustomerContextType> {
  const context = useContext(customerContext);

  if (!context.customer) {
    if (allowNull) {
      return context;
    }
    throw new Error("useCustomerContext was used outside of its Provider");
  }

  return context;
}

// base provider doesn't s access firestore as tests uses it to inject data directly into the context without going through firestore
const BaseCustomerContextProvider = ({
  value,
  children,
}: {
  value: Partial<FullCustomerContextType>;
  children?: ReactNode;
}) => {
  const [freeTrialActive, setFreeTrialActive] = useState<boolean>();
  const [trialUser, setTrialUser] = useState<boolean>();

  useEffect(() => {
    const trialEndDate = value.customer?.trialEndDate && DateTime.fromJSDate(value.customer.trialEndDate.toDate());
    setFreeTrialActive(trialEndDate ? trialEndDate.diff(DateTime.now()).milliseconds > 0 : false);
    setTrialUser(!!value.customer?.trialEndDate);
  }, [value.customer?.trialEndDate]);

  return (
    <customerContext.Provider value={{ freeTrialActive, trialUser, ...value }}>{children}</customerContext.Provider>
  );
};

const getCustomerAmazonWebServices = (customerId?: string) =>
  customerId
    ? getCollection(CustomerModel)
        .doc(customerId)
        .collection("cloudConnect")
        .where("cloudPlatform", "==", AssetTypeAmazonWebServices)
    : undefined;

export const CustomerInitFromContexts = ({
  value,
  children,
}: {
  value: Partial<CustomerContextType>;
  children?: ReactNode;
}) => {
  const awsAccountsQuery = useMemo(() => getCustomerAmazonWebServices(value.customer?.id), [value.customer?.id]);
  const [awsFeatures, setAwsFeatures] = useState<string[]>();
  const [awsAccountsDocs, isLoadingAWSAccountsDocs] = useCollectionData(awsAccountsQuery);

  const { isDemoMode } = useDemoModeContext();
  const entitiesContext = useEntitiesContext();
  const assetsContext = useAssetsContext();
  const contractsContext = useContractsContext();
  const { organizations, userOrganization, organizationsLoading } = useOrgsContext();
  const [init, setInit] = useState(false);
  const [tenantId, setTenantId] = useState<string>();
  const [tenantIdLoading, setTenantIdLoading] = useState<boolean>(true);

  useEffect(() => {
    setInit(
      !!value.customer?.id &&
        !entitiesContext.entitiesLoading &&
        !assetsContext.assetsLoading &&
        !organizationsLoading &&
        !tenantIdLoading
    );
  }, [
    value.customer?.id,
    entitiesContext.entitiesLoading,
    assetsContext.assetsLoading,
    organizationsLoading,
    tenantIdLoading,
  ]);

  useEffect(() => {
    if (!value.customer?.id) {
      setTenantIdLoading(true);
      setTenantId(undefined);
      return;
    }

    if (isDemoMode) {
      setTenantIdLoading(false);
      return;
    }

    async function handleTenantId(customerId) {
      try {
        const customerTenantId = await getTenantId(customerId);
        setTenantId(customerTenantId);
      } catch (e) {
        monitorTenantId(customerId, (customerTenantData) => {
          if (customerTenantData) {
            setTenantId(customerTenantData.tenantId);
          }
        });
      } finally {
        setTenantIdLoading(false);
      }
    }

    handleTenantId(value.customer.id);
  }, [value.customer?.id, isDemoMode]);

  useEffect(() => {
    if (isLoadingAWSAccountsDocs) {
      return;
    }

    if (!awsAccountsDocs) {
      setAwsFeatures([]);
      return;
    }

    const uniqueAwsFeatures = awsAccountsDocs.reduce((acc, awsDoc) => {
      awsDoc.supportedFeatures?.forEach((feature) => {
        if (!feature.hasRequiredPermissions) {
          return;
        }
        acc.add(feature.name);
      });

      return acc;
    }, new Set<string>());

    setAwsFeatures(Array.from(uniqueAwsFeatures));
  }, [awsAccountsDocs, isLoadingAWSAccountsDocs]);

  return (
    <BaseCustomerContextProvider
      value={{
        ...value,
        ...assetsContext,
        ...contractsContext,
        ...entitiesContext,
        awsFeatures,
        userOrganization,
        organizations,
        tenantId,
        init,
      }}
    >
      {children}
    </BaseCustomerContextProvider>
  );
};

export const CustomerContextProvider = ({
  value,
  children,
}: {
  value: Partial<CustomerContextType>;
  children?: ReactNode;
}) => (
  <AccountManagersProvider customer={value.customer}>
    <EntitiesContextProvider customer={value.customer}>
      <DebtContextProvider customer={value.customer}>
        <AssetsContextProvider customer={value.customer}>
          <ContractsContextProvider customer={value.customer}>
            <OrgsContextProvider customer={value.customer}>
              <CloudConnectProvider customer={value.customer}>
                <AttributionsContextProvider customer={value.customer}>
                  <CustomerInitFromContexts value={{ ...value }}>{children}</CustomerInitFromContexts>
                </AttributionsContextProvider>
              </CloudConnectProvider>
            </OrgsContextProvider>
          </ContractsContextProvider>
        </AssetsContextProvider>
      </DebtContextProvider>
    </EntitiesContextProvider>
  </AccountManagersProvider>
);

type CustomerRefIdModel = { id: string; ref: DocumentReference } & WithFirebaseModel<CustomerModel>;

export const CustomerContextProviderForTesting = ({
  children,
  value,
}: {
  children?: ReactNode;
  value?: Partial<
    Omit<FullCustomerContextType, "customer"> & { customer: Partial<Customer> | Partial<CustomerRefIdModel> }
  >;
}) => {
  const { customerId } = useAuthContext();
  const assetsContext = useAssetsContext();
  const entitiesContext = useEntitiesContext();
  const actualValue = value ?? {};

  if (customerId) {
    if (!actualValue.customer) {
      actualValue.customer = {};
    }
    actualValue.customer.id = customerId;
    actualValue.init = true;
  }

  if (actualValue.customer?.id) {
    actualValue.customer.ref = getCollection(CustomerModel).doc(actualValue.customer.id).ref;
  }

  return (
    <BaseCustomerContextProvider
      value={{ ...(actualValue as CustomerContextType), ...assetsContext, ...entitiesContext }}
    >
      {children}
    </BaseCustomerContextProvider>
  );
};

type CustomerContextConsumerType =
  | { children: (value: FullCustomerContextType) => ReactNode }
  | { allowNull: true; children: (value: Partial<FullCustomerContextType>) => ReactNode };

export const CustomerContextConsumer = (props: CustomerContextConsumerType) => {
  const partialDataCallback = useCallback(
    (ctx: Partial<CustomerContextType>) => {
      // if `undefined`, throw an error
      if (!ctx.customer && !("allowNull" in props)) {
        throw new Error("useCustomerContext was used outside of its Provider");
      }

      // once customer is set we assume that all the other data is set
      return props.children(ctx as Required<FullCustomerContextType>);
    },
    [props]
  );

  return <customerContext.Consumer>{partialDataCallback}</customerContext.Consumer>;
};

type Props = CustomerContextType;

export type WithCustomerFlat = Props;
export type WithCustomer = {
  customer: Customer;
};

export function withCustomerFlat<P extends object>(Component: ComponentType<P & Props>) {
  const WrappedComponent: FC<P> = (props) => (
    <CustomerContextConsumer>{(context) => <Component {...context} {...props} />}</CustomerContextConsumer>
  );

  WrappedComponent.displayName = `withCustomerFlat(${getDisplayName(Component)})`;

  return WrappedComponent;
}
