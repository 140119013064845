import { CurrencyCodes, CustomerModel } from "@doitintl/cmp-models";
import { WithFirebase } from "@doitintl/models-firestore";
import { DocumentReference } from "../utils/firebase";

export type CustomerSettings = {
  currency: CurrencyCodes;
  timezone?: string;
};

export enum CustomerType {
  CONSOLIDATED = "consolidated",
  STANDALONE = "standalone",
}

export type Customer = { id: string; ref: DocumentReference } & WithFirebase<CustomerModel>;
