import { common } from "@mui/material/colors";
import { alpha } from "@mui/material/styles";
import { ThemeMode, ThemeModes } from "../muiTheme";

export const gradientText = (color: string) => ({
  background: `-webkit-${color}`,
  WebkitBackgroundClip: "text",
  color: "inherit",
});

export const convertToGradient = (main: string, level: number, mode: ThemeMode) => {
  const base = mode === ThemeModes.LIGHT ? common.white : common.black;
  return `linear-gradient(0deg, ${alpha(base, level)}, ${alpha(base, level)}), linear-gradient(0deg, ${main}, ${main})`;
};

export const convertHexToRGBA = (hexCode: string, opacity = 1) => {
  let hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  if (opacity === 1) {
    return `rgb(${r}, ${g}, ${b})`;
  }
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};
