import React from "react";

import makeStyles from "@mui/styles/makeStyles";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

const useStyles = makeStyles(() => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

type Props = {
  loading: boolean;
  children: React.ReactNode;
} & React.ComponentProps<typeof Button>;

const LoadingButton = ({ loading, children, ...otherProps }: Props) => {
  const classes = useStyles();

  return (
    <Button {...otherProps}>
      {children}
      {loading && (
        <CircularProgress disableShrink={true} color="primary" size={24} className={classes.buttonProgress} />
      )}
    </Button>
  );
};

export default LoadingButton;
