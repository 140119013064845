import { collection, Reference, subCollection, Timestamp } from "@doitintl/models-types";
import { CustomerModel } from "./Customer";
import { EntityModel, EntityModelBucketModel } from "./Entity";
import { ContractModel } from "./Contract";
import { AssetType, CurrencyCodes, DashboardModelAttributionModel } from ".";

type CloudHealthProperties = {
  accountId: number;
  customerId: number;
  customerName: string;
  externalId: string;
  status: "" | "green" | "yello" | "red" | "unknown";
};

type Organization = {
  status: "ACTIVE" | "INACTIVE" | "SUSPENDED";
  email: string;
  payerAccount: string | { id: string; displayName: string };
};

type GSuiteBillingMethod = "OFFLINE" | "ONLINE";

export type GSuiteRenewalType = "AUTO_RENEW" | "RENEW_CURRENT_USERS" | "SWITCH_TO_PAY_AS_YOU_GO";

export type GSuitePlanName = "ANNUAL" | "ANNUAL_YEARLY_PAY" | "FLEXIBLE" | "FREE" | "TRIAL";

type GSuiteStatus = "ACTIVE" | "SUSPENDED";

export type Office365PlanName = "ANNUAL" | "FLEXIBLE";

export type Plan<TPlanName extends GSuitePlanName | Office365PlanName> = {
  planName: TPlanName;
} & (
  | {
      commitmentInterval: {
        endTime: number;
        startTime: number;
      };
      isCommitmentPlan: true;
    }
  | {
      isCommitmentPlan: false;
    }
);

export type GSuiteAssetModelProperties = {
  customerDomain: string;
  customerId: string;
  reseller: string;
  settings?: {
    currency?: CurrencyCodes;
    payment?: "MONTHLY" | "YEARLY";
    plan?: Plan<GSuitePlanName>;
  } | null;
  subscription: {
    billingMethod: GSuiteBillingMethod;
    creationTime: number;
    plan: Plan<GSuitePlanName>;
    purchaseOrderId?: string;
    renewalSettings?: {
      renewalType: GSuiteRenewalType;
    };
    resourceUiUrl: string;
    seats: {
      licensedNumberOfSeats: number;
      maximumNumberOfSeats: number;
      numberOfSeats: number;
    } | null;
    skuId: string;
    skuName: string;
    status: GSuiteStatus;
    subscriptionId: string;
    trialSettings:
      | {
          isInTrial: false;
        }
      | {
          isInTrial: true;
          trialEndTime: number;
        };
  };
};

export type AWSSupportData = {
  supportModel?: string;
  originalSupportTier?: string;
  overridingSupportTier?: string;
  overrideReason?: string;
  isPLESAsset?: boolean;
  overriddenOn: Timestamp | null;
  isOverridable: boolean;
};

export type AmazonWebServicesAssetModelProperties = {
  accountId: string;
  cloudhealth?: CloudHealthProperties;
  friendlyName: string;
  name: string;
  organization: {
    email: string;
    payerAccount: {
      displayName: string;
      id: string;
    };
    status: "ACTIVE" | "SUSPENDED";
  } | null;
  sauronRole: boolean;
  support?: AWSSupportData;
};

export type AmazonWebServicesResellerAssetModelProperties = {
  accountId: string;
  cloudhealth: {
    customerId: number;
  };
  name: string;
  organization: {
    payerAccount: {
      displayName: string;
    };
    status: string;
  };
  sauronRole: boolean;
  support?: AWSSupportData;
};

export type AmazonWebServicesStandaloneAssetModelProperties = {
  accountId: string;
  friendlyName: string;
  name: string;
  organization: {
    email: string;
    payerAccount: {
      displayName: string;
      id: string;
    };
    status: "ACTIVE" | "SUSPENDED";
  };
  sauronRole: boolean;
  support?: AWSSupportData;
};

export type BetterCloudAssetModelProperties = {
  customerDomain: string;
  subscription: {
    billingCycle: string;
    endDate: Timestamp;
    isCommitment: boolean;
    quantity: number;
    skuId: string;
    skuName: string;
    startDate: Timestamp;
  };
};

export type Office365AssetModelProperties = {
  customerDomain: string;
  customerId: string;
  reseller: string;
  settings: {
    currency?: CurrencyCodes;
    payment?: "MONTHLY" | "YEARLY";
    plan?: Plan<Office365PlanName>;
  } | null;
  subscription: {
    autoRenewEnabled: boolean;
    commitmentEndDate: string;
    creationDate: string;
    effectiveStartDate: string;
    friendlyName: string;
    id: string;
    isTrial: boolean;
    offerId: string;
    offerName: string;
    orderId: string;
    productType: {
      displayName: string;
      id: "Azure" | "OnlineServicesNCE";
    } | null;
    quantity: number;
    status: "active";
  };
  syncing?: boolean;
};

export type GoogleCloudAssetModelProperties = {
  admins: string[];
  billingAccountId: string;
  displayName: string;
  etag?: string;
  numProjects: number;
  projects: string[];
};

export type GoogleCloudDirectAssetModelProperties = {
  admins?: string[];
  billingAccountId: string;
  displayName?: string;
  etag?: string;
  projects?: [];
};

export type GoogleCloudProjectAssetModelProperties = {
  billingAccountId: string;
  projectId: string;
};

export type GoogleCloudResellerAssetModelProperties = {
  admins: [];
  billingAccountId: string;
  displayName: string;
  etag: string;
  projects: [];
};

export type GoogleCloudStandaloneAssetModelProperties = {
  admins: null;
  billingAccountId: string;
  displayName: string;
  etag: string;
  numProjects: number;
  projects: null;
};

export type ZendeskAssetModelProperties = {
  customerDomain: string;
  subscription: {
    billingCycle: "ANNUAL" | "FLEXIBLE";
    endDate: Timestamp | null;
    isCommitment: boolean;
    quantity: number;
    skuId: string;
    skuName: string;
    startDate: Timestamp;
  };
};

/*
 TODO: 22/02/2023 | https://doitintl.atlassian.net/browse/CMP-9430
   Populate with the properties of an actual looker asset.
 */
export type LookerAssetModelProperties = any;

export type MicrosoftAzureAssetModelProperties = {
  customerDomain: string;
  customerId: string;
  reseller: string;
  subscription: {
    billingProfileDisplayName: string;
    billingProfileId: string;
    customerDisplayName: string;
    customerId: string;
    displayName: string;
    skuDescription: string;
    skuId: string;
    subscriptionBillingStatus: "active";
    subscriptionId: string;
  };
};

type AssetModelProperties = {
  accountId: string;
  friendlyName: string;
  name: string;
  billingAccountId: string;
  displayName: string;
  cloudhealth?: CloudHealthProperties;
  admins: string[];
  organization: Organization | null;
  subscription: {
    id: string;
    offerName: string;
    skuName: string;
    subscriptionId: string;
    skuId: string;
    seats?: {
      maximumNumberOfSeats: number;
      numberOfSeats: number;
    };
    displayName: string;
    skuDescription?: string;
    subscriptionBillingStatus: string;
  };
  customerDomain: string;
  numProjects?: number;
  projects: string[];
  sauronRole?: boolean;
  support?: AWSSupportData;
  projectId?: string;
  reseller: string;
};

export namespace AssetModelBillingAnomaly {
  export type ChartEntry = {
    actual_cost: number;
    high: number;
    low: number;
    sku_costs?: number[];
    sku_names?: string[];
  };
  export type ChartData = Record<string, ChartEntry>;
}

export type BillingAnomalyExplainer = {
  optimization: string;
  skus_explanation: string;
  summary: string;
};

@subCollection("billingAnomalies")
export class AssetModelBillingAnomalyModel {
  customer!: Reference<CustomerModel>;
  chart_data!: AssetModelBillingAnomaly.ChartData;
  customer_rating?: number;
  attribution?: Reference<DashboardModelAttributionModel>;
  metadata!: {
    platform: "google-cloud" | "amazon-web-services";
    context: string;
    project_id: string;
    alert_id: string;
    explorated_level: {
      rules_model: "0" | "1" | "2" | "3";
    };
    billing_account_id: string;
    service_name?: string;
    usage_start_time: string;
  };
  timestamp!: Timestamp;
  explainer?: BillingAnomalyExplainer;
}

export const AssetTypeGoogleCloud = "google-cloud";
export const AssetTypeGoogleCloudProject = "google-cloud-project";
export const AssetTypeGoogleCloudDirect = "google-cloud-direct";
export const AssetTypeGoogleCloudStandalone = "google-cloud-standalone";
export const AssetTypeAmazonWebServices = "amazon-web-services";
export const AssetTypeAwsStandalone = "amazon-web-services-standalone";
export const AssetTypeMicrosoftAzure = "microsoft-azure";
export const AssetTypeGSuite = "g-suite";
export const AssetTypeOffice365 = "office-365";
export const AssetTypeZendesk = "zendesk";
export const AssetTypeBetterCloud = "bettercloud";
export const AssetTypeLooker = "looker";

@collection("assets")
export abstract class BaseAssetModel<TAssetType extends AssetType, TProperties> {
  bucket!: Reference<EntityModelBucketModel> | null;
  contract!: Reference<ContractModel> | null;
  customer!: Reference<CustomerModel>;
  entity!: Reference<EntityModel> | null;
  properties!: TProperties;
  tags?: string[] | null;
  type!: TAssetType;
  static billingAnomalies = AssetModelBillingAnomalyModel;
}

type ProcessingStatus = "idle" | "done" | "error" | "processing";

export type AssetAction = "modal" | string;

export class AssetModel extends BaseAssetModel<AssetType, AssetModelProperties> {
  copyJobMetadata?: {
    reason: string;
    progress: number;
    status: ProcessingStatus;
    action?: AssetAction;
  };
  tables?: Array<{ dataset: string; table: string; project: string }>;
}

export class AmazonWebServicesAssetModel extends BaseAssetModel<
  "amazon-web-services",
  AmazonWebServicesAssetModelProperties
> {
  settings?: {
    isRecalculated: boolean;
  } | null;
}

export class AmazonWebServicesResellerAssetModel extends BaseAssetModel<
  "amazon-web-services-reseller",
  AmazonWebServicesResellerAssetModelProperties
> {
  settings?: {
    isRecalculated: boolean;
  } | null;
}

export class AmazonWebServicesStandaloneAssetModel extends BaseAssetModel<
  "amazon-web-services-standalone",
  AmazonWebServicesStandaloneAssetModelProperties
> {
  settings?: {
    isRecalculated: boolean;
  } | null;
}

export class BetterCloudCloudAssetModel extends BaseAssetModel<"bettercloud", BetterCloudAssetModelProperties> {}

export class GoogleCloudAssetModel extends BaseAssetModel<"google-cloud", GoogleCloudAssetModelProperties> {}

export class GoogleCloudDirectAssetModel extends BaseAssetModel<
  "google-cloud-direct",
  GoogleCloudDirectAssetModelProperties
> {
  copyJobMetadata!: {
    action?: string;
    progress: number;
    reason: string;
    status: ProcessingStatus;
  };
  oneTimeBackfillDone?: boolean;
  tables?: {
    dataset: string;
    location?: string;
    project: string;
    table?: string;
  }[];
}

export class GoogleCloudProjectAssetModel extends BaseAssetModel<
  "google-cloud-project",
  GoogleCloudProjectAssetModelProperties
> {}

export class GoogleCloudResellerAssetModel extends BaseAssetModel<
  "google-cloud-reseller",
  GoogleCloudResellerAssetModelProperties
> {}

export class GoogleCloudStandaloneAssetModel extends BaseAssetModel<
  "google-cloud-standalone",
  GoogleCloudStandaloneAssetModelProperties
> {}

export class GSuiteAssetModel extends BaseAssetModel<"g-suite", GSuiteAssetModelProperties> {}

export class MicrosoftAzureAssetModel extends BaseAssetModel<"microsoft-azure", MicrosoftAzureAssetModelProperties> {}

export class Office365AssetModel extends BaseAssetModel<"office-365", Office365AssetModelProperties> {}

export class ZendeskCloudAssetModel extends BaseAssetModel<"zendesk", ZendeskAssetModelProperties> {}

export class LookerAssetModel extends BaseAssetModel<"looker", LookerAssetModelProperties> {}
