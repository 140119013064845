import { ComponentType, FC, useEffect, useMemo, createContext, useState, ReactNode, useContext } from "react";
import { getCollection, ModelIdRef } from "@doitintl/models-firestore";
import {
  AccountManagerCompanies,
  AccountManagerModel,
  AccountManagerStatuses,
  AccountTeam,
  AppModel,
} from "@doitintl/cmp-models";
import { getDisplayName } from "recompose";
import memoizeOne from "memoize-one";
import { useSyncExternalStore } from "use-sync-external-store/shim";
import noop from "lodash/noop";
import { useAuthContext } from "../AuthContext";
import { Customer } from "../../types";
import { AccountRole } from "../../utils/common";
import { createDocStore, createSnapshotStore } from "../../utils/syncStores";
import { doitDomain, doitPreviousDomain } from "../../utils/domains";

export type AccountManagersContextType = {
  // if the current user is account manager this will hae a value
  accountManager?: ModelIdRef<AccountManagerModel> | null;
  useAccountManagersForCustomer: () => Promise<ModelIdRef<AccountManagerModel>[]>;

  useAccountManagersRolesStore: () => AccountRole[];
  useAllAccountManagersStore: () => ModelIdRef<AccountManagerModel>[];
  useAllDoersAccountManagersStore: () => ModelIdRef<AccountManagerModel>[];
};

const accountManagerContext = createContext<AccountManagersContextType>({
  useAccountManagersForCustomer: noop as () => Promise<[]>,
  useAccountManagersRolesStore: noop as () => [],
  useAllAccountManagersStore: noop as () => [],
  useAllDoersAccountManagersStore: noop as () => [],
});

// if the given account manager is terminated - find replacement on his managers hierarchy. return null if cannot find a replacement
const replaceAccountManagerIfTerminated = async (
  accountManager: ModelIdRef<AccountManagerModel>
): Promise<ModelIdRef<AccountManagerModel> | null> => {
  if (accountManager.status === AccountManagerStatuses.TERMINATED) {
    if (!accountManager.manager) {
      return null;
    }

    const managerSnap = await accountManager.manager.get();

    const data = managerSnap.asModelData();

    if (data && accountManager.id !== managerSnap.id) {
      const newAccountManager = {
        ...data,
        id: managerSnap.id,
        ref: managerSnap.modelRef,
      };

      return replaceAccountManagerIfTerminated(newAccountManager);
    }

    return null;
  }

  return accountManager;
};
const getAccountManagersForAccountTeam = async (
  accountTeam?: AccountTeam,
  isDoitPartner?: boolean,
  partnerCompany?: AccountManagerCompanies
) => {
  if (!accountTeam) {
    return [];
  }

  const accountTeamIds = accountTeam
    .filter((am) =>
      isDoitPartner ? [partnerCompany, AccountManagerCompanies.DOIT as string].includes(am.company) : true
    )
    .map((am) => am.ref.id);

  if (accountTeamIds.length === 0) {
    return [];
  }

  const getDocs = async () => {
    const teamAccountManagers = await Promise.all(
      accountTeamIds.map(async (id): Promise<ModelIdRef<AccountManagerModel> | undefined> => {
        const doc = await getCollection(AccountManagerModel).doc(id).get();
        const data = doc.asModelData();
        if (!data) {
          return;
        }

        return { id: doc.id, ref: doc.modelRef, ...data };
      })
    );

    return teamAccountManagers.filter((am): am is ModelIdRef<AccountManagerModel> => !!am);
  };

  const docsData = await getDocs();
  const accountManagers = await Promise.all(
    docsData.map((accountManager) => {
      if (accountManager.company === "doit") {
        return replaceAccountManagerIfTerminated(accountManager);
      }

      return accountManager;
    })
  );

  const newAccountManagers: ModelIdRef<AccountManagerModel>[] = [];
  accountManagers.forEach((accountManager) => {
    if (!accountManager) {
      return;
    }

    if (accountManager.company === "doit") {
      newAccountManagers.unshift(accountManager);
    } else {
      newAccountManagers.push(accountManager);
    }
  });

  return newAccountManagers;
};

const allAccountManagersStore = createSnapshotStore(
  () => getCollection(AccountManagerModel),
  (accountManagerDoc) => ({
    ...accountManagerDoc.asModelData(),
    id: accountManagerDoc.id,
    ref: accountManagerDoc.modelRef,
  })
);

const accountManagersRolesStore = createDocStore(
  () => getCollection(AppModel).doc("account-manager-roles"),
  (doc) => doc.asModelData()?.roles ?? []
);

const allDoersAccountManagersStore = createSnapshotStore(
  () => getCollection(AccountManagerModel).where("company", "==", "doit"),
  (accountManagerDoc) => ({
    ...accountManagerDoc.asModelData(),
    id: accountManagerDoc.id,
    ref: accountManagerDoc.modelRef,
  })
);

const useAllAccountManagersStore = (): ModelIdRef<AccountManagerModel>[] =>
  useSyncExternalStore(allAccountManagersStore.subscribe, allAccountManagersStore.getSnapshot);

const useAllDoersAccountManagersStore = (): ModelIdRef<AccountManagerModel>[] =>
  useSyncExternalStore(allDoersAccountManagersStore.subscribe, allDoersAccountManagersStore.getSnapshot);

const useAccountManagersRolesStore = (): AccountRole[] =>
  useSyncExternalStore(accountManagersRolesStore.subscribe, accountManagersRolesStore.getSnapshot) ?? [];

export const AccountManagersProvider = ({
  children,
  customer,
}: {
  children: ReactNode;
  customer: Customer | null | undefined;
}) => {
  const { currentUser, isDoitEmployee, isDoitPartner, partnerCompany, userId } = useAuthContext();
  const [accountManager, setAccountManager] = useState<ModelIdRef<AccountManagerModel> | null>();
  const [accountManagerSearchByEmail, setAccountManagerSearchByEmail] = useState<string>();

  const useAccountManagersForCustomer = useMemo(
    () =>
      memoizeOne(() =>
        getAccountManagersForAccountTeam(
          customer?.accountTeam,
          isDoitPartner,
          partnerCompany as AccountManagerCompanies
        )
      ),
    [customer?.accountTeam, isDoitPartner, partnerCompany]
  );

  useEffect(() => {
    // user not loaded yet
    if (!currentUser?.email) {
      return;
    }

    if (isDoitPartner || isDoitEmployee) {
      return;
    }

    setAccountManager(undefined);
  }, [currentUser?.email, isDoitEmployee, isDoitPartner]);

  useEffect(() => {
    if (!accountManagerSearchByEmail) {
      return;
    }

    const emailParts = accountManagerSearchByEmail.split("@");
    const otherEmail =
      emailParts[1] === doitPreviousDomain
        ? `${emailParts[0]}@${doitDomain}`
        : `${emailParts[0]}@${doitPreviousDomain}`;

    return getCollection(AccountManagerModel)
      .where("email", "in", [accountManagerSearchByEmail, otherEmail])
      .limit(1)
      .onSnapshot((snap) => {
        if (snap.empty) {
          setAccountManager(null);
          return;
        }

        const data = snap.docs[0].asModelData();
        const newAccountManager = {
          ...data,
          id: snap.docs[0].id,
          ref: snap.docs[0].modelRef,
        };

        setAccountManager(newAccountManager);
      });
  }, [accountManagerSearchByEmail]);

  useEffect(() => {
    if (!isDoitPartner && !isDoitEmployee) {
      return;
    }

    if (!currentUser?.email) {
      setAccountManager(undefined);
      return;
    }

    return getCollection(AccountManagerModel)
      .doc(userId)
      .onSnapshot((docSnap) => {
        const data = docSnap.asModelData();

        if (!data) {
          setAccountManagerSearchByEmail(currentUser.email);
          return;
        }

        const newAccountManager = {
          ...data,
          id: docSnap.id,
          ref: docSnap.modelRef,
        };

        setAccountManager(newAccountManager);
      });
  }, [currentUser?.email, isDoitEmployee, isDoitPartner, userId]);

  const value = useMemo(
    () => ({
      accountManager,
      useAccountManagersForCustomer,
      useAccountManagersRolesStore,
      useAllAccountManagersStore,
      useAllDoersAccountManagersStore,
    }),
    [accountManager, useAccountManagersForCustomer]
  );

  return <accountManagerContext.Provider value={value}>{children}</accountManagerContext.Provider>;
};

export const AccountManagersConsumer = accountManagerContext.Consumer;
export function useAccountManagers({
  mustBeAccountManager,
}: {
  mustBeAccountManager: true;
}): Required<Omit<AccountManagersContextType, "accountManager"> & { accountManager: ModelIdRef<AccountManagerModel> }>;

export function useAccountManagers(): AccountManagersContextType;

export function useAccountManagers({
  mustBeAccountManager,
}: {
  mustBeAccountManager?: boolean;
} = {}): AccountManagersContextType {
  const value = useContext(accountManagerContext);

  if (mustBeAccountManager && !value.accountManager) {
    throw new Error("User must be an account manager");
  }

  return value;
}

type Props = AccountManagersContextType;

export type WithAccountManagers = Props;

export function withAccountManagers<P extends object>(Component: ComponentType<P & Props>) {
  const WrappedComponent: FC<P> = (props) => (
    <AccountManagersConsumer>{(context) => <Component {...context} {...props} />}</AccountManagersConsumer>
  );

  WrappedComponent.displayName = `withAccountManagers(${getDisplayName(Component)})`;

  return WrappedComponent;
}

export const AccountManagerContextProviderForTesting = ({
  children,
  value,
}: {
  children?: ReactNode;
  value?: Partial<AccountManagersContextType>;
}) => {
  const actualValue = value ?? {};

  if (!actualValue.accountManager) {
    actualValue.accountManager = undefined;
  }

  return (
    <accountManagerContext.Provider value={actualValue as AccountManagersContextType}>
      {children}
    </accountManagerContext.Provider>
  );
};
