import { collection, Reference, subCollection, Timestamp } from "@doitintl/models-types";
import { CustomerModel } from "./Customer";
import { EntityModel } from "./Entity";

export enum ProductEnum {
  GSuite = "g-suite",
  GoogleCloud = "google-cloud",
  GoogleCloudProject = "google-cloud-project",
  GoogleCloudDirect = "google-cloud-direct",
  GoogleCloudStandalone = "google-cloud-standalone",
  AmazonWebServices = "amazon-web-services",
  AmazonWebServicesStandalone = "amazon-web-services-standalone",
  Office365 = "office-365",
  Other = "other",
}

export class InvoiceData {
  payDate!: Timestamp;
  debit!: number;
  code!: string;
  id!: string;
  url!: string;
}

@subCollection("invoiceReminders")
export class CollectionModelInvoiceReminderModel {
  customer!: Reference<CustomerModel>;
  entity!: Reference<EntityModel>;
  contact!: string;
  reminderNumber?: number;
  invoices!: InvoiceData[];
}

@subCollection("debtAnalytics")
export class CollectionModelDebtAnalyticsModel {
  customer!: Reference<CustomerModel>;
  severity?: number;
  products!: ProductEnum[];
  date!: Timestamp;
}

class DebtAnalyticsDoc {
  subCollections!: {
    debtAnalytics: CollectionModelDebtAnalyticsModel;
  };
}

class InvoiceReminderDoc {
  subCollections!: {
    invoiceReminders: CollectionModelInvoiceReminderModel;
  };
}

@collection("collection")
export class CollectionModel {
  docs!: {
    "debt-analytics": DebtAnalyticsDoc;
    "invoice-reminder": InvoiceReminderDoc;
  };
}
