export enum CurrencyCodes {
  USD = "USD",
  EUR = "EUR",
  GBP = "GBP",
  ILS = "ILS",
  AUD = "AUD",
  CAD = "CAD",
  DKK = "DKK",
  NOK = "NOK",
  SEK = "SEK",
  BRL = "BRL",
  SGD = "SGD",
  MXN = "MXN",
  CHF = "CHF",
  MYR = "MYR",
  TWD = "TWD",
}
export type CurrencyCode = keyof typeof CurrencyCodes;
export type OldCurrency = "AUD" | "EUR" | "GBP" | "USD";

export const Currencies = [
  { value: CurrencyCodes.USD, symbol: "$" },
  { value: CurrencyCodes.ILS, symbol: "₪" },
  { value: CurrencyCodes.EUR, symbol: "€" },
  { value: CurrencyCodes.GBP, symbol: "£" },
  { value: CurrencyCodes.AUD, symbol: "A$" },
  { value: CurrencyCodes.CAD, symbol: "C$" },
  { value: CurrencyCodes.DKK, symbol: "kr" },
  { value: CurrencyCodes.NOK, symbol: "kr" },
  { value: CurrencyCodes.SEK, symbol: "kr" },
  { value: CurrencyCodes.BRL, symbol: "R$" },
  { value: CurrencyCodes.SGD, symbol: "S$" },
  { value: CurrencyCodes.MXN, symbol: "MX$" },
  { value: CurrencyCodes.CHF, symbol: "Fr." },
  { value: CurrencyCodes.MYR, symbol: "RM" },
  { value: CurrencyCodes.TWD, symbol: "NT$" },
];

export const CurrenciesMap = {
  [CurrencyCodes.USD]: "$",
  [CurrencyCodes.ILS]: "₪",
  [CurrencyCodes.EUR]: "€",
  [CurrencyCodes.GBP]: "£",
  [CurrencyCodes.AUD]: "A$",
  [CurrencyCodes.CAD]: "C$",
  [CurrencyCodes.DKK]: "kr",
  [CurrencyCodes.NOK]: "kr",
  [CurrencyCodes.SEK]: "kr",
  [CurrencyCodes.BRL]: "R$",
  [CurrencyCodes.SGD]: "S$",
  [CurrencyCodes.MXN]: "MX$",
  [CurrencyCodes.CHF]: "Fr.",
  [CurrencyCodes.MYR]: "RM",
  [CurrencyCodes.TWD]: "NT$",
};
