import React, { createContext, useContext, useEffect, useState } from "react";
import uniq from "lodash/uniq";
import { getCollection } from "@doitintl/models-firestore";
import { CollectionModel, CustomerModel, ProductEnum } from "@doitintl/cmp-models";
import { DateTime } from "luxon";
import mixpanel from "../../utils/mixpanel";
import { Customer } from "../../types";
import { useAuthContext } from "../AuthContext";

export type DebtContextProps = {
  products: ProductEnum[];
  date?: DateTime | null;
};

const defaultContextValue = { products: [] };

const debtContext = createContext<DebtContextProps>({ ...defaultContextValue });

export const DebtContextProvider = ({
  children,
  customer,
}: {
  children?: React.ReactNode;
  customer: Customer | undefined | null;
}) => {
  const [customerDebt, setCustomerDebt] = useState<DebtContextProps>({ ...defaultContextValue });
  const { isDoitPartner } = useAuthContext();

  useEffect(() => {
    setCustomerDebt({ ...defaultContextValue });

    if (!customer?.id || isDoitPartner) {
      return;
    }

    const customerRef = getCollection(CustomerModel).doc(customer.id);

    getCollection(CollectionModel)
      .doc("debt-analytics")
      .collection("debtAnalytics")
      .where("customer", "==", customerRef)
      .where("severity", "in", [20, 30, 40])
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          return;
        }

        const customerDebtData = querySnapshot.docs.reduce<DebtContextProps>(
          (memo, docSnap) => {
            const date = DateTime.fromJSDate(docSnap.get("date").toDate());
            if (!memo.date || date <= memo.date) {
              memo.date = date;
            }

            memo.products = uniq(memo.products.concat(docSnap.get("products"))).filter((p) => p && p !== "other");
            return memo;
          },
          {
            products: [],
            date: null,
          }
        );

        if (customerDebtData?.date?.isValid) {
          mixpanel.track("customer.open.overdue");
        }

        setCustomerDebt(customerDebtData);
      });
  }, [customer?.id, isDoitPartner]);

  return <debtContext.Provider value={customerDebt}>{children}</debtContext.Provider>;
};

export function useDebtContext(): DebtContextProps {
  return useContext(debtContext);
}
