import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import "firebase/compat/messaging";

import { getDemoMode } from "./DemoModeContext";
import { demoApp, customAuthApp, firestore, slimInitFirestore } from "./initFirestore";

export const storage = firebase.storage(customAuthApp);

export let auth: firebase.auth.Auth;

let rootAuthInstance: firebase.auth.Auth | undefined = undefined;
export const rootAuth = () => {
  if (rootAuthInstance === undefined) {
    rootAuthInstance = firebase.auth();
  }

  return rootAuthInstance;
};

export const customAuth = customAuthApp?.auth();

export const initFirebaseAuth = () => {
  if (getDemoMode()) {
    auth = demoApp.auth();
    return;
  }

  const useSsoWithoutProxy = window.localStorage.getItem("use-sso-without-proxy");

  if (customAuthApp && customAuth && !useSsoWithoutProxy && !firebase.auth().currentUser) {
    auth = customAuth;
    return;
  }

  auth = firebase.auth();
  window.localStorage.removeItem("use-sso-without-proxy");
};

export function initFirestore(idToken?: firebase.auth.IdTokenResult) {
  return slimInitFirestore(getDemoMode(), idToken);
}

export const bucket = (name) => (customAuthApp ?? firebase.app()).storage(name);
export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;
export const firestoreTimestamp = firebase.firestore.Timestamp.now;
export const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
export const arrayRemove = firebase.firestore.FieldValue.arrayRemove;
export const deleteField = firebase.firestore.FieldValue.delete;
export const increment = firebase.firestore.FieldValue.increment;
export const documentId = firebase.firestore.FieldPath.documentId;
export const isMessagingSupported = firebase.messaging.isSupported;

export type DocumentReference = firebase.firestore.DocumentReference;
export type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
export type QuerySnapshot = firebase.firestore.QuerySnapshot;
export type QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;
export type Query = firebase.firestore.Query;

// Firestore Timestamps
export type Timestamp = firebase.firestore.Timestamp;
export const TimestampNow = firebase.firestore.Timestamp.now;
export const TimestampFromDate = firebase.firestore.Timestamp.fromDate;

export { demoApp, firestore };
