import React, { ReactNode } from "react";
import startCase from "lodash/startCase";
import noop from "lodash/noop";
import { AlertColor } from "@mui/material/Alert";
import { autoHideDuration as defaultAutoHideDuration } from "../../constants";
import SharedSnackbar from "./SharedSnackbar";
import { appSnackbarDefaults, SharedSnackbarProps } from "./types";

export type OnOpenSharedSnackbar = (data: SharedSnackbarProps) => void;
export type OnOpenTemplateSnackbar = (message: string | ReactNode) => void;

export type OnCloseSharedSnackbar = (event?: any, reason?: string) => void;

export type SharedSnackbarContextProps = {
  onOpen: OnOpenSharedSnackbar;
  onClose: OnCloseSharedSnackbar;
};

const SharedSnackbarContext = React.createContext<SharedSnackbarContextProps>({
  onOpen: noop,
  onClose: noop,
});

export const useSnackbar = () => React.useContext(SharedSnackbarContext);

const useSnackbarTemplate = (
  variant: AlertColor,
  autoHideDuration = defaultAutoHideDuration
): OnOpenTemplateSnackbar => {
  const snackbar = useSnackbar();
  return React.useCallback(
    (message: React.ReactNode) => {
      snackbar.onOpen({
        message,
        variant,
        autoHideDuration,
        withClose: true,
      });
    },
    [snackbar, variant, autoHideDuration]
  );
};

const secondsToMilliseconds = (seconds?: number) => (seconds ? seconds * 1000 : undefined);
export const useErrorSnackbar = (autoHideAfterSeconds?: number) =>
  useSnackbarTemplate("error", secondsToMilliseconds(autoHideAfterSeconds));
export const useSuccessSnackbar = (autoHideAfterSeconds?: number) =>
  useSnackbarTemplate("success", secondsToMilliseconds(autoHideAfterSeconds));
export const useInfoSnackbar = (autoHideAfterSeconds?: number) =>
  useSnackbarTemplate("info", secondsToMilliseconds(autoHideAfterSeconds));
export const useWarningSnackbar = (autoHideAfterSeconds?: number) =>
  useSnackbarTemplate("warning", secondsToMilliseconds(autoHideAfterSeconds));

type SnackbarProviderProps = {
  children?: React.ReactElement<any, any>;
};

const SharedSnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
  const [queue, setQueue] = React.useState<Array<SharedSnackbarProps>>([]);
  const [open, setOpen] = React.useState(false);
  const [snackbarData, setSnackbarData] = React.useState<undefined | SharedSnackbarProps>(undefined);

  React.useEffect(() => {
    if (!queue.length) {
      return;
    }

    if (!snackbarData) {
      // Set a new snack when we don't have an active one
      setSnackbarData({ ...queue[0] });
      setQueue((prev) => prev.slice(1));
      setOpen(true);
    } else if (open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [queue, snackbarData, open]);

  const handleOpen = React.useCallback((options: SharedSnackbarProps) => {
    if (!options?.message || (typeof options.message === "object" && !React.isValidElement(options.message))) {
      options.message = startCase(options.variant || "");
    }

    setQueue((prev) => [...prev, { ...appSnackbarDefaults, ...options, key: new Date().getTime() }]);
  }, []);

  const handleClose = React.useCallback((event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  }, []);

  const handleExited = () => {
    setSnackbarData(undefined);
  };

  const ctxValue = React.useMemo(
    () => ({
      onOpen: handleOpen,
      onClose: handleClose,
    }),
    [handleOpen, handleClose]
  );

  return (
    <SharedSnackbarContext.Provider value={ctxValue}>
      {children}
      <SharedSnackbar
        key={snackbarData ? snackbarData.key : undefined}
        TransitionProps={{ onExited: handleExited }}
        open={open}
        {...snackbarData}
      />
    </SharedSnackbarContext.Provider>
  );
};

const SharedSnackbarConsumer = SharedSnackbarContext.Consumer;

export { SharedSnackbarProvider, SharedSnackbarContext, SharedSnackbarConsumer };
