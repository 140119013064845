import { collection, Reference, subCollection, Timestamp } from "@doitintl/models-types";
import { ProductEnum } from "./Collection";
import { CurrencyCodes } from "./Currency";
import { CustomerModel } from "./Customer";
import {
  Aggregator,
  AnalyticsResourceType,
  AttributionFilter,
  Collaborator,
  DashboardModelAttributionModel,
  Metric,
  MetricFilterOperator,
  PublicAccess,
  Renderer,
  ReportConfig,
  Roles,
  Sort,
  TimeInterval,
} from "./Dashboard";
import { OrganizationsModel } from "./Organizations";

export enum CalcMetricFormat {
  NUMERIC,
  PRECENTAGE,
}

export type SlackChannel = {
  id: string;
  name: string;
  shared: boolean;
  customerId: string;
  workspace: string;
  type?: "private" | "public";
};

export type BudgetAlert = {
  percentage: number;
  amount?: number;
  forecastedDate?: Timestamp | null;
  triggered: boolean;
};

export enum AlertCondition {
  VALUE = "value",
  FORECAST = "forecast",
  PERCENTAGE = "percentage",
}

export type IgnoreValuesRange = {
  lowerBound: number;
  upperBound: number;
};

export type BasicFilter = {
  id: string;
  inverse?: boolean;
  regexp?: string | null;
  values?: string[] | null;
};

export type AlertConfig = {
  aggregator: Aggregator;
  calculatedMetric: Reference<CloudAnalyticsModelMetricModel> | null;
  condition: AlertCondition;
  currency: CurrencyCodes;
  extendedMetric: string | null;
  filters?: AttributionFilter[];
  ignoreValuesRange?: IgnoreValuesRange | null;
  metric: Metric;
  operator: MetricFilterOperator;
  rows: string[];
  scope?: Reference<DashboardModelAttributionModel>[];
  timeInterval: TimeInterval;
  values: number[];
};

export enum LimitAggregation {
  TOP = "top",
  ALL = "all",
  NONE = "none",
}

@subCollection("cloudAnalyticsAlerts")
export class CloudAnalyticsModelAlertModel {
  collaborators!: Array<Collaborator>;
  config!: AlertConfig;
  customer!: Reference<CustomerModel>;
  etag!: string;
  isValid!: boolean;
  name!: string;
  organization!: Reference<OrganizationsModel>;
  public!: PublicAccess;
  timeCreated!: Timestamp;
  timeLastAlerted: Timestamp | null = null;
  timeModified!: Timestamp;
  recipients!: string[];
}

@subCollection("cloudAnalyticsAttributionGroups")
export class CloudAnalyticsModelAttributionGroupsModel {
  attributions!: Reference<DashboardModelAttributionModel>[];
  collaborators!: Array<Collaborator>;
  customer!: Reference<CustomerModel> | null;
  name!: string;
  description!: string;
  organization!: Reference<OrganizationsModel> | null;
  hidden?: boolean;
  public!: PublicAccess;
  timeCreated!: Timestamp;
  timeModified!: Timestamp;
  type!: AnalyticsResourceType;
  classification?: "invoice";
  cloud?: ProductEnum[] | null;
  nullFallback?: string | null;
}

export type BudgetConfig = {
  alerts: BudgetAlert[];
  allowGrowth: boolean;
  amount: number;
  currency: CurrencyCodes;
  endPeriod?: Timestamp;
  growthPerPeriod: number;
  metric: number;
  originalAmount: number;
  renderer?: Renderer;
  scope: Reference<DashboardModelAttributionModel>[];
  startPeriod: Timestamp;
  timeInterval: TimeInterval;
  type: number;
  usePrevSpend?: boolean;
};

@subCollection("cloudAnalyticsBudgets")
export class CloudAnalyticsModelBudgetModel {
  collaborators!: {
    email: string;
    role: Roles;
  }[];
  config!: BudgetConfig;
  customer!: Reference<CustomerModel>;
  description!: string;
  isPublic?: boolean;
  isValid!: boolean;

  name!: string;
  public!: PublicAccess;

  recipients!: string[];
  recipientsSlackChannels?: SlackChannel[];
  timeCreated!: Timestamp;
  timeModified!: Timestamp;
  timeRefreshed?: Timestamp;
  utilization?: {
    current: number;
    forecasted: number;
    lastPeriod?: number;
  };
  draft?: boolean;
}

export type MetricVariable = {
  attribution: Reference<DashboardModelAttributionModel>;
  metric: number;
};

@subCollection("cloudAnalyticsMetrics")
export class CloudAnalyticsModelMetricModel {
  name!: string;
  customer!: Reference<CustomerModel> | null;
  owner!: string;
  description!: string;
  type!: string;
  variables!: MetricVariable[] | null;
  formula!: string;
  format!: CalcMetricFormat;
  timeCreated!: Timestamp;
  timeModified!: Timestamp;
}

@subCollection("cloudAnalyticsConfigs")
export class CloudAnalyticsConfigsModel {
  metrics!: ExtendedMetric[];
}

@subCollection("cloudAnalyticsReportsDefaultConfigs")
export class CloudAnalyticsReportsDefaultConfigsModel {
  config!: ReportConfig;
}

export type ExtendedMetric = {
  key: string;
  type: string;
  label: string;
  visibility: "all" | "none" | "csp" | "regular" | "customer";
  cloud: ProductEnum.GoogleCloud | ProductEnum.AmazonWebServices | null;
};

type Variable = {
  metric: number;
};

type CalculatedMetric = {
  format: number;
  variables: Variable[];
};

enum Fields {
  GKEClusterName = "GKE.cluster_name",
  GKELabels = "GKE.labels",
  GKENamespace = "GKE.namespace",
  GKEResourceName = "GKE.resource_name",
  TBillingAccountID = "T.billing_account_id",
  TCloudProvider = "T.cloud_provider",
  TKubernetesClusterName = "T.kubernetes_cluster_name",
  TKubernetesNamespace = "T.kubernetes_namespace",
  TLabels = "T.labels",
  TLocationRegion = "T.location.region",
  TOperation = "T.operation",
  TProjectID = "T.project_id",
  TProjectName = "T.project_name",
  TResourceID = "T.resource_id",
  TServiceDescription = "T.service_description",
  TSkuDescription = "T.sku_description",
  TSystemLabels = "T.system_labels",
  TUsageDateTime = "T.usage_date_time",
}

type Field = `${Fields}`;

enum IDs {
  DatetimeDay = "datetime:day",
  DatetimeMonth = "datetime:month",
  DatetimeWeek = "datetime:week",
  DatetimeYear = "datetime:year",
  FixedBillingAccountID = "fixed:billing_account_id",
  FixedCloudProvider = "fixed:cloud_provider",
  FixedKubernetesClusterName = "fixed:kubernetes_cluster_name",
  FixedKubernetesNamespace = "fixed:kubernetes_namespace",
  FixedOperation = "fixed:operation",
  FixedProjectID = "fixed:project_id",
  FixedProjectName = "fixed:project_name",
  FixedRegion = "fixed:region",
  FixedResourceID = "fixed:resource_id",
  FixedServiceDescription = "fixed:service_description",
  FixedSkuDescription = "fixed:sku_description",
  GkeClusterName = "gke:cluster_name",
  GkeLabelYXBW = "gke_label:YXBw",
  GkeNamespace = "gke:namespace",
  GkeResourceName = "gke:resource_name",
  LabelAzhzLWxhYmVsL2S4Cy1HcHA = "label:azhzLWxhYmVsL2s4cy1hcHA=",
  LabelZGVwbG95BWVudF9UYW1L = "label:ZGVwbG95bWVudF9uYW1l",
  SystemLabelY21WL2NvbW1PDG1LbnRfdHlwZQ = "system_label:Y21wL2NvbW1pdG1lbnRfdHlwZQ==",
}

type ID = `${IDs}`;

export enum Keys {
  App = "app",
  BillingAccountID = "billing_account_id",
  CloudProvider = "cloud_provider",
  ClusterName = "cluster_name",
  CmpCommitmentType = "cmp/commitment_type",
  Day = "day",
  DeploymentName = "deployment_name",
  K8SLabelK8SApp = "k8s-label/k8s-app",
  KubernetesClusterName = "kubernetes_cluster_name",
  KubernetesNamespace = "kubernetes_namespace",
  Month = "month",
  Namespace = "namespace",
  Operation = "operation",
  ProjectID = "project_id",
  ProjectName = "project_name",
  Region = "region",
  ResourceID = "resource_id",
  ResourceName = "resource_name",
  ServiceDescription = "service_description",
  SkuDescription = "sku_description",
  Week = "week",
  Year = "year",
}

export type Key = `${Keys}`;

enum Labels {
  BillingAccount = "Billing Account",
  Cloud = "Cloud",
  Day = "Day",
  Empty = "",
  GKECluster = "GKE Cluster",
  GKENamespace = "GKE Namespace",
  GKEResource = "GKE Resource",
  Month = "Month",
  Operation = "Operation",
  ProjectAccountID = "Project/Account ID",
  ProjectAccountName = "Project/Account name",
  Region = "Region",
  Resource = "Resource",
  ScopeProjectAccount = "Scope (Project/Account)",
  Service = "Service",
  Sku = "SKU",
  Week = "Week",
  Year = "Year",
}

type Label = `${Labels}`;

enum ColTypes {
  Datetime = "datetime",
  Fixed = "fixed",
  Gke = "gke",
  GkeLabel = "gke_label",
  Label = "label",
  SystemLabel = "system_label",
}

type ColType = `${ColTypes}`;

export type Col = {
  allowNull: boolean;
  field: Field;
  filters: null;
  formula?: string;
  id: ID;
  includeInFilter: boolean;
  inverse: boolean;
  key: Key;
  label: Label;
  limit: number;
  limitMetric: null;
  limitOrder: null;
  position: Position;
  regexp: null;
  relation: string;
  type: ColType;
  values: null;
};

enum Positions {
  Col = "col",
  Row = "row",
}

type Position = `${Positions}`;

enum Features {
  Decreasing = "decreasing",
  Forecast = "forecast",
  Increasing = "increasing",
}

type Feature = `${Features}`;

enum CloudAnalyticsWidgetsTypes {
  Custom = "custom",
  Preset = "preset",
}

type CloudAnalyticsWidgetsType = `${CloudAnalyticsWidgetsTypes}`;

@subCollection("cloudAnalyticsWidgets")
export class CloudAnalyticsModelWidgetModel {
  collaborators!: Array<Collaborator>;
  config!: {
    aggregator: "total";
    calculatedMetric: CalculatedMetric | null;
    colOrder: Sort;
    cols: Col[];
    comparative: null;
    count: null;
    currency: CurrencyCodes;
    excludePartialData: boolean;
    extendedMetric: string;
    extendedMetricType: string;
    features: Feature[] | null;
    gkeMode: boolean;
    logScale: boolean;
    metric: number;
    renderer: Renderer;
    rowOrder: Sort;
    rows: Col[];
    timezone: string;
  };
  customer!: Reference<CustomerModel>;
  customerID!: string;
  data!: {
    cloudProvider: "amazon-web-services" | "google-cloud" | null;
    forecastRows: { [key: string]: Array<number | null | string> };
    rows: Record<string, [year: string, month: string, day: string, num1: number, num2: number, num3: number]>;
  };
  description!: string;
  expireBy?: Timestamp;
  isPublic!: boolean;
  name!: string;
  organization!: Reference<OrganizationsModel>;
  report!: string;
  size!: number;
  timeRefreshed!: Timestamp;
  type!: CloudAnalyticsWidgetsType;
}

export type StatusData = {
  lastUpdate?: Timestamp;
  status?: string;
};

@subCollection("cloudAnalyticsStatuses")
export class CloudAnalyticsModelStatusModel {
  status!: Record<string, StatusData>;
  timeModified!: Timestamp;
  overallLastUpdate!: Timestamp;
  customer!: Reference<CustomerModel>;
}

@subCollection("cloudAnalyticsScheduledReportsExecutions")
export class CloudAnalyticsScheduledReportsExecutionsModel {
  state!: number;
  timestamp!: Timestamp;
}

@subCollection("cloudAnalyticsGkeCostAllocations")
export class CloudAnalyticsModelGkeCostAllocationModel {
  billingAccountIds!: string[];
  labels!: {
    clusters: string[];
    namespaces: string[];
  };
  enabled!: boolean;
  timeCreated!: Timestamp;
  timeModified!: Timestamp;
  fullyEnabled!: boolean;
  unenabledClusters!: string[];
}

@subCollection("cloudAnalyticsReportsDocumentation")
export class CloudAnalyticsReportsDocumentationModel {
  html!: string;
}

class CloudAnalyticsModelWidgesDoc {
  subCollections!: {
    cloudAnalyticsWidgets: CloudAnalyticsModelWidgetModel;
  };
}

class GkeCostAllocationDoc {
  subCollections!: {
    cloudAnalyticsGkeCostAllocations: CloudAnalyticsModelGkeCostAllocationModel;
  };
}

class CloudAnalyticsModelAlertsDoc {
  subCollections!: {
    cloudAnalyticsAlerts: CloudAnalyticsModelAlertModel;
  };
}

class CloudAnalyticsModelAttributionGroupsDoc {
  subCollections!: {
    cloudAnalyticsAttributionGroups: CloudAnalyticsModelAttributionGroupsModel;
  };
}

class CloudAnalyticsModelMetricsDoc {
  subCollections!: {
    cloudAnalyticsMetrics: CloudAnalyticsModelMetricModel;
  };
}

class CloudAnalyticsModelBudgetsDoc {
  subCollections!: {
    cloudAnalyticsBudgets: CloudAnalyticsModelBudgetModel;
  };
}

class CloudAnalyticsModelStatusesDoc {
  subCollections!: {
    cloudAnalyticsStatuses: CloudAnalyticsModelStatusModel;
  };
}

class CloudAnalyticsModelConfigsDoc {
  subCollections!: {
    cloudAnalyticsConfigs: CloudAnalyticsConfigsModel;
  };
}

class CloudAnalyticsModelReportsDoc {
  subCollections!: {
    cloudAnalyticsScheduledReportsExecutions: CloudAnalyticsScheduledReportsExecutionsModel;
    cloudAnalyticsReportsDefaultConfigs: CloudAnalyticsReportsDefaultConfigsModel;
    cloudAnalyticsReportsDocumentation: CloudAnalyticsReportsDocumentationModel;
  };
}

@collection("cloudAnalytics")
export class CloudAnalyticsModel {
  static Budgets = CloudAnalyticsModelBudgetModel;
  static Metrics = CloudAnalyticsModelMetricModel;
  static Widgets = CloudAnalyticsModelWidgetModel;
  static Statuses = CloudAnalyticsModelStatusModel;
  static Configs = CloudAnalyticsConfigsModel;
  static Alerts = CloudAnalyticsModelAlertModel;
  static AttributionGroups = CloudAnalyticsModelAttributionGroupsModel;
  static GkeCostAllocation = CloudAnalyticsModelGkeCostAllocationModel;

  docs!: {
    alerts: CloudAnalyticsModelAlertsDoc;
    "attribution-groups": CloudAnalyticsModelAttributionGroupsDoc;
    budgets: CloudAnalyticsModelBudgetsDoc;
    "gke-cost-allocations": GkeCostAllocationDoc;
    metrics: CloudAnalyticsModelMetricsDoc;
    statuses: CloudAnalyticsModelStatusesDoc;
    widgets: CloudAnalyticsModelWidgesDoc;
    configs: CloudAnalyticsModelConfigsDoc;
    reports: CloudAnalyticsModelReportsDoc;
  };
}
