import { collection, Reference, Timestamp } from "@doitintl/models-types";
import { CustomerModel } from "./Customer";
import { EntityModel } from "./Entity";
import { CurrencyCodes } from "./Currency";

export type Extfile = {
  key: null | string;
  storage: null | string;
  udate: Timestamp;
  url: null | string;
};

export type InvoiceProduct =
  | "amazon-web-services"
  | "g-suite"
  | "google-cloud"
  | "microsoft-azure"
  | "office-365"
  | "other"
  | "superquery"
  | "google-cloud-standalone"
  | "amazon-web-services-standalone"
  | "looker";

type Ivtype = "A" | "C" | "F";

type InvoiceItem = {
  DETAILS: string;
  DISPRICE: number;
  EXCH: number | null;
  ICODE: CurrencyCodes;
  PARTNAME: string;
  PDES: string;
  PERCENT: number;
  PRICE: number;
  QPRICE: number;
  QUANT: number;
  SYMBOL: CurrencyCodes;
  TYPE: InvoiceProduct;
  USDEXCH: number;
};

type Metadata = {
  customer: {
    name: string;
    primaryDomain: string;
  };
  entity: {
    name: string;
  };
};

type Notification = {
  created: Date;
  sent: boolean;
};

type ReminderSettings = {
  snoozeUntil: Timestamp;
  updatedBy: string;
};

export type StripePaymentIntent = {
  amount: number;
  amount_received: number;
  currency: "ils" | "usd";
  debit: number;
  id: string;
  payment_method_types?: "card"[];
  ref: string;
  status: Status;
  timestamp: Timestamp;
  linked_invoice?: {
    amount_fees: number;
    ref: Reference<InvoiceModel>;
    invoice_id: string;
  };
};

type Status = "canceled" | "requires_capture" | "succeeded" | "processing";

@collection("invoices")
export class InvoiceModel {
  CANCELED!: boolean;
  CDES!: string;
  CODE!: CurrencyCodes;
  COMPANY!: "doit";
  CUSTNAME!: string;
  DEBIT!: number;
  DETAILS!: string;
  ESTPAYDATE!: Timestamp | null;
  EXTFILES!: Extfile[];
  INVOICEITEMS!: InvoiceItem[];
  IVDATE!: Timestamp;
  IVDATE_STRING!: Timestamp;
  IVNUM!: string;
  IVTYPE!: Ivtype;
  PAID!: boolean;
  PAYDATE!: Timestamp;
  PAYDATE_STRING!: string;
  PRODUCTS!: InvoiceProduct[];
  QPRICE!: number;
  STATDES!: string;
  SYMBOL!: CurrencyCodes;
  TOTPRICE!: number;
  USDEXCH!: number;
  USDTOTAL!: number;
  VAT!: number;
  customer!: Reference<CustomerModel>;
  entity!: Reference<EntityModel>;
  isNoticeToRemedySent?: boolean;
  metadata!: Metadata;
  notification?: Notification;
  reminderSettings?: ReminderSettings | null;
  stripeLocked?: boolean;
  stripePaymentIntents?: StripePaymentIntent[] | null;
}
