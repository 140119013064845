import { collection, subCollection, Timestamp } from "@doitintl/models-types";

export type SimulationRecommenderExplorerDoc = {
  day: {
    bar: number[];
    line: number[];
    xAxis: string[];
  };

  hour: {
    bar: number[];
    line: number[];
    xAxis: string[];
  };
};

class SimulationRecommenderExplorer {
  docs!: {
    "past-1-day": SimulationRecommenderExplorerDoc;
    "past-7-days": SimulationRecommenderExplorerDoc;
    "past-30-days": SimulationRecommenderExplorerDoc;
  };
}

class SimulationRecommenderRollUpsUserSlotsDoc {}
class SimulationRecommenderRollUpsUser {
  docs!: {
    slots: SimulationRecommenderRollUpsUserSlotsDoc;
  };
}

class SimulationRecommenderRollUpsDoc {
  subCollections!: {
    user: SimulationRecommenderRollUpsUser;
  };
}

class SimulationRecommenderRollUps {
  docs!: {
    "past-1-day": SimulationRecommenderRollUpsDoc;
    "past-7-days": SimulationRecommenderRollUpsDoc;
    "past-30-days": SimulationRecommenderRollUpsDoc;
  };
}

class SimulationRecommender {
  subCollections!: {
    explorer: SimulationRecommenderExplorer;
    rollUps: SimulationRecommenderRollUps;
  };
}
class SimulationRecommenderDoc {
  subCollections!: {
    "flat-rate": SimulationRecommender;
    "on-demand": SimulationRecommender;
    output: OutputModel;
  };
}

class SimulationOptimisationDoc {
  subCollections!: {
    output: OutputModel;
  };
}

class CmpDisplayMessagesDoc {
  NO_BQ_USAGE!: string;
}

class JobsSinksMetadataModel {
  backfillProgress!: number;
  backfillDone?: boolean;
}

class JobsSinksDoc {
  subCollections!: {
    jobsSinksMetadata: JobsSinksMetadataModel;
  };
}

@collection("superQuery")
export class SuperQueryModel {
  docs!: {
    "simulation-recommender": SimulationRecommenderDoc;
    "simulation-optimisation": SimulationOptimisationDoc;
    cmpDisplayMessages: CmpDisplayMessagesDoc;
    "jobs-sinks": JobsSinksDoc;
  };
}

type StorageSavings = {
  cost: number;
  datasetId: string;
  lastAccess: string;
  projectId: string;
  storageSizeTB: number;
  tableCreateDate: Timestamp;
  tableId: string;
};

type FieldMapping = {
  isPartition: boolean;
  order: number;
  sign: string;
  title: string;
  visible: boolean;
};

type OutputModelRecommendationsDoc = {
  storageSavings: {
    detailedTable: StorageSavings &
      {
        partitionsAvailable: StorageSavings &
          {
            tableIdBaseName: string;
          }[];
      }[];
    detailedTableFieldsMapping: Record<keyof StorageSavings & "tableIdBaseName", FieldMapping>[];
    recommendation: string;
    savingsPercentage: number;
    savingsPrice: number;
  };
};

class OutputModelRecommendations {
  docs!: {
    "past-1-day": OutputModelRecommendationsDoc;
    "past-7-day": OutputModelRecommendationsDoc;
    "past-30-day": OutputModelRecommendationsDoc;
  };
}

@subCollection("output")
export class OutputModel {
  progress!: number;
  displayMessage?: string;
  subCollections!: {
    recommendations: OutputModelRecommendations;
    rollUps?: SimulationRecommenderRollUps;
  };
}
