import { SnackbarProps } from "@mui/material/Snackbar/Snackbar";
import { SnackbarContentProps } from "@mui/material/SnackbarContent/SnackbarContent";
import { AlertColor } from "@mui/material/Alert";

export type SharedSnackbarProps = Partial<SnackbarProps> & {
  variant?: AlertColor;
  withClose?: boolean;
};

export const appSnackbarDefaults: SharedSnackbarProps = {
  message: "",
  autoHideDuration: 5000,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  withClose: false,
  action: null,
};

export type SnackbarOptions = {
  message: string;
  variant?: AlertColor;
  autoHideDuration?: number;
  action?: SnackbarContentProps["action"];
  withClose?: boolean;
};
