import Mixpanel, { Callback, Dict, RequestOptions } from "mixpanel-browser";

declare global {
  interface Window {
    mixpanel?: typeof Mixpanel;
  }
}

const MixpanelToken =
  process.env.WP_CALC === "true"
    ? process.env.REACT_APP_MIXPANEL_CALC_TOKEN
    : process.env.REACT_APP_MIXPANEL_TOKEN || "";

// we enable mixpanel on:
// 1. not cypress testing
// 2. we have token to mixpanel AND
// 3. REACT_APP_ENABLE_MIXPANEL is true (explicit enable mixpanel) OR
// 4. we are in production

const shouldInitMixPanel =
  process.env.CYPRESS_INTEGRATION_MODE !== "true" &&
  MixpanelToken &&
  (process.env.REACT_APP_ENABLE_MIXPANEL === "true" || process.env.NODE_ENV === "production");

// all mixpanel method we use in CMP, if you need a new one add that here and to the stub
export interface MinimumMixpanel {
  track(
    event_name: string,
    properties?: Dict,
    optionsOrCallback?: RequestOptions | Callback,
    callback?: Callback
  ): void;
  identify(unique_id?: string): any;
  register(props: Dict, days?: number): void;
  people: {
    set(prop: string, to: any, callback?: Callback): void;
    set(props: Record<string, any>): void;
  };
}

if (globalThis.mixpanel && shouldInitMixPanel) {
  globalThis.mixpanel.init(MixpanelToken, {
    debug: false,
  });
}

// can't use noop as those needs to be new  instances of functions
const mixpanel: MinimumMixpanel = (shouldInitMixPanel && globalThis.mixpanel) || {
  track: new Function() as MinimumMixpanel["track"],
  identify: new Function() as MinimumMixpanel["identify"],
  register: new Function() as MinimumMixpanel["register"],
  people: {
    set: new Function() as MinimumMixpanel["people"]["set"],
  },
};

export default mixpanel;
