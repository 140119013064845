import { useEffect } from "react";

export enum CloudProviders {
  GOOGLE_CLOUD = "Google Cloud",
  AMAZON_WEB_SERVICES = "Amazon Web Services",
}

type ChiliPiperContactType = {
  email: string;
  firstname: string;
  lastname?: string;
  companyName?: string;
  companyDomain?: string;
  platform?: CloudProviders;
  estimate?: number;
  country?: string;
};

const submit = (contactInfo: ChiliPiperContactType, onSuccess?: () => void) =>
  globalThis.ChiliPiper.submit("doit-intl", "flexsave_calc", {
    map: true,
    lead: contactInfo,
    onSuccess,
  });

export const useChiliPiper = () => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://js.chilipiper.com/marketing.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return submit;
};
