import { collection, Reference, subCollection, Timestamp } from "@doitintl/models-types";
import { CustomerModel } from "./Customer";

type AsgItemConfig = {
  fallbackOnDemand: boolean;
  keepUpToDate: boolean;
  excludedInstanceTypes: string[];
  excludedAvailabilityZones: string[];
  excludedSubnets: string[];
};

type AsgAdditionalDetails = {
  allOnDemand: boolean;
  instances: string[];
};

type AsgInstancesCosts = {
  spotHourCost: number;
  onDemandHourCost: number;
};

type AsgCosts = {
  cur: AsgInstancesCosts;
  rec: AsgInstancesCosts;
  averageDesiredCapacity: number;
};

type SpotisizeInfo = {
  curAsg: any;
  curAsgDetails: AsgAdditionalDetails;
  recAsg: any;
  costs: AsgCosts;
};

type InstanceTypeDetails = {
  compute: number;
  memory: number;
};

type SubnetDetails = {
  availabilityZone: string;
  cidr: string;
  isDefault: boolean;
};

type MonthlyAsgUsage = {
  onDemandInstances: UsageInstancesDetails;
  spotInstances: UsageInstancesDetails;
  totalSavings: number;
};

type AsgInstancesUsage = {
  totalCost: number;
  totalHours: number;
};

type UsageInstancesDetails = AsgInstancesUsage & {
  instances?: unknown;
};

class AsgBasicInfo {
  accountId!: string;
  region!: string;
  asgName!: string;
}

@subCollection("asgs")
export class Spot0ModelAsgsModel extends AsgBasicInfo {
  customer!: Reference<CustomerModel>;
  asgName!: string;
  mode!: string;
  timeStartedUsingSpots?: Timestamp;
  config!: AsgItemConfig;
  spotisize!: SpotisizeInfo;
  instanceTypesDetails!: Record<string, InstanceTypeDetails>;
  subnetsDetails!: Record<string, SubnetDetails>;
  spotisizeNotSupported!: boolean;
  spotisizeErrorDesc?: string;

  accountName!: string;
  managedStatus!: string;
  timeCreated!: Timestamp;
  timeModified!: Timestamp;
  usage!: Record<string, MonthlyAsgUsage>;
}

@collection("spot0")
export class Spot0Model {
  static Asgs = Spot0ModelAsgsModel;
}
