import { collection, doc, Reference, subCollection, Timestamp } from "@doitintl/models-types";
import { AUTO_GENERATED_AWS_ACCOUNTS_DOC_KEY, AutoGeneratedAwsAccountsDoc } from "./AutoGeneratedAwsAccounts";
import { AccountManagersCompany } from "./Customer";
import { Product } from "./Integration";
import { RoleModel } from "./Role";

type AccountRole = { name: string; value: string; vendors: string[] };

type UserNotificationDoc = {
  notifications: { description: string; name: string; order: number; value: number }[];
};

export type AwsFeaturePermission = {
  name: string;
  permissions: string[];
  policies: string[] | null;
};

export type CloudConnectCategory = {
  allowPartialAccessLevel?: boolean;
  description: string;
  id: string;
  name: string;
  orgLevelOnlyPermissions?: string[];
  permissions: string[];
};

export type GcpLocation = {
  title: string;
  value: string;
};

type CloudConnectDatasetLocations = {
  gcp: GcpLocation[];
};

type CloudConnectDoc = {
  awsFeaturePermissions: AwsFeaturePermission[];
  categories: CloudConnectCategory[];
  datasetLocations: CloudConnectDatasetLocations;
  doitAwsAccountNumber: string;
  permissionsAmazonWebServices: string[];
  permissionsGoogleCloud: string[];
};

@collection("app")
export class AppModel {
  docs!: {
    version: AppModelDoc;
    "account-manager-roles": AccountManagerRoles;
    "cloud-connect": CloudConnectDoc;
    fixer: FixerDoc;
    "doit-employees": DoitEmployeesDoc;
    "my-customers": MyCustomersDoc;
    "partner-access": PartnerAccessDoc;
    support: SupportModelDoc;
    "priority-v2": PriorityV2;
    algolia: AlgoliaConfig;
    "algolia-public": AlgoliaPublicConfig;
    "master-payer-accounts": MasterPayerAccountsDoc;
    [AUTO_GENERATED_AWS_ACCOUNTS_DOC_KEY]: AutoGeneratedAwsAccountsDoc;
    "sso-providers-texts": SsoProvidersTexts;
    userNotifications: UserNotificationDoc;
    countries: AppModelCountry;
    perks: PerksConfig;
  };
}

export type PerkPageConfig = {
  perksType: PerkPageSectionTitles[];
};

type PerkPageSectionTitles = {
  agenda: string;
  checklist: string;
  modules: string;
  id: string;
};

type SsoProvidersTexts = {
  [key in SsoTypes]: {
    [ssoProviderName: string]: SsoLabels;
  };
};

type SsoTypes = "saml" | "oidc";

type SsoLabels = {
  spEntityId: string;
  callbackUrl: string;
  idpLoginUrl: string;
};

export type PartnerData = {
  auth: {
    tenantId: string;
  };
  company: AccountManagersCompany;
  domains: string[];
  name: string;
  allowAuthProvider?: string;
};

@doc("partner-access")
class PartnerAccessDoc {
  partners!: PartnerData[];
}

export type DateStringFormat = `${number}-${number}-${number}`;

@subCollection("doersColumnsConfig")
export class MyCustomersDocDoersColumnsConfigModel {
  columns!: CustomersColumnsID[];
}

@subCollection("my-customers")
export class MyCustomersDoc {
  static DoersColumnsConfigModel = MyCustomersDocDoersColumnsConfigModel;
}

@subCollection("fixerExchangeRates")
export class FixerExchangeRatesModel {
  readonly [key: DateStringFormat]: Record<string, number>;
}

@doc("fixer")
export class FixerDoc {
  static ExchangeRates = FixerExchangeRatesModel;
}

@subCollection("doitRoles")
export class AppModelDoitEmployeesDocDoitRoles {
  roleName!: DoitRole;
  users!: string[];
  description!: string;
}

@doc("doit-employees")
class DoitEmployeesDoc {
  static DoitRoles = AppModelDoitEmployeesDocDoitRoles;
  subCollections!: {
    doitRoles: AppModelDoitEmployeesDocDoitRoles;
  };
}

@collection("app")
export class AccountManagerRoles {
  roles!: AccountRole[];
}

@doc("version")
export class AppModelDoc {
  theme?: string;
  commitSha!: string;
  shortCommitSha!: string;
  branch!: string;
  showSnackbar?: boolean;
  timestamp!: Timestamp;
  forceRefresh?: boolean;
}

@collection("app")
export class AppModelCloudHealthPriceBook {
  operations!: {
    name: string;
  }[];
  products!: Product[];
  regions!: {
    name: string;
  }[];
}

export type ContractTypeOption = {
  label: string;
  value: string;
};

@collection("app")
export class AppModelContracts {
  options!: ContractTypeOption[];
}

@doc("countries")
export class AppModelCountry {
  code!: Record<string, Country>;
}

export type Country = {
  name: string;
  region: string;
};

export type ChannelNotificationsProps = {
  customersNotifications: boolean;
  id: string;
  internalChannel?: string;
  name?: string;
  title?: string;
};

@collection("app")
export class AppModelSlack {
  channels!: ChannelNotificationsProps[];
}

export enum CustomersColumnsID {
  Checkbox = "",
  PrimaryDomain = "primaryDomain",
  ThisMonth = "thisMonth",
  Trend = "trend",
  AccountManagers = "accountManagers",
  FlexsaveAWS = "flexsaveAWS",
  FlexsaveGCP = "flexsaveGCP",
  BigqueryLens = "bigqueryLens",
  GkeMetering = "gkeMetering",
  AwsRampPlanAttainment = "awsRampPlanAttainment",
  GcpRampPlanAttainment = "gcpRampPlanAttainment",
  AwsContracts = "awsContracts",
  GcpContracts = "gcpContracts",
  Assets = "assets",
  AwsRampPlanStatus = "awsRampPlanStatus",
  GcpRampPlanStatus = "gcpRampPlanStatus",
  GkeCostAllocation = "gkeCostAllocation",
  RevenueEstGCP = "revenueEstGCP",
}

export enum DoitRole {
  AwsAccountGenerator = "aws-account-generator-admin",
  BillingProfileAdmin = "billing-profile-admin",
  CustomerSettingsAdmin = "customer-settings-admin",
  Developers = "developers",
  FlexsaveAdmin = "flexsave-admin",
  FlexsaveSuperAdmin = "flexsave-super-admin",
  MarketplaceAdmin = "marketplace-admin",
  MasterPayerAccountOpsAdmin = "master-payer-account-ops-admin",
  Owners = "owners",
  PerksAdmin = "perks-admin",
  ContractAdmin = "contract-admin",
  FieldSalesManager = "field-sales-manager",
  NordiaPreview = "nordia-feature-preview",
  CAOwnershipAssigner = "ca-ownership-assigner",
}

type RoleNotification = {
  name: string;
  roleRef: Reference<RoleModel>;
  notifications: number[];
};

@collection("app")
export class AppRoleNotificationModel {
  roleNotifications!: RoleNotification[];
}

export type AppPartnerData = {
  name: string;
  auth: {
    tenantId: string;
  };
  domains: string[];
  company: "google_cloud_platform" | "amazon_web_services";
  allowAuthProvider?: string;
};

@collection("app")
export class AppPartnerAccessModel {
  partners!: AppPartnerData[];
}

export type SupportTier = "basic" | "business" | "enterprise" | "developer";
export type SupportModel = "partner-led" | "resold" | null;
export type Tenancy = "dedicated" | "shared";
export type MasterPayerAccountStatus = "pending" | "active" | "retired";
export type BillingProfileStatus = "active" | "inactive";

export interface Support {
  tier: SupportTier;
  model: SupportModel;
  includeMemberAccounts?: boolean;
  enterpriseTAMLocation?: string;
  supportStart?: Timestamp | null;
  awsCaseIdSupportTicketUrl?: string;
}

export type MasterPayerAccountsFeatures = Partial<{
  "no-root-access": boolean;
  "import-org": boolean;
  "edp-ppa": boolean;
  "no-import-org-reason"?: string;
  "nra-expected-mrr"?: number;
  "nra-link-to-materials"?: string;
}>;

export type MpaRevenue = {
  month: string;
  lastUpdated: Timestamp;
  ppa?: number;
  edp?: number;
  support?: number;
  total?: number;
};

@subCollection("mpaAccounts")
export class MasterPayerAccountsModel {
  accountNumber!: string;
  customerId!: string;
  domain?: string;
  name!: string;
  friendlyName!: string;
  rootEmail?: string;
  roleARN!: string;
  tenancyType!: Tenancy;
  flexSaveAllowed?: boolean;
  flexSaveRecalculationStartDate?: Timestamp;
  defaultAwsFlexSaveDiscountRate!: number;
  support!: Support;
  cur_bucket!: string;
  cur_path!: string;
  expectedOnboardingDate?: Timestamp;
  status?: MasterPayerAccountStatus; // TO BE MADE MANDATORY FIELDS AFTER CMP-6194
  features?: MasterPayerAccountsFeatures; // TO BE MADE MANDATORY FIELDS AFTER CMP-6194
  regions?: string[];
  lastUpdated!: Timestamp;
  timeCreated!: Timestamp;
  requestedBy?: string;
  onboardingTicketId?: string;
  onePasswordItemId?: string;
  requesterLocation?: string;
  ticketAssignee?: string;
  revenues?: MpaRevenue[];
}

@doc("master-payer-accounts")
class MasterPayerAccountsDoc {
  static MpaAccounts = MasterPayerAccountsModel;
  subCollections!: {
    mpaAccounts: MasterPayerAccountsModel;
  };
}

@subCollection("services")
export class SupportServiceModel {
  blacklisted!: boolean;
  categories!: {
    name: string;
    id: string;
  }[];
  id!: string;
  name!: string;
  platform!: string;
  summary!: string;
  tags!: string[];
  url!: string;
}

@doc("support")
export class SupportModelDoc {
  static Services = SupportServiceModel;
}

@doc("priority-v2")
export class PriorityV2 {
  companies!: Company[];
}

export type Company = {
  countries: string[];
  wireTransfer: {
    key: string;
    value: string;
  }[];
};

export type AlgoliaConfig = {
  appId: string;
  searchKey: string;
  devAppId: string;
  devSearchKey: string;
  restrictedIndices: string[];
};

export type AlgoliaPublicConfig = {
  appId: string;
  devAppId: string;
};

@subCollection("statistics")
export class SupportStatisticseModel {
  distribution_products_30d!: {
    label: string;
    value: number;
  }[];
}

export type PerksConfig = {
  perksType: PerkTypeLabel[];
};

export type PerkTypeLabel = {
  id: string;
  agenda: string;
  checklist: string;
  modules: string;
};
