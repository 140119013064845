import { PaletteColor } from "@mui/material";
import { blue, common, green, grey, lightBlue, orange, red } from "@mui/material/colors";
import { alpha, createTheme } from "@mui/material/styles";
import { TypeAction, TypeBackground, TypeText } from "@mui/material/styles/createPalette";
import capitalize from "lodash/capitalize";
import flatten from "lodash/flatten";
import { cmpBaseColors } from "./cmpBaseColors";
import { convertToGradient, gradientText } from "./utils/color";

export enum ThemeModes {
  LIGHT = "light",
  DARK = "dark",
}

export type ThemeMode = `${ThemeModes}`;

export type Navigation = {
  searchPlaceholder: string;
  divider: string;
  background: string;
  backgroundSecondRow: string;
  gradient: string;
  searchBackground: string;
  text: string;
  accent: string;
  subItemColor: string;
};

export type General = {
  backgroundDefault: string;
  backdrop: string;
  divider: string;
  outlineBorder: string;
  snackbar: string;
  backgroundDark: string;
  cellBackground: string;
};

export enum Color {
  primary = "primary",
  secondary = "secondary",
  error = "error",
  warning = "warning",
  success = "success",
  info = "info",
  header = "header",
}

export type Colors = {
  background: TypeBackground;
  primary: PaletteColor;
  success: PaletteColor;
  error: PaletteColor;
  warning: PaletteColor;
  info: PaletteColor;
  header: PaletteColor;
  text: TypeText;
  action: Pick<TypeAction, "active" | "hover" | "selected" | "disabled" | "disabledBackground" | "focus"> & {
    hoverBackground?: string;
  };
  navigation: Navigation;
  general: General;
};

export type Modes = { light: Colors; dark: Colors };

declare module "@mui/material/styles/createMixins" {
  interface Mixins {
    styleButtonOutlined: (color: Color) => CSSProperties;
    styleButtonContained: (color: Color) => CSSProperties;
    styleChipOutlined: (color: Color | "default") => CSSProperties;
    styleChipFilled: (color: Color | "default") => CSSProperties;
    styleButtonHeader: (variant: "text" | "contained") => CSSProperties;
    styleAlertStandard: (color: Color) => CSSProperties;
  }

  interface MixinsOptions {
    styleButtonOutlined?: (color: Color) => CSSProperties;
    styleButtonContained?: (color: Color) => CSSProperties;
    styleChipOutlined?: (color: Color | "default") => CSSProperties;
    styleChipFilled?: (color: Color | "default") => CSSProperties;
    styleAlertStandard?: (color: Color) => CSSProperties;
    styleButtonHeader?: (variant: "text" | "contained") => CSSProperties;
  }
}

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    navigation: Navigation;
    general: General;
    header: PaletteColor;
  }

  interface PaletteOptions {
    navigation: Navigation;
    general: General;
    header: PaletteColor;
  }

  interface PaletteColor {
    hoverBackground: string;
    background?: string;
    border: string;
    content?: string;
  }
}

declare module "@mui/material" {
  interface Color {
    main: string;
    dark: string;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    header: true;
  }
}
declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    header: true;
  }
}

const convertToBackground = (main: string, mode: ThemeMode) => convertToGradient(main, 0.9, mode);

const prepare = (main: string, mode: ThemeMode = ThemeModes.LIGHT) => ({
  border: alpha(main, 0.5),
  hoverBackground: alpha(main, 0.08),
  content: convertToGradient(main, 0.6, mode === ThemeModes.LIGHT ? ThemeModes.DARK : ThemeModes.LIGHT),
});

export const colors: Modes = {
  light: {
    background: {
      default: common.white,
      paper: common.white,
    },
    navigation: {
      background: cmpBaseColors.navigation,
      backgroundSecondRow: common.white,
      text: cmpBaseColors.navigationTextColor,
      searchBackground: alpha(common.white, 0.15),
      searchPlaceholder: alpha(common.white, 0.42),
      divider: alpha(common.white, 0.3),
      gradient: `linear-gradient(90deg, ${cmpBaseColors.primaryGradient} 0.8%, ${cmpBaseColors.secondaryGradient} 99.1%)`,
      accent: cmpBaseColors.accent,
      subItemColor: grey[900],
    },
    general: {
      divider: alpha(common.black, 0.12),
      outlineBorder: alpha(common.black, 0.23),
      backdrop: alpha(common.black, 0.5),
      snackbar: cmpBaseColors.darkContrast,
      backgroundDefault: common.white,
      backgroundDark: grey[50],
      cellBackground: cmpBaseColors.cellBackgroundLight,
    },
    text: {
      primary: alpha(common.black, 0.87),
      secondary: alpha(common.black, 0.54),
      disabled: alpha(common.black, 0.38),
    },
    action: {
      active: alpha(common.black, 0.54),
      hover: alpha(common.black, 0.04),
      selected: alpha(common.black, 0.8),
      disabled: alpha(common.black, 0.26),
      disabledBackground: alpha(common.black, 0.12),
      focus: alpha(common.black, 0.12),
    },
    header: {
      main: cmpBaseColors.navigationTextColor,
      light: convertToGradient(common.white, 0.3, ThemeModes.LIGHT),
      dark: convertToGradient(common.white, 0.3, ThemeModes.DARK),
      contrastText: cmpBaseColors.navigation,
      ...prepare(cmpBaseColors.navigation, ThemeModes.DARK),
      hoverBackground: alpha(common.white, 0.15),
      background: cmpBaseColors.navigation,
    },
    primary: {
      main: cmpBaseColors.primary,
      light: convertToGradient(cmpBaseColors.primary, 0.3, ThemeModes.LIGHT),
      dark: convertToGradient(cmpBaseColors.primary, 0.3, ThemeModes.DARK),
      contrastText: cmpBaseColors.primaryButtonTextColor,
      ...prepare(cmpBaseColors.primary),
      hoverBackground: alpha(cmpBaseColors.primary, 0.08),
    },
    success: {
      main: green[800],
      light: green[500],
      dark: green[900],
      contrastText: common.white,
      ...prepare(green[800]),
      background: convertToBackground(green[800], ThemeModes.LIGHT),
    },
    error: {
      main: red[700],
      light: red[400],
      dark: red[800],
      contrastText: alpha(common.white, 0.87),
      ...prepare(red[800]),
      background: convertToBackground(red[800], ThemeModes.LIGHT),
    },
    warning: {
      main: orange[800],
      light: orange[500],
      dark: orange[900],
      contrastText: common.white,
      ...prepare(orange[800]),
      background: convertToBackground(orange[800], ThemeModes.LIGHT),
    },
    info: {
      main: lightBlue[800],
      light: lightBlue[500],
      dark: lightBlue[900],
      contrastText: alpha(common.white, 0.87),
      ...prepare(lightBlue[800]),
      background: convertToBackground(lightBlue[800], ThemeModes.LIGHT),
    },
  },
  dark: {
    background: {
      default: cmpBaseColors.darkContrast,
      paper: cmpBaseColors.darkContrast,
    },
    navigation: {
      background: cmpBaseColors.navigationDark,
      backgroundSecondRow: cmpBaseColors.darkContrast,
      text: common.white,
      searchBackground: alpha(common.white, 0.15),
      searchPlaceholder: alpha(common.white, 0.42),
      divider: alpha(common.white, 0.3),
      gradient: `linear-gradient(90deg, ${cmpBaseColors.primaryGradient} 0.8%, ${cmpBaseColors.secondaryGradient} 99.1%)`,
      accent: cmpBaseColors.accentDark,
      subItemColor: common.white,
    },
    general: {
      divider: alpha(common.white, 0.12),
      outlineBorder: alpha(common.white, 0.23),
      backdrop: alpha(common.black, 0.5),
      snackbar: common.white,
      backgroundDefault: cmpBaseColors.darkContrast,
      backgroundDark: cmpBaseColors.backgroundDark,
      cellBackground: cmpBaseColors.cellBackgroundDark,
    },
    text: {
      primary: common.white,
      secondary: alpha(common.white, 0.7),
      disabled: alpha(common.white, 0.5),
    },
    action: {
      active: common.white,
      hover: alpha(common.white, 0.08),
      selected: alpha(common.white, 0.16),
      disabled: alpha(common.white, 0.3),
      disabledBackground: alpha(common.white, 0.12),
      focus: alpha(common.white, 0.12),
    },
    header: {
      main: common.white,
      light: convertToGradient(common.white, 0.3, ThemeModes.LIGHT),
      dark: convertToGradient(common.white, 0.3, ThemeModes.DARK),
      contrastText: cmpBaseColors.navigationDark,
      ...prepare(cmpBaseColors.navigationDark, ThemeModes.DARK),
      hoverBackground: alpha(common.white, 0.15),
      background: cmpBaseColors.navigationDark,
    },
    primary: {
      main: cmpBaseColors.primaryDark,
      light: convertToGradient(cmpBaseColors.primaryDark, 0.3, ThemeModes.LIGHT),
      dark: cmpBaseColors.primaryDarkDark,
      contrastText: cmpBaseColors.darkContrast,
      ...prepare(cmpBaseColors.primaryDark, ThemeModes.DARK),
      hoverBackground: alpha(cmpBaseColors.primaryDark, 0.08),
    },
    success: {
      main: green[400],
      light: green[300],
      dark: green[700],
      contrastText: cmpBaseColors.darkContrast,
      background: cmpBaseColors.successBackgroundDark,
      ...prepare(green[400], ThemeModes.DARK),
    },
    error: {
      main: red.A100,
      light: red[100],
      dark: red[300],
      contrastText: cmpBaseColors.darkContrast,
      background: cmpBaseColors.errorBackgroundDark,
      ...prepare(red.A100, ThemeModes.DARK),
    },
    warning: {
      main: orange[500],
      light: orange[300],
      dark: orange[700],
      contrastText: cmpBaseColors.darkContrast,
      background: cmpBaseColors.warningBackgroundDark,
      ...prepare(orange[500], ThemeModes.DARK),
    },
    info: {
      main: lightBlue[400],
      light: lightBlue[300],
      dark: lightBlue[700],
      contrastText: cmpBaseColors.darkContrast,
      background: cmpBaseColors.infoBackgroundDark,
      ...prepare(lightBlue[400], ThemeModes.DARK),
    },
  },
};

export const configureTheme = (mode: ThemeMode = ThemeModes.LIGHT) => {
  const theme = createTheme({
    palette: {
      mode,
      ...colors[mode],
    },
  });

  const mixins = createTheme(theme, {
    mixins: {
      styleButtonHeader: (variant) => ({
        minWidth: 0,
        padding: "6px",
        color: variant === "contained" ? theme.palette.header.background : theme.palette.header.main,
        background: variant === "contained" ? theme.palette.header.main : theme.palette.header.background,
        border: 0,
        "&:hover": {
          background: theme.palette.header.hoverBackground,
          color: theme.palette.header.main,
        },
        "&.Mui-disabled": {
          border: 0,
          padding: "7px 17px",
          background: alpha(theme.palette.header.main, 0.6),
          color: theme.palette.header.contrastText,
        },
      }),
      styleChipOutlined: (color) => {
        const hoverColor = theme.palette.mode === ThemeModes.LIGHT ? "#002A4D" : "#4785B7";
        const borderBlue = blue[100];
        const iconBlue = theme.palette.mode === ThemeModes.LIGHT ? blue[700] : borderBlue;
        const textBlue = theme.palette.mode === ThemeModes.LIGHT ? lightBlue[900] : borderBlue;
        const deleteBlue = textBlue;
        return {
          color: color === "default" ? textBlue : theme.palette[color].main,
          borderColor: color === "default" ? borderBlue : theme.palette[color].main,
          "& .MuiChip-deleteIcon": {
            color: color === "default" ? deleteBlue : undefined,
            "&:hover": {
              color: color === "default" ? hoverColor : undefined,
            },
          },
          "& .MuiChip-icon": {
            color: color === "default" ? iconBlue : theme.palette[color].main,
          },
        };
      },
      styleChipFilled: (color) => {
        const hoverColor = "#002A4D";
        const borderBlue = blue[100];
        const textBlue = alpha(theme.palette.common.black, 0.87);
        const deleteBlue = "#095391";
        return {
          backgroundColor: color === "default" ? borderBlue : undefined,
          color: color === "default" ? textBlue : undefined,
          "& .MuiChip-deleteIcon": {
            color: color === "default" ? deleteBlue : undefined,
            "&:hover": {
              color: color === "default" ? hoverColor : undefined,
            },
          },
          "& .MuiChip-icon": {
            color: theme.palette.text.primary,
          },
        };
      },
      styleButtonContained: (color) => ({
        color: theme.palette[color].contrastText,
        border: "1px solid",
        borderColor: theme.palette[color].border,
        backgroundColor: theme.palette[color].main,
        "&:hover": {
          background: theme.palette[color].dark,
          backgroundColor: theme.palette[color].main,
        },
        "&.Mui-disabled": {
          border: 0,
          padding: "7px 17px",
        },
      }),
      styleButtonOutlined: (color) => ({
        background: "none",
        color: theme.palette[color].main,
        border: "1px solid",
        borderColor: theme.palette.mode === ThemeModes.LIGHT ? theme.palette[color].border : theme.palette[color].main,
        "&:hover": {
          backgroundColor: theme.palette[color].hoverBackground,
        },
      }),
      styleAlertStandard: (color) => ({
        background: theme.palette[color].background,
        "& .MuiAlert-message": gradientText(theme.palette[color].content),
        "& .MuiAlert-icon": {
          color: theme.palette[color].main,
        },
        "& .MuiAlert-action": {
          "&>a": gradientText(theme.palette[color].content),
        },
      }),
    },
  });

  return createTheme(mixins, {
    /**
     * Typography configuration according to UI Team Config https://design.doit-intl.com/90bbd94fe/p/801a23-typography/b/55c7fb
     */
    typography: {
      h1: { fontSize: "1.5rem", lineHeight: "2rem", fontWeight: "500" },
      h2: { fontSize: "1.375rem", lineHeight: "1.875rem", fontWeight: "500" },
      h3: { fontSize: "1.25rem", lineHeight: "1.75rem", fontWeight: "500" },
      h4: { fontSize: "1.125rem", lineHeight: "1.625rem", fontWeight: "500" },
      subtitle1: { fontSize: "1rem", lineHeight: "1.5rem", fontWeight: "400" },
      subtitle2: { fontSize: "0.875rem", lineHeight: "1.375rem", fontWeight: "500" },
      body1: { fontSize: "1rem", lineHeight: "1.5rem", fontWeight: "400" },
      body2: { fontSize: "0.875rem", lineHeight: "1.375rem", fontWeight: "400" },
      button: { fontSize: "0.875rem", lineHeight: "1.3125rem", fontWeight: "500" },
      caption: { fontSize: "0.75rem", lineHeight: "1.25rem", fontWeight: "400" },
      overline: { fontSize: "0.75rem", lineHeight: "2rem", fontWeight: "400" },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: { fontSize: "1.25rem", lineHeight: "1.25rem" },
          img: {
            backgroundColor: "transparent",
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: { fontSize: "0.8125rem", lineHeight: "1.125rem" },
        },
        variants: [
          ...flatten(
            ["outlined", "filled"].map((variant) =>
              ["default", ...Object.values(Color)].map((color) => ({
                props: { variant, color },
                style: mixins.mixins[`styleChip${capitalize(variant)}`](color),
              }))
            )
          ),
        ],
      },
      MuiPaper: {
        styleOverrides: { root: { backgroundImage: "unset" } },
      },
      MuiTextField: {
        defaultProps: {
          size: "small",
          InputLabelProps: {
            shrink: true,
          },
        },
      },
      MuiCard: {
        defaultProps: {
          variant: "outlined",
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            textTransform: "initial",
            "&:first-letter": { textTransform: "uppercase" },
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          hover: {
            backgroundColor: "action.hover",
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            boxShadow: `inset 0px -2px 0px ${theme.palette.navigation.accent}`,
            background: theme.palette.navigation.accent,
          },
        },
        defaultProps: {
          textColor: "inherit",
        },
      },
      MuiTooltip: {
        styleOverrides: {
          popper: { fontSize: "0.625rem", lineHeight: "0.875rem" },
        },
      },
      MuiAlert: {
        variants: flatten(
          ["standard"].map((variant) =>
            Object.values(Color).map((severity) => ({
              props: { variant, severity },
              style: mixins.mixins.styleAlertStandard(severity),
            }))
          )
        ),
      },
      MuiAlertTitle: {
        styleOverrides: {
          root: {
            fontSize: "1rem",
            lineHeight: "1.5rem",
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            fontSize: "0.875rem",
            lineHeight: "1.5rem",
          },
        },
      },

      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: 0,
            marginRight: 0,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: red[500],
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: { fontSize: "1rem", lineHeight: "1.1875rem" },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: { fontSize: "1rem", lineHeight: "1.1875rem" },
        },
      },
      MuiButtonGroup: {
        styleOverrides: {
          groupedContainedPrimary: {
            "&:not(:last-of-type)": {
              borderLeftWidth: 0,
              borderTopWidth: 0,
              borderBottomWidth: 0,
              borderRight: `1px solid ${theme.palette.primary.dark}`,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
            },
            "&:last-of-type": {
              "&:hover": {
                borderRightColor: "transparent",
              },
            },
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
              borderLeftColor: "transparent",
              borderTopColor: "transparent",
              borderBottomColor: "transparent",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "initial",
            "&:first-letter": { textTransform: "uppercase" },
          },
          sizeMedium: {
            fontSize: "0.875rem",
            lineHeight: "1.3125rem",
          },
          sizeSmall: {
            fontSize: "0.8125rem",
            lineHeight: "1.3125rem",
          },
        },
        variants: [
          ...flatten(
            ["outlined", "contained"].map((variant) =>
              Object.values(Color).map((color) => ({
                props: { variant, color },
                style: mixins.mixins[`styleButton${capitalize(variant)}`](color),
              }))
            )
          ),
          {
            props: { variant: "text", color: "header" },
            style: mixins.mixins.styleButtonHeader("text"),
          },
          {
            props: { variant: "contained", color: "header" },
            style: mixins.mixins.styleButtonHeader("contained"),
          },
        ],
      },
      MuiCalendarPicker: {
        styleOverrides: {
          root: {
            color: theme.palette.text.primary,
          },
        },
      },
    },
  });
};
