import { collection, subCollection } from "@doitintl/models-types";
import { AccountManagersCompany } from "./Customer";

class PartnersCommon {
  auth!: {
    tenantId: string;
  };
  primaryDomain!: string;
  domains!: string[];
  name!: string;
}

@subCollection("channelPartners")
export class ChannelPartnerModel extends PartnersCommon {
  branding!: {
    partnerLogoURL: string;
    partnerFavIcon: string;
    privacyPolicyURL: string;
    preferenceCenterURL: string;
  };
}

@subCollection("vendorPartners")
export class VendorPartnerModel extends PartnersCommon {
  company!: AccountManagersCompany;
}

@collection("partners")
export class PartnerModel {
  subCollections!: {
    vendorPartners: VendorPartnerModel;
    channelPartners: ChannelPartnerModel;
  };
}
