import { useState, useEffect, KeyboardEventHandler, useCallback, KeyboardEvent } from "react";

import {
  AccountManagerCompanies,
  AccountManagerModel,
  AssetTypeAmazonWebServices,
  AssetTypeGoogleCloud,
  AssetTypeMicrosoftAzure,
  Classification,
  Currencies,
  CurrencyCodes,
  EarlyAccessFeature,
  UserNotificationEnum,
} from "@doitintl/cmp-models";
import { v4 } from "uuid";

import { WithFirebaseModel } from "@doitintl/models-firestore";
import { blue, green, purple, red, yellow } from "@mui/material/colors";
import copy from "copy-to-clipboard";
import firebase from "firebase/compat/app";
import sample from "lodash/sample";
import { DateTime, DateTimeFormatOptions } from "luxon";
import iconAws from "../assets/amazon-web-services-new-square.png";
import iconDoit from "../assets/doit-logo-hero-square.svg";
import googleCloudIcon from "../assets/google-cloud.png";
import iconAzure from "../assets/microsoft-azure.png";
import { CloudConnectStatusText } from "../assets/texts";
import { Customer, UserNotification } from "../types";
import { useCustomerContext } from "../Context/CustomerContext";
import { Timestamp, TimestampFromDate } from "./firebase";
import Permissions from "./permissions";
import CurrencyConverter, { CurrencyConverterState, CurrencyDateType } from "./currencyConverter";

export const HelpSite = "https://help.doit.com";
export const CSPCustomerID = "CIgtnEximnd4fevT3qIU";

export const TimestampFromDateTime = (t: DateTime) => TimestampFromDate(t.toJSDate());

export const defaultNumberOfYearsFixer = 3;
export const currencyConverter = CurrencyConverter.getInstance(defaultNumberOfYearsFixer);

export const getCurrencySymbol = (code: string): string => Currencies.find((c) => c.value === code)?.symbol ?? code;

export const useCurrencyConverter = (wantedYears: number) => {
  const service = CurrencyConverter.getInstance(wantedYears);
  const { customer } = useCustomerContext();
  const currency = CurrencyCodes[customer.settings?.currency || CurrencyCodes.USD];
  const [id] = useState(v4());

  const [state, setState] = useState<CurrencyConverterState>(service.getState());

  useEffect(() => {
    service.on(id, setState);

    return () => {
      service.off(id);
    };
  }, [service, id]);

  const convert = useCallback(
    (amount: number, day: Date) => currencyConverter.convert.bind(service)(amount, day, CurrencyCodes.USD, currency),
    [currency, service]
  );

  return {
    ...state,
    convert,
  } as const;
};

export const getCurrencyByCode = (code: string | undefined): string => {
  if (!code) {
    return CurrencyCodes.USD;
  }

  return Currencies.find((c) => c.value === code) ? code : CurrencyCodes.USD;
};

export const getCurrencyLabelByCode = (code: string): string => {
  const currency = Currencies.find((c) => c.value === code);
  if (currency) {
    return `${currency.symbol} ${currency.value}`;
  }
  return code;
};

export const formatCurrency = (value, code, maxFracDigits = 2, minFracDigits = undefined): string => {
  const currency = getCurrencyByCode(code);
  if (currency) {
    const v = value ? value : 0;
    const max = maxFracDigits ?? 2;
    return v.toLocaleString(undefined, {
      style: "currency",
      currency,
      maximumFractionDigits: max,
      minimumFractionDigits: minFracDigits ?? max,
    });
  } else {
    return `${code}${value}`;
  }
};

export const formatDecimalNumber = (
  value,
  maxFracDigits: number | undefined = undefined,
  minFracDigits: number | undefined = undefined
) => {
  const v = value ? value : 0;
  const max = maxFracDigits ?? 2;
  return v.toLocaleString(undefined, {
    style: "decimal",
    maximumFractionDigits: max,
    minimumFractionDigits: minFracDigits ?? max,
  });
};

const intlFormat = (n, d) => new Intl.NumberFormat().format(Math.round(n * Math.pow(10, d)) / Math.pow(10, d));

export const formatNumber = (n, d = 2) => {
  const abs = Math.abs(n);
  d = d < 1 ? 1 : d;
  if (abs >= 1e18) {
    return `${intlFormat(n / 1e18, d)}E`;
  }
  if (abs >= 1e15) {
    return `${intlFormat(n / 1e15, d)}P`;
  }
  if (abs >= 1e12) {
    return `${intlFormat(n / 1e12, d)}T`;
  }
  if (abs >= 1e9) {
    return `${intlFormat(n / 1e9, d)}G`;
  }
  if (abs >= 1e6) {
    return `${intlFormat(n / 1e6, d)}M`;
  }
  if (abs >= 1e3) {
    return `${intlFormat(n / 1e3, d)}k`;
  }
  return intlFormat(n, d);
};

export const roundWithCommas = (num: number) =>
  Math.round(num)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const numberWithCommas = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const assetTypeName = (type: string) => {
  switch (type) {
    case "amazon-web-services":
      return "Amazon Web Services";
    case "amazon-web-services-standalone":
      return "Flexsave AWS Standalone";
    case "g-suite":
      return "Google Workspace";
    case "google-cloud":
      return "Google Cloud";
    case "google-cloud-standalone":
      return "Flexsave GCP Standalone";
    case "google-cloud-project":
      return "Google Cloud Project";
    case "office-365":
      return "Office 365";
    case "microsoft-azure":
      return "Microsoft Azure";
    case "superquery":
      return "superQuery";
    case "zendesk":
      return "Zendesk";
    case "bettercloud":
      return "BetterCloud";
    case "looker":
      return "Looker";
    case "other":
      return "Other";
    default:
      return type;
  }
};

export enum priorityCompanyDefaultCurrency {
  doit = CurrencyCodes.ILS,
  doitint = CurrencyCodes.USD,
  doituk = CurrencyCodes.GBP,
  doitaus = CurrencyCodes.AUD,
  doitde = CurrencyCodes.EUR,
  doitfr = CurrencyCodes.EUR,
  doitnl = CurrencyCodes.EUR,
  doitch = CurrencyCodes.CHF,
  doitca = CurrencyCodes.CAD,
  doitse = CurrencyCodes.SEK,
  doites = CurrencyCodes.EUR,
  doitie = CurrencyCodes.EUR,
  doitee = CurrencyCodes.EUR,
  doitsg = CurrencyCodes.SGD,
}

export const priorityCompanyTransform = (id) => {
  switch (id) {
    case "doit":
      return "DoiT International (Israel)";
    case "doitint":
      return "DoiT International USA Inc.";
    case "doituk":
      return "DoiT International UK&I Ltd";
    case "doitaus":
      return "DoiT International AUS PTY Ltd";
    case "doitde":
      return "DoiT International, DACH GmbH";
    case "doitfr":
      return "DoiT International France SAS";
    case "doitnl":
      return "DoiT International NL BV";
    case "doitch":
      return "DoiT International CH SARL";
    case "doitca":
      return "DoiT Holdings International CA Ltd";
    case "doitse":
      return "DoiT International SE AB";
    case "doites":
      return "DoiT International Multi-Cloud Espana S.L";
    case "doitie":
      return "DoiT International Multi-Cloud Ireland Ltd";
    case "doitee":
      return "DoiT Estonia";
    case "doitsg":
      return "DoiT International Xinjiapo Pte. Ltd";
    default:
      return "N/A";
  }
};

export const b64EncodeUnicode = (str) =>
  btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(_match, p1) {
      // @ts-expect-error
      return String.fromCharCode(`0x${p1}`);
    })
  );

export const b64DecodeUnicode = (str) =>
  decodeURIComponent(
    atob(str)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );

const invalidPattern = /^(?:http[s]?:\/\/|(?:http[s]?:\/\/)?www\.).+$/;
const validPattern = /^([a-z0-9-_]+\.)*[a-z0-9][a-z0-9-_]+\.[a-z]{2,12}?$/;
export const isNakedDomainValid = (domain: string) => validPattern.test(domain) && !invalidPattern.test(domain);

export const validate = (inputData) => !inputData;

export const transformStatus = (status) => {
  switch (status) {
    case "new":
      return "Pending Agent Assignment";
    case "open":
      return "In Progress with Support";
    case "pending":
      return "Pending Customer Action";
    case "hold":
      return "In Progress with Engineering";
    case "solved":
      return "Solved";
    case "closed":
      return "Closed";
    default:
      return "";
  }
};
export const transformPriority = (priority) => {
  switch (priority) {
    case "low":
      return "General Guidance";
    case "normal":
      return "System Impaired";
    case "high":
      return "Production System Impaired";
    case "urgent":
      return "Production System Down";
    default:
      return "General Guidance";
  }
};

export enum PresetOrganizations {
  GCPOrg = "GCPOrg",
  AWSOrg = "AWSOrg",
  DOITOrg = "doit-international",
}

export const partnerAssetsMap = {
  [AccountManagerCompanies.GCP]: AssetTypeGoogleCloud,
  [AccountManagerCompanies.AWS]: AssetTypeAmazonWebServices,
};

export const partnerOrgsMap = {
  [AccountManagerCompanies.GCP]: PresetOrganizations.GCPOrg,
  [AccountManagerCompanies.AWS]: PresetOrganizations.AWSOrg,
};

export const companiesLookup = {
  [AccountManagerCompanies.DOIT]: { name: "DoiT International", icon: iconDoit },
  [AccountManagerCompanies.AWS]: { name: "Amazon Web Services", icon: iconAws },
  [AccountManagerCompanies.GCP]: { name: "Google Cloud", icon: googleCloudIcon },
  [AccountManagerCompanies.MicrosoftAzure]: { name: "Microsoft Azure", icon: iconAzure },
};

export const clouds = [
  {
    name: "Amazon Web Services",
    hyphenName: AssetTypeAmazonWebServices,
    value: AccountManagerCompanies.AWS,
    icon: iconAws,
  },
  {
    name: "Google Cloud",
    hyphenName: AssetTypeGoogleCloud,
    value: AccountManagerCompanies.GCP,
    icon: googleCloudIcon,
  },
  {
    name: "Microsoft Azure",
    hyphenName: AssetTypeMicrosoftAzure,
    value: AccountManagerCompanies.MicrosoftAzure,
    icon: iconAzure,
  },
];

export type AccountRole = { name: string; value: string; vendors: string[] };
type TicketNotification = { name: "Off" | "Low" | "Normal" | "High" | "Urgent"; value: number; icon: "" | ">=" };
export const ticketNotification: TicketNotification[] = [
  { name: "Off", value: 0, icon: "" },
  { name: "Low", value: 1, icon: ">=" },
  { name: "Normal", value: 2, icon: ">=" },
  { name: "High", value: 3, icon: ">=" },
  { name: "Urgent", value: 4, icon: "" },
];

export const jobFunction = [
  { name: "Software / Ops Engineer", value: 1 },
  { name: "Finance / Accounting", value: 2 },
  { name: "Executive Team", value: 3 },
  { name: "Sales / Marketing", value: 4 },
  { name: "Legal / Purchasing", value: 5 },
  { name: "Founder", value: 6 },
  { name: "Management", value: 7 },
  { name: "Data Engineer/Data Analysts", value: 8 },
];

export const permissions = [
  { name: "Users Manager", value: Permissions.permissionIds.usersManager, visible: true },
  { name: "Billing Profile Admin", value: Permissions.permissionIds.billingProfilesAdmin, visible: true },
  { name: "Assets Manager", value: Permissions.permissionIds.assetsManager, visible: true },
  { name: "Flexsave Admin", value: Permissions.permissionIds.flexsaveAdmin, visible: true },
  { name: "Cloud Analytics", value: Permissions.permissionIds.cloudAnalytics, visible: true },
  { name: "Sandbox Admin", value: Permissions.permissionIds.sandboxAdmin, visible: true },
  { name: "Sandbox User", value: Permissions.permissionIds.sandboxUser, visible: true },
  { name: "Invoice Viewer", value: Permissions.permissionIds.invoicesViewer, visible: true },
  { name: "Manage Settings", value: Permissions.permissionIds.manageSettings, visible: true },
  { name: "Spot Scaling Manager", value: Permissions.permissionIds.spotScaling, visible: true },
  { name: "Contracts Viewer", value: Permissions.permissionIds.contractsViewer, visible: true },
  { name: "Anomalies Viewer", value: Permissions.permissionIds.anomaliesViewer, visible: true },
  { name: "Perks Viewer", value: Permissions.permissionIds.perksViewer, visible: true },
  { name: "Issues Viewer", value: Permissions.permissionIds.issuesViewer, visible: true },
  { name: "Budgets Manager", value: Permissions.permissionIds.budgetsManager, visible: true },
  { name: "Metrics Manager", value: Permissions.permissionIds.metricsManager, visible: true },
  { name: "Attributions Manager", value: Permissions.permissionIds.attributionsManager, visible: true },
  { name: "Support Requester", value: Permissions.permissionIds.supportRequester, visible: true },
  { name: "Ramp Plans Viewer", value: Permissions.permissionIds.rampPlansViewer, visible: true },
];

export const getPermissionsNames = (permissionsIds: string[]) =>
  permissionsIds.map((pId) => permissions.find((perm) => perm.value === pId)?.name ?? pId);

export const userNotifications: UserNotification[] = [
  {
    name: "New Invoices",
    value: UserNotificationEnum.NewInvoice,
    description: "explanation about this notification",
    order: 1,
  },
  {
    name: "Cloud Cost Anomalies",
    value: UserNotificationEnum.CostAnomalies,
    description: "explanation about this notification",
    order: 5,
  },
  {
    name: "Payment Due/Overdue",
    value: UserNotificationEnum.PaymentOverdue,
    description: "explanation about this notification",
    order: 3,
  },
  {
    name: "Credits Utilization",
    value: UserNotificationEnum.CreditsUtilization,
    description: "explanation about this notification",
    order: 2,
  },
  {
    name: "Cloud Quota Utilization",
    value: UserNotificationEnum.CloudQuotaUtilization,
    description: "explanation about this notification",
    order: 4,
  },
  {
    name: "Cloud Incidents",
    value: UserNotificationEnum.CloudKnownIssues,
    description: "explanation about this notification",
    order: 6,
  },
  {
    name: "Daily Digests",
    value: UserNotificationEnum.DailyDigest,
    description: "Receive a daily digest for your account",
    order: 7,
  },
  {
    name: "Monthly Digests",
    value: UserNotificationEnum.MonthlyDigest,
    description: "Receive a monthly digest for your account",
    order: 8,
  },
];

export const anomalyAlerts = [
  { name: "Information", value: 1 },
  { name: "Warning", value: 2 },
  { name: "Critical", value: 3 },
];

export enum anomalyAlertLevel {
  Information = 1,
  Warning = 2,
  Critical = 3,
}

export enum CategoryStatus {
  NotSetUp,
  Healthy,
  Unhealthy,
  Critical,
  Partial,
}

export const getCloudConnectStatus = (statusID: number) => {
  switch (statusID) {
    case CategoryStatus.NotSetUp:
      return { text: CloudConnectStatusText.NotSetUp, color: blue.A200 };
    case CategoryStatus.Healthy:
      return { text: CloudConnectStatusText.Healthy, color: green[600] };
    case CategoryStatus.Unhealthy:
      return { text: CloudConnectStatusText.Unhealthy, color: yellow[600] };
    case CategoryStatus.Critical:
      return { text: CloudConnectStatusText.Critical, color: red[500] };
    case CategoryStatus.Partial:
      return { text: CloudConnectStatusText.Partial, color: purple[500] };

    default:
      break;
  }
};

export const statusStyles = (dark: boolean) => ({
  red: {
    backgroundColor: dark ? red[200] : red[700],
    color: dark ? "rgba(0, 0, 0, 0.87)" : "#FFFFFF", // These are hardcoded because the designs are explicitly the reverse of the standard "text.primary" color behaviour
  },
  yellow: {
    backgroundColor: dark ? yellow[400] : yellow[500],
    color: "rgba(0, 0, 0, 0.87)", // These are hardcoded because the designs are explicitly the reverse of the standard "text.primary" color behaviour
  },
  green: {
    backgroundColor: dark ? green[400] : green[700],
    color: dark ? "rgba(0, 0, 0, 0.87)" : "#FFFFFF", // These are hardcoded because the designs are explicitly the reverse of the standard "text.primary" color behaviour
  },
});

export enum CloudHealthStatus {
  RED = "red",
  YELLOW = "yellow",
  GREEN = "green",
  UNKNOWN = "unknown",
}

/**
 * Returns status label for cloud assets in a more professional manner than mere colors.
 * @param status Color string
 */
export const getStatusLabel = (status: CloudHealthStatus): string => {
  switch (status) {
    case CloudHealthStatus.RED:
      return "Critical";
    case CloudHealthStatus.YELLOW:
      return "Warning";
    case CloudHealthStatus.GREEN:
      return "Healthy";
    case CloudHealthStatus.UNKNOWN:
      return "Unknown";
  }
};

export const jsUcfirst = (string) => {
  if (!string) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const awsShortenTitle = (title) => {
  if (title.indexOf("-") > -1 && title.includes("Amazon")) {
    title = title.substring(0, title.indexOf("-") - 1);
  }
  switch (title) {
    case "Amazon Elastic Compute Cloud":
      return "EC2";
    case "Amazon S3":
      return "S3";
    case "Amazon RDS Service":
      return "RDS";
    case "AWS (Support Business|Business Support)":
      return "Business Support";
    case "Amazon Virtual Private Cloud":
      return "VPC";
    case "AWS Direct Connect":
      return "Direct Connect";
    case "Amazon CloudFront":
      return "CloudFront";
    case "Amazon Elasticsearch Service":
      return "Elasticsearch";

    default:
      return title;
  }
};

export const urlify = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  let newUrl;
  text.replace(urlRegex, (url) => {
    newUrl = url;
  });
  return newUrl;
};

// Change luxon DateTime time zone to UTC and set to 00:00:00
export const sanitizeDate = (dt: DateTime) => dt.toUTC().startOf("day");

// Change luxon DateTime to represent same date in UTC timezone and set time to 00:00:00
export const sanitizeKeepingLocalDate = (dt: DateTime) => dt.toUTC(0, { keepLocalTime: true }).startOf("day");

const getNoAccessURL = (history, missingPermissions, troubleshootUrl) => {
  const troubleshoot_urlTmp = troubleshootUrl ? `&troubleshoot_url=${troubleshootUrl}` : "";
  return `/noaccess?origin_url=${encodeURIComponent(
    history.location.pathname
  )}&missing_permissions=${encodeURIComponent(missingPermissions.join(","))}${troubleshoot_urlTmp}`;
};

// For example: `noAccess(history, ["Manage Settings", "Users"], "http://google.com");`
export const noAccess = (history, missingPermissions, troubleshootUrl) => {
  const url = getNoAccessURL(history, missingPermissions, troubleshootUrl);
  history.push(url);
};

// For example: `noAccess(history, ["Manage Settings", "Users"], "http://google.com");`
export const noAccessReplace = (history, missingPermissions, troubleshootUrl) => {
  const url = getNoAccessURL(history, missingPermissions, troubleshootUrl);
  history.replace(url);
};
export const availableEarlyAccessFeaturesOptions = [
  EarlyAccessFeature.ALGOLIA_SEARCH,
  EarlyAccessFeature.FSGCP_DISABLED,
  EarlyAccessFeature.FSAWS_DISABLED,
  EarlyAccessFeature.FSGCP_MARKETPLACE_DISABLED,
  EarlyAccessFeature.FLEXSAVE_AWS_STANDALONE,
  EarlyAccessFeature.GCP_CLOUD_CONNECT_V2,
  EarlyAccessFeature.COUNT_AGGREGATION,
  EarlyAccessFeature.GKE_COST_ALLOCATION,
  EarlyAccessFeature.DISABLE_CREDIT_CARD_FEES,
  EarlyAccessFeature.ACCELERATOR_PROGRAM,
];

export const classificationOptions = [
  { value: Classification.Business, name: "Business" },
  { value: Classification.Terminated, name: "Terminated" },
  { value: Classification.SuspendedForNonPayment, name: "Suspended for non-payment" },
  { value: Classification.Inactive, name: "Inactive" },
];

export const downloadFile = (filename, fileData) => {
  const element = document.createElement("a");
  element.setAttribute("href", `data:text/csv;charset=utf-8,${encodeURIComponent(fileData)}`);
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const capitalizeStartCase = (s) => (typeof s !== "string" ? s : s.charAt(0).toUpperCase() + s.slice(1));

export const addToObject = (obj, key, value, index) => {
  const temp = {};
  let i = 0;
  for (const prop in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(prop)) {
      // If the indexes match, add the new item
      if (i === index && key && value) {
        temp[key] = value;
      }
      temp[prop] = obj[prop];
      i++;
    }
  }
  // If no index is specified, add to the end
  if (!index && key && value) {
    temp[key] = value;
  }
  return temp;
};

export const copyToClipboard = (e, showSnackbar, textToCopy) => {
  const target = e.target;
  const parent = target.parentElement;
  if (textToCopy) {
    copy(textToCopy);
  } else {
    copy(e.target.id !== "icon" ? e.target.innerText : parent.textContent);
  }
  showSnackbar({
    message: "Copied to Clipboard",
    variant: "info",
    autoHideDuration: 1000,
  });
};

const isNumeric = <T,>(e: KeyboardEvent<T>) => e.key >= "1" || e.key <= "0";

export const onKeyPressPreventNonNumeric = <T,>(e: KeyboardEvent<T>) => {
  if (!isNumeric(e)) {
    e.preventDefault();
  }
};

export const onKeyPressPreventNonNumericAllowFloatNegative = <T,>(e: KeyboardEvent<T>) => {
  if (e.key !== "-" && e.key !== "." && !isNumeric(e)) {
    e.preventDefault();
  }
};

export const getDateTimeFromFirestoreTimestamp = (timestamp) => DateTime?.fromJSDate(timestamp?.toDate());

export const getCustomerName = async (ref) => {
  const customerDocSnap = await ref.get();
  const customer = customerDocSnap.data();
  return customer.name;
};

// getCustomerAccountManagers receives a customer reference and returns an array
// of the DoiT account managers (FSR/SAM) who are assigned to this customer
export const getCustomerAccountManagers = async (customerRef) => {
  const customerDocSnap = await customerRef.get();
  const customer = customerDocSnap.data();
  const accountManagers = [];
  if (customer.accountManagers?.doit) {
    for (const key in customer.accountManagers.doit) {
      if (customer.accountManagers.doit[key]?.ref) {
        const docSnap = await customer.accountManagers.doit[key].ref.get();
        if (docSnap.exists) {
          // @ts-expect-error
          accountManagers.push(docSnap.data());
        }
      }
    }
  }
  return accountManagers;
};

export const randomLoadingMessage = () => {
  const loadingLines = [
    "Locating the required gigapixels to render...",
    "Spinning up the hamster...",
    "Shovelling coal into the server...",
    "Programming the flux capacitor...",
  ];

  return sample(loadingLines);
};

export const preventEnterEvent: KeyboardEventHandler<HTMLFormElement> = (event) => {
  if (event.key === "Enter") {
    event.preventDefault();
  }
};

/**
 * Provides a yes/no in place of true/false If the value is missing or nullish, it will fall back on "No".
 * @param bool the boolean value to convert to words
 */
export const humanBoolean = (bool?: boolean): "Yes" | "No" => (bool ? "Yes" : "No");

export const truncate = (str: string, n: number, useWordBoundary: boolean) => {
  if (!str || str?.length <= n) {
    return str;
  }
  const subString = str.substring(0, n - 1);
  return `${useWordBoundary ? subString.substring(0, subString.lastIndexOf(" ")) : subString}...`;
};

export const formatToLocaleTimestamp = (ts: Timestamp): string => {
  const dt = DateTime.fromJSDate(ts?.toDate());
  return dt?.isValid ? dt.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS) : "";
};

export const convertCurrencyTo = (
  amount: number,
  date: CurrencyDateType,
  from: CurrencyCodes,
  to: CurrencyCodes
): number => currencyConverter.convert(amount, date, from, to);

export const isDoitEmployeeOrPartner = (token?: firebase.auth.IdTokenResult): boolean =>
  !!token?.claims.doitEmployee || !!token?.claims.doitPartner;

export enum GlobalDashboardId {
  Home = "acczPSL1V2PY45mcBwBV", // Account/Home/default dashboard
  Pulse = "kr7df9r9FQmSH67O2wkO",
  BQLens = "uItbeGssgbOpmpOKQ9wj",
  AWSLens = "YnJfHkFLw7lsLINCkISG",
  GCPLens = "ixThO1hY3IWlo8O8Py2q",
  GKELens = "nClxTjylyAbIQW9AQNRk",
  GKELensv2 = "U6R46sYmgx8L55WbHvpb",
}

export const GlobalDashboardsById = {
  [GlobalDashboardId.Pulse]: { type: "pulse", name: "Pulse" },
  [GlobalDashboardId.BQLens]: { type: "superquery", name: "BQ Lens" },
  [GlobalDashboardId.AWSLens]: { type: "AWS", name: "AWS Lens" },
  [GlobalDashboardId.GCPLens]: { type: "gcp-lens", name: "GCP Lens" },
  [GlobalDashboardId.GKELens]: { type: "gke-lens", name: "GKE Lens" },
  [GlobalDashboardId.GKELensv2]: { type: "gke-lens-v2", name: "GKE Lens" },
};

export const getDashboardNameById = (dashboardType: string | null | undefined, name: string): string =>
  dashboardType ? GlobalDashboardsById[dashboardType]?.name ?? name : name;
export const GKE_LENS = GlobalDashboardsById[GlobalDashboardId.GKELens]
  ? GlobalDashboardsById[GlobalDashboardId.GKELens].name
  : "GKE Lens";

export const isMac = () => window.navigator.userAgent.indexOf("Macintosh") !== -1;

export const requiresStandaloneConfiguration = (customer?: Customer | null): boolean => {
  if (!customer?.type || customer.type !== "standalone") {
    return false;
  }

  return !!(
    customer.type === "standalone" &&
    customer.enabledFlexsave &&
    !customer.enabledFlexsave.GCP &&
    !customer.enabledFlexsave.AWS
  );
};

export const getAccountTeamRole = (
  roles: AccountRole[],
  accountManager: WithFirebaseModel<AccountManagerModel>
): string => {
  if (accountManager.role && roles) {
    const matchedRole = roles.find(
      (role) => role.value === accountManager.role && role.vendors.includes(accountManager.company)
    );
    return matchedRole ? matchedRole.name : "";
  }
  return "";
};

export function stringifyTimestamp({
  timeStamp,
  formatOpts = DateTime.DATETIME_MED_WITH_SECONDS,
  fallback = "",
}: {
  timeStamp: Pick<Timestamp, "toDate"> | null | undefined;
  formatOpts?: DateTimeFormatOptions;
  fallback?: string;
}): string {
  if (!timeStamp) {
    return fallback;
  }
  const date = DateTime.fromJSDate(timeStamp.toDate());
  if (!date.isValid) {
    return fallback;
  }
  return date.toLocaleString(formatOpts);
}

export const sortAlphabetically = (a: string, b: string, AtoZ = true): number => {
  if (a === b) {
    return 0;
  }

  switch (AtoZ) {
    case true:
      return a < b ? -1 : 1;
    case false:
      return a > b ? -1 : 1;
  }
};
