import { collection, subCollection, Timestamp } from "@doitintl/models-types";

type KnownIssueStatus = "ongoing" | "archived";

export enum KnownIssuePlatform {
  AWS = "amazon-web-services",
  GCP = "google-cloud-project",
  GSuite = "g-suite",
  Office365 = "office-365",
  GoogleCloud = "google-cloud",
}

@subCollection("customersThreads")
export class KnownIssueModelCustomerThreadModel {}

@collection("knownIssues")
export class KnownIssueModel {
  id!: string;
  issueId?: string;
  platform!: KnownIssuePlatform;
  affectedProduct!: string;
  products!: string[];
  region?: string;
  status!: KnownIssueStatus;
  title!: string;
  dateTime!: Timestamp;
  incidentEndDate?: Timestamp;
  outageDescription!: string;
  threadTs?: string;
  nextUpdateTime?: Timestamp;

  static CustomerThreads = KnownIssueModelCustomerThreadModel;

  subCollections!: {
    customersThreads: KnownIssueModelCustomerThreadModel;
  };
}

export class GcpKnownIssueModel extends KnownIssueModel {
  platform!: KnownIssuePlatform.GCP;
  exposureLevel!: string;
  summary!: string;
  symptoms!: string;
  workaround!: string;
  locations?: string[];
}

export class AwsKnownIssueModel extends KnownIssueModel {
  platform!: KnownIssuePlatform.AWS;
  lastUpdatedTime!: Timestamp;
  region!: string;
  endTime!: Timestamp;
  availabilityZone?: string;
  workaround?: string;
  nextUpdateTime?: Timestamp;
  exposureLevel!: string;
}
