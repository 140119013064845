import { collection, Reference, Timestamp } from "@doitintl/models-types";
import { CustomerModel } from "./Customer";
import { ContractModel, CommitmentPeriod, ContractFile, UpdatedBy } from "./Contract";
import { EntityModel } from "./Entity";
import { AssetModel } from "./Asset";

@collection("vendorContracts")
export class VendorContractModel {
  active!: boolean; // double check if needed
  assets?: Reference<AssetModel>[];
  commitmentPeriods?: CommitmentPeriod[];
  commitmentRollover?: boolean;
  contractFile!: ContractFile;
  credits?: Credits;
  customer!: Reference<CustomerModel>;
  customerContract!: Reference<ContractModel>;
  discount!: number;
  endDate!: Timestamp | null;
  entity!: Reference<EntityModel> | null;
  properties?: AWSProperties;
  startDate!: Timestamp;
  timeCreated!: Timestamp;
  timeUpdated!: Timestamp;
  commitmentType!: CommitmentType;
  updatedBy!: UpdatedBy;
  vendor!: VendorType;
}

type CommitmentType = "B2B" | "B2B lite" | "PPA" | null;

type VendorType = "amazon-web-services" | "google-cloud";

type Credits = {
  vendorToDoit: {
    amount: number;
    currency: string;
  };
};

type AWSProperties = {
  discount: number;
  partnerMargin: number;
};
