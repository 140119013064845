import { useMemo, useEffect, useState, createContext, ReactNode, useContext, ComponentType, FC } from "react";
import { getCollection } from "@doitintl/models-firestore";
import { ContractModel, CustomerModel, EntityModel } from "@doitintl/cmp-models";
import { DateTime } from "luxon";
import { getDisplayName } from "recompose";
import { Contracts, Customer } from "../../types";
import { useUserContext } from "../UserContext";
import { arrayFromDocChange } from "./arrayFromDocChange";
import { useEntitiesContext } from "./EntitiesContext";

export type ContractsContextType = {
  contracts: Contracts;
  contractsLoading: boolean;
};

const defaultContractsContext = { contracts: [], contractsLoading: true };
const contractsContext = createContext<ContractsContextType>({ ...defaultContractsContext });

export const ContractsContextProvider = ({
  children,
  customer,
}: {
  children?: ReactNode;
  customer: Customer | undefined | null;
}) => {
  const { entities } = useEntitiesContext();
  const { userRoles } = useUserContext();

  const [contracts, setContracts] = useState<Contracts>();
  const [contractsLoading, setContractsLoading] = useState<boolean>(true);

  const entitiesIdsAsJson = useMemo(() => JSON.stringify((entities ?? []).map((entity) => entity.id)), [entities]);

  useEffect(() => {
    setContractsLoading(true);
    setContracts([]);

    if (!customer?.id) {
      setContractsLoading(false);
      return;
    }

    // Users who manage assets must have access to read the contracts from firestore
    // to be able to see correct pricing when updating subscription (discount is set on contract).
    if (!userRoles?.assetsManager && !userRoles?.contractsViewer && !userRoles?.doitEmployee) {
      setContractsLoading(false);
      return;
    }

    const customerRef = getCollection(CustomerModel).doc(customer?.id);
    const now = DateTime.utc().toJSDate();
    const query = getCollection(ContractModel)
      .where("customer", "==", customerRef)
      .where("active", "==", true)
      .where("startDate", "<=", now);

    const entitiesIds = JSON.parse(entitiesIdsAsJson);

    const contractListeners = entitiesIds.map((entityId) =>
      query.where("entity", "==", getCollection(EntityModel).doc(entityId)).onSnapshot((querySnapshot) => {
        setContracts((prevContracts) => {
          const updatedContracts = [...(prevContracts ?? [])];
          arrayFromDocChange(updatedContracts, querySnapshot, (doc) => ({
            ...doc.asModelData(),
            id: doc.id,
          }));

          return updatedContracts;
        });

        setContractsLoading(false);
      })
    );

    return () => {
      contractListeners.forEach((unsubscribe) => unsubscribe());
    };
  }, [customer?.id, entitiesIdsAsJson, userRoles?.assetsManager, userRoles?.contractsViewer, userRoles?.doitEmployee]);

  return (
    <contractsContext.Provider value={{ contracts: contracts ?? [], contractsLoading }}>
      {children}
    </contractsContext.Provider>
  );
};

export function useContractsContext(): ContractsContextType {
  return useContext(contractsContext);
}

const ContractsContextConsumer = contractsContext.Consumer;

type Props = ContractsContextType;

export type WithContractsContext = Props;

export type WithContracts = {
  contracts: Contracts;
};

export function withContracts<P extends object>(Component: ComponentType<P & Props>) {
  const WrappedComponent: FC<P> = (props) => (
    <ContractsContextConsumer>{(context) => <Component {...context} {...props} />}</ContractsContextConsumer>
  );

  WrappedComponent.displayName = `withContracts(${getDisplayName(WrappedComponent)})`;

  return WrappedComponent;
}
