import React from "react";
import firebase from "firebase/compat/app";
import { DoitRole, RoleModel, UserModel, Permission, AppModel } from "@doitintl/cmp-models";
import { getCollection, WithFirebaseModel } from "@doitintl/models-firestore";
import { getDisplayName } from "recompose";
import merge from "lodash/merge";
import Permissions from "../utils/permissions";
import { RequiredProperties } from "../utils/RequiredProperties";
import { useAuthContext } from "./AuthContext";
import { useFirestoreContext } from "./FirestoreContext";

// firebase supports user without email, but our system doesn't allow login require email provider (google, ms, user/pass)
// using the native firebase.User will require a lot of places to check for the email but we already know globally that the user has email
// extend the type of current user to reflect that
export type FirebaseUserWithEmail = Omit<firebase.User, "email"> & {
  email: string;
};

export type UserModelType = Omit<WithFirebaseModel<UserModel>, "roles"> & {
  id: string;
  ref: firebase.firestore.DocumentReference;
  roles: ({ id: string } & WithFirebaseModel<RoleModel>)[];
};

type UserContextType = {
  userModel?: UserModelType;
};

type FullUserContextType = UserContextType & {
  userRoles?: Permissions | null;
  doitRoles?: DoitRole[];
};

type FullUserContextTypeWithUser = Required<FullUserContextType> & {
  customerId?: string;
};

export const UserContext = React.createContext<FullUserContextType>({});

export function useUserContext({
  requiredRoles,
  allowNull,
}: {
  requiredRoles: true;
  allowNull: true;
}): RequiredProperties<FullUserContextType, "userRoles">;

export function useUserContext({ allowNull }: { allowNull: false }): FullUserContextTypeWithUser;
export function useUserContext(): FullUserContextType;

export function useUserContext(
  { allowNull, requiredRoles }: { allowNull?: boolean; requiredRoles?: boolean } = {
    allowNull: true,
    requiredRoles: false,
  }
): FullUserContextType {
  const context = React.useContext(UserContext);

  if (requiredRoles && !context.userRoles) {
    throw new Error("useUserContext was used without userRoles");
  }

  if (!allowNull && !context.userModel) {
    throw new Error("useUserContext was used without user");
  }

  return context;
}

const useRoles = ({ userModel, doitRoles }: { userModel?: UserModelType; doitRoles?: DoitRole[] }) => {
  const { token, isDoitEmployee } = useAuthContext();
  const [userRoles, setUserRoles] = React.useState<Permissions | null>();

  React.useEffect(() => {
    // if its a doit employee wait for the doitRoles to be set
    if (!token?.claims || !userModel || (isDoitEmployee && !doitRoles)) {
      setUserRoles(null);
      return;
    }

    setUserRoles(
      new Permissions(
        {
          permissions: userModel.permissions,
          roles: userModel.roles,
        },
        token.claims,
        doitRoles
      )
    );
  }, [token?.claims, userModel, isDoitEmployee, doitRoles]);

  return [userRoles];
};

export const UserContextProvider = ({ value, children }: { value: UserContextType; children?: React.ReactNode }) => {
  const { currentUser, isDoitEmployee } = useAuthContext();
  const { firestoreInitialized } = useFirestoreContext();
  const [doitRoles, setDoitRoles] = React.useState<DoitRole[]>();

  React.useEffect(() => {
    // hack to set the id of the currentUSer as many places in the code assume it's there
    if (value.userModel && currentUser) {
      (currentUser as any).id = value.userModel.id;
    }
  }, [currentUser, value.userModel]);

  React.useEffect(() => {
    if (!currentUser?.email || !isDoitEmployee || !firestoreInitialized) {
      return;
    }

    return getCollection(AppModel)
      .doc("doit-employees")
      .collection("doitRoles")
      .where("users", "array-contains", currentUser.email)
      .onSnapshot((querySnapshot) => {
        setDoitRoles(() => querySnapshot.docs.map((docSnap) => docSnap.get("roleName")));
      });
  }, [currentUser?.email, isDoitEmployee, firestoreInitialized]);

  const [userRoles] = useRoles({ userModel: value.userModel, doitRoles });

  return (
    <UserContext.Provider
      value={{
        ...value,
        userRoles,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

type TestingRole = { name: string; permissions: { id: string }[] };

export const UserContextProviderForTesting = ({
  children,
  value,
}: {
  children?: React.ReactNode;
  value?: Partial<
    Omit<UserModelType, "userModel"> & {
      doitRoles?: DoitRole[];
      userModel: Partial<
        Omit<UserModelType, "roles"> & {
          permissions?: Permission[];
          roles?: TestingRole[];
        }
      >;
    }
  >;
}) => {
  const { userId, currentUser, isDoitEmployee, token } = useAuthContext();

  if (!token) {
    return null;
  }

  const defaultUserModel: Partial<typeof value> = {
    displayName: currentUser?.displayName ?? "",
    email: currentUser?.email,
    id: userId,
    roles: [],
  };

  const mergedUserModel = merge({}, defaultUserModel, value?.userModel ?? {});
  const doitRoles = value?.doitRoles || (isDoitEmployee ? [] : undefined);
  mergedUserModel.ref = getCollection(UserModel).doc(mergedUserModel.id).ref;

  const userRoles = new Permissions(mergedUserModel, token?.claims, doitRoles);

  if (!userRoles) {
    return null;
  }

  const providerValue = { userModel: mergedUserModel as any, userRoles } as any;

  return <UserContext.Provider value={providerValue}>{children}</UserContext.Provider>;
};

export const UserContextConsumer = UserContext.Consumer;

type Props = FullUserContextType;

export type WithUser = Props;
export type WithUserRoles = {
  userRoles: Permissions;
};

export const UserRolesGuard = ({ children }: { children: React.ReactNode }) => {
  const { currentUser } = useAuthContext();
  const { userRoles } = useUserContext();

  if (!userRoles || !currentUser) {
    return null;
  }

  return <>{children}</>;
};

export function withUser<P extends object>(Component: React.ComponentType<P & Props>) {
  const WrappedComponent: React.FC<P> = (props) => (
    <UserContextConsumer>
      {(context) => (
        <UserRolesGuard>
          <Component user={context.userModel} {...context} {...props} />
        </UserRolesGuard>
      )}
    </UserContextConsumer>
  );

  WrappedComponent.displayName = `withUser(${getDisplayName(Component)})`;

  return WrappedComponent;
}
