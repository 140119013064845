// noinspection JSUnusedGlobalSymbols

import { collection, Reference, Timestamp } from "@doitintl/models-types";
import { RoleModel } from "./Role";
import { CustomerModel, CustomerModelOrganizationModel } from "./Customer";
import { EntityModel } from "./Entity";
import { DashboardModelAttributionModel } from "./Dashboard";
import { UserEnrichment } from "./Enrichment";

export enum UserNotificationEnum {
  NewInvoice = 1,
  CostAnomalies = 2,
  PaymentOverdue = 3,
  CreditsUtilization = 4,
  CloudQuotaUtilization = 5,
  CloudKnownIssues = 6,
  DailyDigest = 7,
  MonthlyDigest = 8,
}

@collection("users")
export class UserModel {
  accessKey?: null | string;
  customer?: {
    name: string;
    _name: string;
    ref: Reference<CustomerModel>;
  };
  dailyDigests?: Reference<DashboardModelAttributionModel>[];
  disabled?: boolean;
  displayName!: string;
  domain!: string;
  email!: string;
  enrichment?: UserEnrichment;
  entities?: Reference<EntityModel>[] | null;
  extraDelight?: boolean;
  firstName!: string;
  jobFunction?: number;
  lastLogin?: Timestamp;
  lastName!: string;
  newFeatures?: NewFeature[];
  organizations?: Reference<CustomerModelOrganizationModel>[];
  permissions?: Permission[];
  phone?: null | string;
  phoneExtension?: null | string;
  role?: number | string;
  roles?: Reference<RoleModel>[];
  sessionCount?: number;
  userNotifications?: UserNotificationEnum[];
  userSettings?: UserSettings;
  termsOfService?: {
    type: "standalone-estimation";
    timeCreated: Timestamp;
  } | null;
}

enum NewFeature {
  NewReportsUI = "newReportsUI",
}

export enum Permission {
  BillingProfilesAdmin = "1SmYWoSAO1frHKjt34Gz",
  CloudAnalytics = "sfmBZeLN8uXWooCqJ4NO",
  FlexibleRI = "tvQnB14mSGr8LSU8reYH",
  InvoicesViewer = "HN6A3cPzDBcAIlc3ncDy",
  AssetsManager = "wfDH3k1FmYKHlQBwGIzZ",
  Settings = "AIzQjXTUQDgeZjXqNsgF",
  SandboxAdmin = "jnrMNJLdzRsyLCHCfq6T",
  SandboxUser = "KpogRUOHgMlroIH8xOUQ",
  IAM = "ZqLGIVDUhNiSEDtrEb0S",
  ContractsViewer = "8zXuFyohNSiiLy2ZQ6Xu",
  AnomaliesViewer = "eUKNGKekajR0NbOkHFfC",
  PerksViewer = "itIlDPCy18ymtEVgaW0B",
  IssuesViewer = "dEJbIiUcHn8GhW7IiWLW",
  BudgetsManager = "BgYDGr8dABLKjUkys7AD",
  MetricsManager = "fSFpOG5xUeHPlYVI5N1k",
  AttributionsManager = "AnJW2Hwipmucak00yko0",
  SupportRequester = "jg1YHuQhsRlg5msNhpZZ",
  Licenses = "wfDH3k1FmYKHlQBwGIzZ",
  RampPlansViewer = "u10SsW3V123pb5lVdgcO",
  CAOwnerRoleAssigner = "pwWRo04l9uXUYa8rIQSW",
  UsersManager = "ZqLGIVDUhNiSEDtrEb0S",
}

interface UserSettings {
  anomalyAbsolute?: number;
  anomalyAlerts?: number;
}
