import { collection, Reference } from "@doitintl/models-types";
import { CustomerModel } from "./Customer";
import { EntityModel, EntityModelBucketModel } from "./Entity";
import { ContractModel } from "./Contract";
import { AWSSupportData } from "./Asset";

export type AssetSettingModelAutomation = {
  enabled: boolean;
  quantity: number;
  email: string;
};

export type AssetType =
  | "amazon-web-services"
  | "amazon-web-services-standalone"
  | "amazon-web-services-reseller"
  | "google-cloud-project"
  | "google-cloud"
  | "google-cloud-standalone"
  | "google-cloud-direct"
  | "google-cloud-reseller"
  | "g-suite"
  | "office-365"
  | "microsoft-azure"
  | "bettercloud"
  | "zendesk"
  | "looker";

@collection("assetSettings")
export class AssetSettingModel {
  customer!: Reference<CustomerModel> | null;
  entity!: Reference<EntityModel> | null;
  contract?: Reference<ContractModel> | null;
  bucket?: Reference<EntityModelBucketModel> | null;
  automation?: AssetSettingModelAutomation | null;
  type!: AssetType;
  tags?: string[] | null;
  settings?: { support?: AWSSupportData };
}
