import { collection, Reference, Timestamp } from "@doitintl/models-types";
import { CustomerModel } from "./Customer";
import { PermissionModel } from "./Permission";

export type CustomRoleModel = {
  customer: Reference<CustomerModel>;
  customerType?: "consolidated" | "standalone";
  description?: string;
  inUse: number;
  name: string;
  permissions: Reference<PermissionModel>[];
  timeCreated: Timestamp;
  timeModified: Timestamp;
  type: "custom";
};

export type PresetRoleModel = {
  customer: null;
  customerType?: "consolidated" | "standalone";
  description?: string;
  inUse: number;
  name: string;
  permissions: Reference<PermissionModel>[];
  timeCreated: Timestamp;
  timeModified: Timestamp;
  type: "preset";
};

@collection("roles")
export class RoleModel {
  customer?: Reference<CustomerModel> | null;
  customerType?: "consolidated" | "standalone";
  description?: string;
  inUse!: number;
  name!: string;
  permissions!: Reference<PermissionModel>[];
  timeCreated!: Timestamp;
  timeModified!: Timestamp;
  type!: "preset" | "custom";
}
