import React, { createContext, useContext, useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { AccountManagersCompany } from "@doitintl/cmp-models";
import { getDisplayName } from "recompose";
import merge from "lodash/merge";
import { initFirebaseAuth, auth } from "../utils/firebase";
import { usePrevious } from "../utils/usePrevious";
import { addAuthWindowFunction } from "../utils/windowInit";
import { FirebaseUserWithEmail } from "./UserContext";

type AuthContextType = {
  currentUser?: FirebaseUserWithEmail | null;
  token?: firebase.auth.IdTokenResult | null;
  auth?: firebase.auth.Auth;
};

type FullAuthContextType = AuthContextType & {
  isDoitEmployee: boolean;
  isDoitOwner: boolean;
  customerId?: string;
  userId?: string;
} & (
    | {
        isDoitPartner: false;
        partnerCompany?: undefined;
      }
    | {
        isDoitPartner: true;
        partnerCompany: Exclude<AccountManagersCompany, "doit">;
      }
  );

type FullAuthContextTypeWithUser = FullAuthContextType & {
  currentUser: FirebaseUserWithEmail;
  token: firebase.auth.IdTokenResult;
  userId: string;
};

const defaultValues: FullAuthContextType = {
  isDoitEmployee: false,
  isDoitOwner: false,
  isDoitPartner: false,
};

const authContext = createContext<FullAuthContextType>({ ...defaultValues });

export function useAuthContext(): FullAuthContextType;
export function useAuthContext({ mustHaveUser }: { mustHaveUser: true }): FullAuthContextTypeWithUser;

export function useAuthContext(
  { mustHaveUser }: { mustHaveUser?: boolean } = {
    mustHaveUser: false,
  }
): Partial<FullAuthContextType> {
  const authContextValue = useContext(authContext);

  if (mustHaveUser && (!authContextValue.token || !authContextValue.currentUser)) {
    throw new Error("request auth context before login");
  }

  return authContextValue;
}

const extractTokenData = (tokenClaims?: Record<string, any> | null) => ({
  isDoitEmployee: tokenClaims?.doitEmployee ?? false,
  isDoitOwner: tokenClaims?.doitOwner ?? false,
  isDoitPartner: tokenClaims?.doitPartner ?? false,
  customerId: tokenClaims?.customerId,
  partnerCompany: tokenClaims?.partnerCompany,
  userId: tokenClaims?.userId || tokenClaims?.user_id,
});

export const AuthContextProviderForTesting = ({
  children,
  value,
}: {
  children?: React.ReactNode;
  value?: Partial<{
    currentUser: Partial<FirebaseUserWithEmail> | null;
    token: Partial<firebase.auth.IdTokenResult> | null;
  }>;
}) => {
  const defaultAuth = {
    currentUser: {},
    token: {},
  };

  const mergedAuth = merge({}, defaultAuth, value ?? {});

  const currentUser = mergedAuth.currentUser;
  const actualToken = mergedAuth.token;

  if (actualToken && !actualToken.claims) {
    actualToken.claims = {};
  }

  return (
    // the Provider gives access to the context to its children

    <authContext.Provider
      value={
        {
          ...extractTokenData(mergedAuth.token.claims),
          ...value,
          currentUser: { metadata: {}, ...currentUser },
          token: actualToken,
        } as any
      }
    >
      {children}
    </authContext.Provider>
  );
};

export const AuthContextProvider = ({ children }: { children?: React.ReactNode }) => {
  const [authState, setAuthState] = useState<FullAuthContextType>({
    ...defaultValues,
  });

  function stateFromToken(
    currentUser: FirebaseUserWithEmail | null,
    currentToken: firebase.auth.IdTokenResult | null
  ): FullAuthContextType {
    return {
      ...extractTokenData(currentToken?.claims),
      currentUser,
      token: currentToken,
      auth,
    };
  }

  useEffect(() => {
    initFirebaseAuth();
  }, []);

  useEffect(
    () =>
      auth.onAuthStateChanged(async (user) => {
        const currentToken = (await user?.getIdTokenResult()) ?? null;
        setAuthState(stateFromToken(user as FirebaseUserWithEmail, currentToken));
      }),
    []
  );

  useEffect(
    () =>
      auth.onIdTokenChanged(async (user) => {
        const currentToken = (await user?.getIdTokenResult()) ?? null;

        if (currentToken?.token !== authState.token?.token) {
          setAuthState(stateFromToken(user as FirebaseUserWithEmail, currentToken));
        }
      }),
    [authState]
  );

  return (
    // the Provider gives access to the context to its children
    <authContext.Provider value={authState}>{children}</authContext.Provider>
  );
};

type AuthStateChangeCallback = (user: FirebaseUserWithEmail | null, token: firebase.auth.IdTokenResult | null) => any;

export const AuthSubscribe = ({ onAuthStateChanged }: { onAuthStateChanged: AuthStateChangeCallback }) => {
  const { currentUser, token, auth } = useAuthContext();
  const prevCurrentUser = usePrevious(currentUser);

  useEffect(() => {
    if (token !== undefined && currentUser !== undefined && prevCurrentUser !== currentUser) {
      onAuthStateChanged(currentUser, token);
    }
  }, [currentUser, prevCurrentUser, onAuthStateChanged, token]);

  useEffect(() => {
    if (auth) {
      const signInWithEmailAndPassword = async ({ email, password, tenantId }) => {
        auth.tenantId = tenantId;
        await auth.signInWithEmailAndPassword(email, password);
      };
      const signOut = () => auth.signOut();
      addAuthWindowFunction({ signInWithEmailAndPassword, signOut });
    }
  }, [auth]);

  return null;
};

export const AuthContextConsumer = authContext.Consumer;

type Props = FullAuthContextTypeWithUser;

export type WithAuth = Props;
export type WithToken = {
  token: firebase.auth.IdTokenResult;
};

export function withAuth<P extends object>(Component: React.ComponentType<P & Props>) {
  const WrappedComponent: React.FC<P> = (props) => {
    const authContext = useAuthContext({ mustHaveUser: true });
    return <Component {...authContext} {...props} />;
  };

  WrappedComponent.displayName = `withAuth(${getDisplayName(WrappedComponent)})`;

  return WrappedComponent;
}
