import { CloudAnalyticsModelGkeCostAllocationModel, Metadata } from "@doitintl/cmp-models";
import { MetadataOption } from "../../types";
import { b64EncodeUnicode } from "../../utils/common";
import { AnalyticsMetadata } from "../../Components/hooks/cloudAnalytics/useAnalyticsMetadata";
import { gkeLabelPrefix } from "./utilities";
import { Dictionary } from "./types";

const metadataFields = ["kubernetes_namespace", "kubernetes_cluster_name"];
const costAllocationLabels = ["k8s-namespace", "goog-k8s-cluster-name"];

export enum MetadataFields {
  GKE_LABEL = "GKE Labels",
  OPTIONAL_GKE_LABEL_KEY = "gke_labels_keys",
  OPTIONAL_LABELS_KEY = "labels_keys",
  LABEL_FIELD = "T.labels",
  GKE_LABEL_FIELD = "GKE.labels",
}

export const isGKECostAllocationOptionKey = (mdKey: string): boolean => !!metadataFields.find((f) => mdKey === f);

const GKECostAllocationFlowHandler = (
  gkeCostAllocation?: CloudAnalyticsModelGkeCostAllocationModel | null,
  gkeCostAllocationFeatureEnabled?: boolean
) => {
  const switchToCostAllocation = gkeCostAllocation?.enabled || false;

  const isGKELabelPrefix = (label: any) => label.startsWith(gkeLabelPrefix);

  return {
    filterMetadata: (metadata: AnalyticsMetadata) => {
      let filteredMetadata = metadata.filter(
        (md) => switchToCostAllocation || metadataFields.find((f) => md?.id === `${Metadata.FIXED}:${f}`) === undefined
      );
      if (gkeCostAllocationFeatureEnabled) {
        filteredMetadata = filteredMetadata.filter((md) => !md?.id.includes(`${Metadata.GKE}:`));
      }
      return filteredMetadata;
    },
    modifyAttributionGkeLabel: (data: MetadataOption["data"], labelKey: string) => {
      if (switchToCostAllocation && isGKELabelPrefix(labelKey)) {
        labelKey = labelKey.replace(gkeLabelPrefix, "");
        data.label = labelKey;
        data.field = MetadataFields.LABEL_FIELD;
        data.plural = MetadataFields.GKE_LABEL;
        data.subType = Metadata.GKE_COST_ALLOCATION_LABEL;
      }
      return { data, parsedLabel: labelKey };
    },

    modifyMdGroupsForGkeLabels: (mdGroups: Dictionary<MetadataOption[]>) => {
      if (!switchToCostAllocation) {
        return mdGroups;
      }

      if (Metadata.LABEL in mdGroups) {
        mdGroups[Metadata.LABEL] = mdGroups[Metadata.LABEL].filter(
          (md) => costAllocationLabels.find((l) => md.id === `${Metadata.LABEL}:${b64EncodeUnicode(l)}`) === undefined
        );
      }

      return mdGroups;
    },
  };
};

export default GKECostAllocationFlowHandler;
