import {
  ReactNode,
  useMemo,
  useState,
  useEffect,
  createContext,
  useCallback,
  useContext,
  ComponentType,
  FC,
} from "react";
import { getCollection, ModelReference, QueryModel } from "@doitintl/models-firestore";
import {
  AssetModel,
  AssetType,
  AssetTypeAmazonWebServices,
  AssetTypeAwsStandalone,
  AssetTypeGSuite,
  AssetTypeGoogleCloud,
  AssetTypeGoogleCloudProject,
  AssetTypeGoogleCloudStandalone,
  AssetTypeMicrosoftAzure,
  AssetTypeOffice365,
  CustomerModel,
  CustomerSecurityMode,
  EntityModel,
  IntegrationModel,
} from "@doitintl/cmp-models";

import firebase from "firebase/compat/app";
import { Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { Link as RouterLink } from "react-router-dom";
import { getDisplayName } from "recompose";
import noop from "lodash/noop";
import { useSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { createLocationDescriptorWithReferrer } from "../../Pages/Entity/useGoBackToReferrer";
import { Assets, Customer } from "../../types";
import { Handshake } from "../../types/Handshake";

import { useEntitiesContext } from "./EntitiesContext";
import { arrayFromDocChange } from "./arrayFromDocChange";

type AssetsContextType = {
  assets: Assets;
  handshakes: Handshake[];
  assetsLoading: boolean;
  appendLoadedAssetTypes: (assetTypes: AssetType[]) => void;
};

export type FullAssetsContextType = AssetsContextType & {
  appendLoadedAssetTypes: (assetType: AssetType[]) => void;
  hasAWSAssets: boolean;
  hasGCPAssets: boolean;
};

const defaultAssetsContext = {
  assets: {},
  handshakes: [],
  assetsLoading: true,
  appendLoadedAssetTypes: noop,
  hasGCPAssets: false,
  hasAWSAssets: false,
};
const assetsContext = createContext<FullAssetsContextType>({ ...defaultAssetsContext });
const defaultLoadedAssetsType: AssetType[] = [
  AssetTypeGSuite,
  AssetTypeOffice365,
  AssetTypeGoogleCloud,
  AssetTypeAmazonWebServices,
  AssetTypeMicrosoftAzure,
  AssetTypeGoogleCloudStandalone,
  AssetTypeAwsStandalone,
];

const isTypeExistInAssets = (assets: Assets | undefined, type: string): boolean => {
  if (assets) {
    for (const eId in assets) {
      if (assets[eId].find((asset) => asset.data.type === type)) {
        return true;
      }
    }
  }
  return false;
};

export const AssetsContextProvider = ({
  children,
  customer,
}: {
  children?: ReactNode;
  customer: Customer | undefined | null;
}) => {
  const { entities } = useEntitiesContext();
  const snackbar = useSnackbar();

  const [assets, setAssets] = useState<Assets>({});
  const [assignedAssetsLoading, setAssignedAssetsLoading] = useState<boolean>(true);
  const [unassignedAssetsLoading, setUnassignedAssetsLoading] = useState<boolean>(true);
  const [assetsLoading, setAssetsLoading] = useState<boolean>(true);
  const [loadedAssetsTypes, setLoadedAssetTypes] = useState<AssetType[]>(defaultLoadedAssetsType);
  const [handshakes, setHandshakes] = useState<Handshake[]>([]);
  const [handshakesLoading, setHandshakesLoading] = useState<boolean>(true);

  useEffect(() => {
    const someAssetsLoading = [assignedAssetsLoading, unassignedAssetsLoading, handshakesLoading].some(
      (loading) => loading
    );
    setAssetsLoading(someAssetsLoading);
  }, [assignedAssetsLoading, unassignedAssetsLoading, handshakesLoading]);

  const captureHandshakes = useCallback((customerId: string) => {
    const customerRef = getCollection(CustomerModel).doc(customerId);

    return getCollection(IntegrationModel)
      .doc("amazon-web-services")
      .collection("handshakes")
      .where("customer", "==", customerRef)
      .where("visible", "==", true)
      .onSnapshot(
        (querySnapshot) => {
          setHandshakes((prevShakes) => {
            const newHandshakes = [...prevShakes];
            arrayFromDocChange(newHandshakes, querySnapshot, (doc) => ({
              data: doc.asModelData(),
              snapshot: doc.snapshot,
            }));
            return newHandshakes;
          });
          setHandshakesLoading(false);
        },
        () => {
          setHandshakesLoading(false);
        }
      );
  }, []);

  const appendLoadedAssetTypes = useCallback(
    (assetTypes: AssetType[]) => {
      setLoadedAssetTypes((prevLoadedAssets) => {
        const unionLoadedAndRequested = new Set([...prevLoadedAssets, ...assetTypes]);

        if (customer?.securityMode === CustomerSecurityMode.RESTRICTED) {
          unionLoadedAndRequested.delete(AssetTypeGoogleCloudProject);
        }

        // if we didn't add anything no point in returning new value
        if (unionLoadedAndRequested.size === prevLoadedAssets.length) {
          return prevLoadedAssets;
        }

        setAssets({});

        return [...unionLoadedAndRequested];
      });
    },
    [customer?.securityMode]
  );

  const { hasGCPAssets, hasAWSAssets } = useMemo(
    () => ({
      hasAWSAssets: isTypeExistInAssets(assets, AssetTypeAmazonWebServices),
      hasGCPAssets: isTypeExistInAssets(assets, AssetTypeGoogleCloud),
    }),
    [assets]
  );

  const getNewAssets = useCallback(
    (prevAssets: Assets, entityId: string) => (prevAssets && entityId in prevAssets ? [...prevAssets[entityId]] : []),
    []
  );

  const listenToUnassignedEntityAssets = useCallback((query: QueryModel<AssetModel>) => {
    const getNewUnassignedAssets = (prevAssets: Assets) => (prevAssets?._unassigned ? [...prevAssets._unassigned] : []);

    return query.where("entity", "==", null).onSnapshot((querySnapshot) => {
      if (querySnapshot.empty) {
        setAssets((prevAssets) => ({ ...prevAssets, _unassigned: [] }));
        setUnassignedAssetsLoading(false);
        return;
      }

      setAssets((prevAssets) => {
        const newUnassignedAssets = getNewUnassignedAssets(prevAssets);
        arrayFromDocChange(newUnassignedAssets, querySnapshot, (doc) => ({
          data: doc.asFirestoreData(),
          id: doc.id,
          snapshot: doc.snapshot,
          ref: doc.ref,
        }));

        return {
          ...prevAssets,
          _unassigned: newUnassignedAssets,
        };
      });

      setUnassignedAssetsLoading(false);
    });
  }, []);

  const listenToEntityAssets = useCallback(
    (query: QueryModel<AssetModel>, entityRef: ModelReference<EntityModel>) =>
      query.where("entity", "==", entityRef).onSnapshot((querySnapshot) => {
        if (querySnapshot.empty) {
          setAssets((prevAssets) => ({ ...prevAssets, [entityRef.id]: [] }));
          setAssignedAssetsLoading(false);
          return;
        }

        setAssets((prevAssets) => {
          const newAssets = getNewAssets(prevAssets, entityRef.id);

          arrayFromDocChange(newAssets, querySnapshot, (doc) => ({
            data: doc.asFirestoreData(),
            id: doc.id,
            snapshot: doc.snapshot,
            ref: doc.ref,
          }));

          return {
            ...prevAssets,
            [entityRef.id]: newAssets,
          };
        });

        setAssignedAssetsLoading(false);
      }),
    [getNewAssets]
  );

  const entitiesIdsAsJson = useMemo(() => JSON.stringify((entities ?? []).map((entity) => entity.id)), [entities]);

  useEffect(() => {
    setAssetsLoading(true);
    setAssignedAssetsLoading(true);
    setUnassignedAssetsLoading(true);
    setAssets({});
    setHandshakes([]);

    if (!customer?.id) {
      setAssignedAssetsLoading(false);
      setUnassignedAssetsLoading(false);
      setAssetsLoading(false);
      return;
    }

    const assetsListeners: firebase.Unsubscribe[] = [];

    const customerRef = getCollection(CustomerModel).doc(customer.id);
    const query = getCollection(AssetModel).where("customer", "==", customerRef).where("type", "in", loadedAssetsTypes);

    const unassignedListener = listenToUnassignedEntityAssets(query);
    assetsListeners.push(unassignedListener);

    const entitiesIds: string[] = JSON.parse(entitiesIdsAsJson);

    const assetsByEntities = () => {
      if (!entitiesIds.length) {
        setAssignedAssetsLoading(false);
        return;
      }

      for (const entityId of entitiesIds) {
        // due to the fact that we re-initialize the query listeners for every entity, we need to clear previously cached assets
        setAssets((prevAssets) => ({ ...prevAssets, [entityId]: [] }));

        const entityRef = getCollection(EntityModel).doc(entityId);
        const listener = listenToEntityAssets(query, entityRef);

        assetsListeners.push(listener);
      }
    };

    assetsByEntities();

    assetsListeners.push(captureHandshakes(customer.id));

    return () => {
      assetsListeners.forEach((unsubscribe) => unsubscribe());
    };
  }, [
    customer?.id,
    entitiesIdsAsJson,
    listenToEntityAssets,
    listenToUnassignedEntityAssets,
    loadedAssetsTypes,
    captureHandshakes,
  ]);

  // verifyEntitiesInvoice
  useEffect(() => {
    if (!entities || !customer) {
      return;
    }

    for (const entity of entities) {
      const entityCustomer = entity.customer;
      if (!entityCustomer || !entity.active || entity.invoicing.mode !== "CUSTOM") {
        continue;
      }

      getCollection(AssetModel)
        .where("customer", "==", entityCustomer)
        .where("entity", "==", entity.ref)
        .where("bucket", "==", null)
        .where("type", "in", loadedAssetsTypes)
        .limit(1)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            return;
          }

          snackbar.onOpen({
            message: "Some of your assets are not assigned to an invoice bucket",
            variant: "warning",
            autoHideDuration: 20000,
            action: [
              <Button
                key="link"
                component={RouterLink}
                to={createLocationDescriptorWithReferrer({
                  pathname: `/customers/${entityCustomer.id}/entities/${entity.id}/edit`,
                  hash: "invoice-settings",
                })}
                aria-label="Update invoice settings"
                variant="contained"
                onClick={() => snackbar.onClose()}
                color="primary"
              >
                ASSIGN ASSETS
              </Button>,
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => snackbar.onClose()}
                size="large"
              >
                <CloseIcon />
              </IconButton>,
            ],
          });
        });
    }
  }, [customer, entities, snackbar, loadedAssetsTypes]);

  const value = useMemo(
    () => ({
      assets: assets ?? {},
      assetsLoading,
      appendLoadedAssetTypes,
      hasAWSAssets,
      hasGCPAssets,
      handshakes,
    }),
    [appendLoadedAssetTypes, assets, assetsLoading, handshakes, hasAWSAssets, hasGCPAssets]
  );

  return <assetsContext.Provider value={value}>{children}</assetsContext.Provider>;
};

export const AssetsContextProviderForTesting = ({
  children,
  value,
}: {
  children?: ReactNode;
  value?: Partial<FullAssetsContextType>;
}) => {
  const actualValue = value ?? {};

  if (!actualValue.appendLoadedAssetTypes) {
    actualValue.appendLoadedAssetTypes = noop;
  }

  return <assetsContext.Provider value={actualValue as FullAssetsContextType}>{children}</assetsContext.Provider>;
};

export function useAssetsContext(): FullAssetsContextType {
  return useContext(assetsContext);
}

const AssetsContextConsumer = assetsContext.Consumer;

type Props = AssetsContextType;

export type WithAssets = Props;

export function withAssets<P extends object>(Component: ComponentType<P & Props>) {
  const WrappedComponent: FC<P> = (props) => (
    <AssetsContextConsumer>{(context) => <Component {...context} {...props} />}</AssetsContextConsumer>
  );

  WrappedComponent.displayName = `withAssets(${getDisplayName(WrappedComponent)})`;

  return WrappedComponent;
}
