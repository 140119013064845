import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import noop from "lodash/noop";
import {
  asModelRefFromFirestoreRef,
  DocumentSnapshotModel,
  getCollection,
  QueryDocumentSnapshotModel,
  useCollectionData,
  WithFirebaseModel,
} from "@doitintl/models-firestore";
import {
  AnalyticsResourceType,
  CustomerModel,
  DashboardModel,
  DashboardModelAttributionModel,
  EarlyAccessFeature,
} from "@doitintl/cmp-models";
import intersection from "lodash/intersection";
import { AttributionWRef, Customer } from "../types";
import { formatFirestoreDate, isGKEMeteringAttribution } from "../Pages/CloudAnalytics/utilities";

export const PresetAllGcpResources = "7mJDP8HtXXlpFTb2pVz7";
export const PresetAllAwsResources = "PvqyGcdFcTHh7aLUdGdf";

const attributionsTransform = (
  data: WithFirebaseModel<DashboardModelAttributionModel>,
  snapshot:
    | QueryDocumentSnapshotModel<DashboardModelAttributionModel>
    | DocumentSnapshotModel<DashboardModelAttributionModel>
): AttributionWRef => ({
  data,
  ref: asModelRefFromFirestoreRef(snapshot.ref),
  transform: {
    timeModified: formatFirestoreDate(data.timeModified),
  },
});

export type AttributionsContextType = {
  filteredAttributions: AttributionWRef[];
  attributions: AttributionWRef[];
  attributionsLoading: boolean;
  getAttributions: () => void;
};

const attributionsContextDefaultValue: AttributionsContextType = {
  filteredAttributions: [],
  attributions: [],
  attributionsLoading: false,
  getAttributions: noop,
};

const AttributionsContext = createContext({} as AttributionsContextType);

export const useAttributionsContext = () => {
  const { filteredAttributions, attributions, attributionsLoading, getAttributions } = useContext(AttributionsContext);

  useEffect(() => {
    if (attributions.length === 0) {
      getAttributions();
    }
  }, [attributions.length, getAttributions]);

  return {
    filteredAttributions,
    attributions,
    attributionsLoading,
  };
};

export const AttributionsContextProvider = ({
  children,
  customer,
}: {
  children?: ReactNode;
  customer: Customer | undefined | null;
}) => {
  const [shouldFetch, setShouldFetch] = useState(false);

  useEffect(() => {
    if (!customer?.id) {
      setShouldFetch(false);
    }
  }, [customer?.id]);

  const getAttributions = useCallback(() => setShouldFetch(true), []);

  const queries = useMemo(() => {
    if (!shouldFetch || !customer?.id) {
      return;
    }

    const collectionRef = getCollection(DashboardModel).doc("google-cloud-reports").collection("attributions");
    const customerRef = getCollection(CustomerModel).doc(customer?.id);

    const presetAttributions = collectionRef
      .where("type", "==", AnalyticsResourceType.PRESET)
      .where("customer", "==", null);

    const managedAttributions = collectionRef
      .where("type", "==", AnalyticsResourceType.MANAGED)
      .where("customer", "==", customerRef);

    const customAttributions = collectionRef
      .where("type", "==", AnalyticsResourceType.CUSTOM)
      .where("customer", "==", customerRef);

    return {
      presetAttributions,
      managedAttributions,
      customAttributions,
    };
  }, [shouldFetch, customer?.id]);

  const [presetAttributions, presetAttributionsLoading] = useCollectionData(queries?.presetAttributions, {
    transform: attributionsTransform,
  });

  const [customAttributions, customAttributionsLoading] = useCollectionData(queries?.managedAttributions, {
    transform: attributionsTransform,
  });

  const [managedAttributions, managedAttributionsLoading] = useCollectionData(queries?.customAttributions, {
    transform: attributionsTransform,
  });

  const attributionsLoading = presetAttributionsLoading || customAttributionsLoading || managedAttributionsLoading;

  const gkeCostAllocationFeatureEnabled = useMemo(
    () => (customer?.earlyAccessFeatures ?? []).includes(EarlyAccessFeature.GKE_COST_ALLOCATION),
    [customer]
  );

  const attributions = useMemo<AttributionWRef[]>(() => {
    if (attributionsLoading) {
      return [];
    }
    const list = [...(presetAttributions ?? []), ...(customAttributions ?? []), ...(managedAttributions ?? [])];
    return list.sort((a, b) => a.data.name.localeCompare(b.data.name));
  }, [attributionsLoading, customAttributions, managedAttributions, presetAttributions]);

  const filteredAttributions = useMemo<AttributionWRef[]>(
    () =>
      attributions.filter((attr) => {
        const cloudFilter =
          attr.data.type === AnalyticsResourceType.PRESET && attr.data.cloud?.length
            ? intersection(customer?.assets, attr.data.cloud).length > 0
            : true;

        return (
          !attr.data.draft && cloudFilter && (gkeCostAllocationFeatureEnabled ? isGKEMeteringAttribution(attr) : true)
        );
      }),
    [attributions, customer?.assets, gkeCostAllocationFeatureEnabled]
  );

  const value = useMemo(
    () => ({
      filteredAttributions,
      attributions,
      attributionsLoading,
      getAttributions,
    }),
    [attributions, attributionsLoading, filteredAttributions, getAttributions]
  );

  return <AttributionsContext.Provider value={value}>{children}</AttributionsContext.Provider>;
};

export const AttributionsContextProviderForTesting = ({
  children,
  value,
}: {
  children?: ReactNode;
  value?: Partial<AttributionsContextType>;
}) => {
  const actualValue = value ? value : attributionsContextDefaultValue;

  return (
    <AttributionsContext.Provider value={actualValue as AttributionsContextType}>
      {children}
    </AttributionsContext.Provider>
  );
};
