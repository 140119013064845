import { collection, Reference, Timestamp } from "@doitintl/models-types";
import { CustomerModel, CustomerModelOrganizationModel } from "./Customer";
import { EntityModel } from "./Entity";
import { Permission } from "./User";
import { DashboardModelAttributionModel } from "./Dashboard";
import { RoleModel } from "./Role";
import { UserEnrichment } from "./Enrichment";

export type InvitedBy = {
  email: string;
  name: null | string;
};

type UserSettings = {
  anomalyAbsolute?: number;
  anomalyAlerts?: number;
};

@collection("invites")
export class InviteModel {
  customer!: {
    _name: string;
    name: string;
    ref: Reference<CustomerModel>;
  };
  dailyDigests?: Reference<DashboardModelAttributionModel>[];
  defaultDashboard?: string;
  displayName?: string;
  email!: string;
  enrichment!: UserEnrichment;
  entities?: Reference<EntityModel>[] | null;
  entityCreateTemplate!: boolean;
  firstName?: string;
  invitedBy!: InvitedBy | string;
  jobFunction?: number;
  lastName?: string;
  organizations?: Reference<CustomerModelOrganizationModel>[];
  permissions!: Permission[];
  phone?: null | string;
  phoneExtension?: null | string;
  role?: number | string;
  roles?: Reference<RoleModel>[];
  sendEmail?: boolean;
  timestamp!: Timestamp;
  userNotifications?: number[];
  userSettings?: UserSettings;
  invitationSentTimestamp?: Timestamp;
}
