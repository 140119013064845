import React from "react";
import { useHistory } from "react-router";
import { matchPath, useLocation } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { getDisplayName } from "recompose";
import axios from "axios";
import { demoModeText } from "../assets/texts";
import { useWarningSnackbar } from "../Components/SharedSnackbar/SharedSnackbar.context";
import { useApiContext } from "../api/context";
import { useCustomerContext } from "../Context/CustomerContext";
import LoadingPage from "../Layout/LoadingPage";
import { CustomerType } from "../types";
import { useAuthContext } from "../Context/AuthContext";
import mixpanel from "./mixpanel";
import { auth, demoApp, rootAuth } from "./firebase";
import { consoleErrorWithSentry } from "./errorHandling";
import { usePrevious } from "./usePrevious";

type DemoModeContextType = {
  isDemoMode: boolean;
  toggleDemoMode: () => void;
};

type FullDemoModeContextType = DemoModeContextType & {
  setIsDemoMode: (isDemoMode: boolean) => void;
  setHandleToggleDemoMode: (handleToggleDemoMode: () => void) => void;
};

export const demoModeContext = React.createContext<FullDemoModeContextType>({} as FullDemoModeContextType);

export const DemoModeContextConsumer = demoModeContext.Consumer;

export const getDemoMode = () => sessionStorage?.getItem("demoMode") === "true";

export const DemoModeContextProvider = ({ children }: { children?: React.ReactNode }) => {
  const [isDemoMode, setIsDemoMode] = React.useState(getDemoMode());
  const handleToggleDemoMode = React.useRef(() => {
    // empty function
  });

  const setHandleToggleDemoMode = React.useCallback((newHandleToggleDemoMode: () => void) => {
    handleToggleDemoMode.current = newHandleToggleDemoMode;
  }, []);

  const toggleDemoMode = React.useCallback(() => {
    if (handleToggleDemoMode.current) {
      handleToggleDemoMode.current();
    }
  }, []);

  return (
    <demoModeContext.Provider value={{ setIsDemoMode, setHandleToggleDemoMode, toggleDemoMode, isDemoMode }}>
      {children}
    </demoModeContext.Provider>
  );
};

export const DemoModeContextProviderForTesting = ({
  value,
  children,
}: {
  value?: {
    isDemoMode?: boolean;
  };
  children?: React.ReactNode;
}) => {
  const actualValue = value ?? {};

  if (actualValue.isDemoMode === undefined) {
    actualValue.isDemoMode = false;
  }

  return <demoModeContext.Provider value={actualValue as FullDemoModeContextType}>{children}</demoModeContext.Provider>;
};

const useStyles = makeStyles((theme) => ({
  backdrop: () => ({
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  }),
}));

const demoToken = sessionStorage.getItem("demoToken");

export const useDemoModeContext = () => React.useContext(demoModeContext);

export const DemoModeUI = ({ children }: { children?: React.ReactNode }) => {
  const api = useApiContext();
  const history = useHistory();
  const location = useLocation();
  const { token, isDoitEmployee, currentUser } = useAuthContext();
  const { customer } = useCustomerContext({ allowNull: true });
  const { isDemoMode } = useDemoModeContext();
  const prevCurrentUser = usePrevious(currentUser);
  const { setIsDemoMode, setHandleToggleDemoMode } = React.useContext(demoModeContext);
  const prevTrialEndDate = usePrevious(customer?.trialEndDate);
  const [demoModeReady, setDemoModeReady] = React.useState(false);
  const [backdrop, setBackdrop] = React.useState(false);
  const classes = useStyles();
  const [showLoadingPage, setShowLoadingPage] = React.useState(false);
  const warningSnackbar = useWarningSnackbar();

  React.useEffect(() => {
    if (demoToken) {
      auth.signInWithCustomToken(demoToken).catch(consoleErrorWithSentry);
    }
  }, []);

  React.useEffect(() => {
    if (!currentUser && currentUser !== undefined && isDemoMode) {
      sessionStorage.removeItem("demoMode");
      sessionStorage.removeItem("demoToken");
    }
  }, [currentUser, isDemoMode]);

  React.useEffect(() => {
    if (!currentUser && prevCurrentUser) {
      sessionStorage.removeItem("demoMode");
      sessionStorage.removeItem("demoToken");

      if (isDemoMode) {
        rootAuth()
          .signOut()
          .then(() => {
            history.go(0);
          });
      }
    }
  }, [isDemoMode, currentUser, prevCurrentUser, history]);

  const handleToggleDemoMode = React.useCallback(async () => {
    if (!customer?.id || customer?.type === CustomerType.STANDALONE) {
      return;
    }

    if (!isDemoMode) {
      sessionStorage.setItem("spotScalingDemoModeSavingsEnabled", JSON.stringify(false));
      history.push(`/customers/${customer.id}`);
      setShowLoadingPage(true);
      try {
        const response = await api.request({
          method: "POST",
          url: `/v1/customers/${customer.id}/demo/start`,
          data: {
            userId: token?.claims.userId,
          },
        });
        if (response.status === 200) {
          const match = matchPath<{ currentFeature?: string }>(location.pathname, {
            path: "/customers/:customerId/:currentFeature",
            exact: false,
            strict: false,
          });
          mixpanel.track("demomode.switch", {
            "current-feature": match?.params?.currentFeature ?? "dashboards",
          });
          setDemoModeReady(true);
          setTimeout(async () => {
            const newDemoToken = response.data;
            await demoApp.auth().signInWithCustomToken(newDemoToken);
            sessionStorage.setItem("demoMode", "true");
            sessionStorage.setItem("demoToken", newDemoToken);
            setIsDemoMode(customer?.type !== CustomerType.STANDALONE);
            history.go(0);
          }, 1500);
        }
      } catch (err: any) {
        if (axios.isAxiosError(err) && err.response?.status === 403) {
          warningSnackbar(demoModeText.PARTNERS_NOT_ALLOWED);
        }

        setShowLoadingPage(false);
      }
    } else {
      if (customer.trialEndDate && !currentUser?.email?.endsWith("@doit-intl.com")) {
        return;
      }
      setBackdrop(true);
      sessionStorage.removeItem("demoMode");
      sessionStorage.removeItem("demoToken");
      setIsDemoMode(false);
      history.push(`/customers/${customer.id}`);
      history.go(0);
      await demoApp.auth().signOut();
    }
  }, [
    customer?.id,
    customer?.type,
    customer?.trialEndDate,
    isDemoMode,
    history,
    api,
    token?.claims.userId,
    location.pathname,
    setIsDemoMode,
    currentUser?.email,
    warningSnackbar,
  ]);

  React.useEffect(() => {
    if (!prevTrialEndDate && customer?.trialEndDate && !isDemoMode && !isDoitEmployee) {
      handleToggleDemoMode().catch(consoleErrorWithSentry);
    }
  }, [prevTrialEndDate, handleToggleDemoMode, isDemoMode, isDoitEmployee, customer?.trialEndDate]);

  React.useEffect(() => {
    setHandleToggleDemoMode(handleToggleDemoMode);
  }, [setHandleToggleDemoMode, handleToggleDemoMode]);

  const actualChildren = showLoadingPage ? <LoadingPage topSpacer={1} demoModeReady={demoModeReady} /> : children;

  return (
    <>
      {actualChildren}
      <Backdrop open={backdrop} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

type Props = DemoModeContextType;

export function withDemoMode<P extends object>(Component: React.ComponentType<P & Props>) {
  const WrappedComponent: React.FC<P> = (props) => (
    <DemoModeContextConsumer>{(context) => <Component {...context} {...props} />}</DemoModeContextConsumer>
  );

  WrappedComponent.displayName = `withDemoMode(${getDisplayName(WrappedComponent)})`;

  return WrappedComponent;
}
