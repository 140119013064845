import React, { useEffect } from "react";
import { getCollection, WithFirebase } from "@doitintl/models-firestore";
import { CustomerModel, EntityModel } from "@doitintl/cmp-models";
import firebase from "firebase/compat/app";
import { v4 as uuidv4 } from "uuid";
import { getDisplayName } from "recompose";
import { Customer } from "../../types";
import { arrayFromDocChange } from "./arrayFromDocChange";

export type Entity = WithFirebase<EntityModel> & {
  id: string;
  ref: firebase.firestore.DocumentReference<EntityModel>;
  snapshot: firebase.firestore.DocumentSnapshot<EntityModel>;
};

export type EntitiesContextType = {
  entities: Entity[];
  entitiesLoading: boolean;
  etag?: string | null;
};

const entitiesContext = React.createContext<EntitiesContextType>({ entities: [], entitiesLoading: true });

export const EntitiesContextProvider = ({
  children,
  customer,
}: {
  children: React.ReactNode;
  customer: Customer | undefined | null;
}) => {
  const [entities, setEntities] = React.useState<Entity[]>([]);
  const [entitiesLoading, setEntitiesLoading] = React.useState<boolean>(true);

  const [etag, setEtag] = React.useState<string>();

  useEffect(() => {
    setEntities(() => {
      setEntitiesLoading(true);
      return [];
    });

    if (!customer?.id) {
      return;
    }

    const customerRef = getCollection(CustomerModel).doc(customer.id);

    const entitiesUnsubscribe = getCollection(EntityModel)
      .where("customer", "==", customerRef)
      .onSnapshot((querySnapshot) => {
        setEntities((prevEntities) => {
          const updatedEntities = [...(prevEntities ?? [])];

          arrayFromDocChange(updatedEntities, querySnapshot, (doc) => ({
            ...doc.asFirestoreData(),
            id: doc.id,
            snapshot: doc.snapshot,
            ref: doc.ref,
          }));

          return updatedEntities;
        });

        setEtag(uuidv4());
        setEntitiesLoading(false);
      });

    return () => {
      entitiesUnsubscribe();
    };
  }, [customer?.id]);

  return <entitiesContext.Provider value={{ entities, entitiesLoading, etag }}>{children}</entitiesContext.Provider>;
};

export function useEntitiesContext(): EntitiesContextType {
  return React.useContext(entitiesContext);
}

export const EntitiesContextConsumer = entitiesContext.Consumer;

type Props = EntitiesContextType;

export type WithEntities = Props;

export function withEntities<P extends object>(Component: React.ComponentType<P & Props>) {
  const WrappedComponent: React.FC<P> = (props) => (
    <EntitiesContextConsumer>{(context) => <Component {...context} {...props} />}</EntitiesContextConsumer>
  );

  WrappedComponent.displayName = `withEntities(${getDisplayName(WrappedComponent)})`;

  return WrappedComponent;
}

export const EntitiesContextProviderForTesting = ({
  children,
  value: value,
}: {
  children: React.ReactNode;
  value?: Partial<EntitiesContextType>;
}) => {
  const actualValue = value ?? {};

  actualValue.etag = value?.entities?.length ? uuidv4() : null;
  actualValue.entitiesLoading = value?.entitiesLoading ?? false;

  return <entitiesContext.Provider value={actualValue as any}>{children}</entitiesContext.Provider>;
};
