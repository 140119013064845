import firebase from "firebase/compat/app";
import {
  DocumentSnapshotModel,
  ModelReference,
  QueryDocumentSnapshotModel,
  QueryModel,
} from "@doitintl/models-firestore";
import noop from "lodash/noop";
import { arrayFromDocChange } from "../Context/customer/arrayFromDocChange";

class StorageState<TReturn> {
  listeners: (() => void)[] = [];

  data: TReturn[] = [];

  private listening = false;

  private unsubscribe: () => void = noop;

  subscribe(listener: () => void, onListen: () => () => void) {
    this.listeners = [...this.listeners, listener];

    if (!this.listening) {
      this.listening = true;

      this.unsubscribe = onListen();
    }
    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener);

      if (this.listeners.length === 0) {
        this.listening = false;
        this.unsubscribe();
        this.unsubscribe = noop;
        this.data = [];
      }
    };
  }

  emitChange() {
    for (const listener of this.listeners) {
      listener();
    }
  }
}

export const createSnapshotStore = <T extends firebase.firestore.DocumentData, TReturn>(
  queryFactory: () => QueryModel<T>,
  addProps: (item: QueryDocumentSnapshotModel<T>) => TReturn
) => {
  const store = {
    state: new StorageState<TReturn>(),

    subscribe(listener: () => void) {
      return store.state.subscribe(listener, () =>
        queryFactory().onSnapshot((querySnapshot) => {
          const newData = [...store.state.data];
          arrayFromDocChange(newData, querySnapshot, addProps);

          store.state.data = newData;
          store.state.emitChange();
        })
      );
    },
    getSnapshot() {
      return store.state.data;
    },
  };
  return store;
};

export const createDocStore = <T extends firebase.firestore.DocumentData, TReturn>(
  docFactory: () => ModelReference<T>,
  transformDoc: (item: DocumentSnapshotModel<T>) => TReturn
) => {
  const store = {
    state: new StorageState(),
    data: undefined as TReturn | undefined,
    subscribe(listener: () => void) {
      return store.state.subscribe(listener, () =>
        docFactory().onSnapshot((querySnapshot) => {
          store.data = transformDoc(querySnapshot);
          store.state.emitChange();
        })
      );
    },
    getSnapshot() {
      return store.data;
    },
  };
  return store;
};
