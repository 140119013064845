import { getCollection, QueryDocumentSnapshotModel } from "@doitintl/models-firestore";
import { DoitRole, Permission, PermissionModel, RoleModel } from "@doitintl/cmp-models";
import { Customer } from "../types";

export default class Permissions {
  readonly doitEmployee: boolean;

  readonly doitOwner: boolean;

  readonly doitDeveloper: boolean;

  readonly doitPartner: boolean;

  readonly userId: string | undefined;

  /**
   * @deprecated use billingProfilesAdmin instead
   */
  readonly entities: boolean;

  readonly billingProfilesAdmin: boolean;

  /**
   * @deprecated use assetsManager instead
   */
  readonly licenses: boolean;

  readonly assetsManager: boolean;

  readonly users: boolean;

  /**
   * @deprecated use invoicesViewer instead
   */
  readonly accounting: boolean;

  readonly invoicesViewer: boolean;

  readonly sandboxAdmin: boolean;

  readonly sandboxUser: boolean;

  /**
   * @deprecated use flexsaveAdmin instead
   */
  readonly billingRIAdmin: boolean;

  readonly flexsaveAdmin: boolean;

  readonly cloudAnalytics: boolean;

  readonly manageSettings: boolean;

  readonly usersManager: boolean;

  readonly spotScaling: boolean;

  readonly contractsViewer: boolean;

  readonly anomaliesViewer: boolean;

  readonly perksViewer: boolean;

  readonly issuesViewer: boolean;

  readonly budgetsManager: boolean;

  readonly metricsManager: boolean;

  readonly attributionsManager: boolean;

  readonly supportRequester: boolean;

  readonly rampPlansViewer: boolean;

  readonly caOwnerRoleAssigner: boolean;

  readonly permissions: Set<string>;

  constructor(
    private readonly user:
      | {
          permissions?: string[];
          roles?: { name: string; permissions: { id: string }[] }[];
        }
      | null
      | undefined,
    readonly claims: Record<string, any>,
    doitRoles?: DoitRole[]
  ) {
    this.user = user;
    this.claims = claims;
    const userPermissions = this.user?.permissions ? new Set(this.user.permissions) : new Set<string>();
    const userRoles = this.user?.roles ?? [];
    userRoles.forEach((role) => {
      if (role.permissions) {
        role.permissions.forEach((permission) => {
          userPermissions.add(permission.id);
        });
      }
    });

    doitRoles?.forEach((role) => {
      userPermissions.add(role);
    });

    this.permissions = userPermissions;
    this.doitEmployee = this.claims?.doitEmployee ?? false;
    this.doitOwner = this.claims?.doitOwner ?? false;
    this.doitDeveloper = this.claims?.doitDeveloper ?? false;
    this.doitPartner = this.claims?.doitPartner ?? false;
    this.userId = this.claims?.userId;
    this.billingProfilesAdmin =
      this.claims.doitEmployee || this.permissions.has(Permissions.permissionIds.billingProfilesAdmin);
    this.entities = this.billingProfilesAdmin;
    this.assetsManager = this.claims.doitEmployee || this.permissions.has(Permissions.permissionIds.assetsManager);
    this.licenses = this.assetsManager;
    this.users = this.claims.doitEmployee || this.permissions.has(Permissions.permissionIds.users);
    this.invoicesViewer = this.claims.doitEmployee || this.permissions.has(Permissions.permissionIds.invoicesViewer);
    this.accounting = this.invoicesViewer;
    this.sandboxAdmin = this.permissions.has(Permissions.permissionIds.sandboxAdmin);
    this.sandboxUser = this.permissions.has(Permissions.permissionIds.sandboxUser);
    this.billingRIAdmin = this.permissions.has(Permissions.permissionIds.billingRIAdmin);
    this.flexsaveAdmin = this.permissions.has(Permissions.permissionIds.flexsaveAdmin);
    this.cloudAnalytics = this.permissions.has(Permissions.permissionIds.cloudAnalytics);
    this.manageSettings = this.permissions.has(Permissions.permissionIds.manageSettings);
    this.usersManager = this.permissions.has(Permissions.permissionIds.usersManager); // This is the same as users
    this.spotScaling = this.permissions.has(Permissions.permissionIds.spotScaling);
    this.contractsViewer = this.permissions.has(Permissions.permissionIds.contractsViewer);
    this.anomaliesViewer = this.permissions.has(Permissions.permissionIds.anomaliesViewer);
    this.perksViewer = this.permissions.has(Permissions.permissionIds.perksViewer);
    this.issuesViewer = this.claims.doitEmployee || this.permissions.has(Permissions.permissionIds.issuesViewer);
    this.budgetsManager = this.permissions.has(Permissions.permissionIds.budgetsManager);
    this.metricsManager = this.permissions.has(Permissions.permissionIds.metricsManager);
    this.attributionsManager = this.permissions.has(Permissions.permissionIds.attributionsManager);
    this.supportRequester = this.permissions.has(Permissions.permissionIds.supportRequester);
    this.rampPlansViewer = this.permissions.has(Permissions.permissionIds.rampPlansViewer);
    this.caOwnerRoleAssigner = this.permissions.has(Permissions.permissionIds.caOwnerRoleAssigner);
  }

  has(permissionId: string): boolean {
    return this.doitEmployee || this.permissions.has(permissionId);
  }

  check(permissionId: string | DoitRole): boolean {
    return this.permissions.has(permissionId);
  }

  roleName() {
    if (!this.doitEmployee && this.user?.roles?.length) {
      // CMP uses only one role per user currently
      return this.user.roles[0].name;
    }
  }

  static permissionIds = {
    billingProfilesAdmin: "1SmYWoSAO1frHKjt34Gz",
    licenses: "wfDH3k1FmYKHlQBwGIzZ",
    assetsManager: "wfDH3k1FmYKHlQBwGIzZ",
    users: "ZqLGIVDUhNiSEDtrEb0S",
    invoicesViewer: "HN6A3cPzDBcAIlc3ncDy",
    sandboxAdmin: "jnrMNJLdzRsyLCHCfq6T",
    sandboxUser: "KpogRUOHgMlroIH8xOUQ",
    billingRIAdmin: "tvQnB14mSGr8LSU8reYH",
    flexsaveAdmin: "tvQnB14mSGr8LSU8reYH",
    cloudAnalytics: "sfmBZeLN8uXWooCqJ4NO",
    manageSettings: "AIzQjXTUQDgeZjXqNsgF",
    usersManager: "ZqLGIVDUhNiSEDtrEb0S", // This is the same as users
    spotScaling: "akRhXeDLdP3mh87dXKSt",
    contractsViewer: "8zXuFyohNSiiLy2ZQ6Xu",
    anomaliesViewer: "eUKNGKekajR0NbOkHFfC",
    perksViewer: "itIlDPCy18ymtEVgaW0B",
    issuesViewer: "dEJbIiUcHn8GhW7IiWLW",
    budgetsManager: "BgYDGr8dABLKjUkys7AD",
    metricsManager: "fSFpOG5xUeHPlYVI5N1k",
    attributionsManager: "AnJW2Hwipmucak00yko0",
    supportRequester: "jg1YHuQhsRlg5msNhpZZ",
    rampPlansViewer: "u10SsW3V123pb5lVdgcO",
    caOwnerRoleAssigner: "pwWRo04l9uXUYa8rIQSW",
  };

  static widgetPermissionsRequired = {
    invoices: Permissions.permissionIds.invoicesViewer,
    credits: Permissions.permissionIds.invoicesViewer,
  };
}

export const filterUsersWithPermission = async <T extends { roles?: { id: string }[]; permissions?: string[] }>(
  customer: Customer,
  usersDocSnaps: QueryDocumentSnapshotModel<T>[],
  permissionId: Permission | string
) => {
  // Fetch custom and preset roles the contains the required permission
  const permissionRef = getCollection(PermissionModel).doc(permissionId);
  const presetRolesQuerySnap = await getCollection(RoleModel)
    .where("type", "==", "preset")
    .where("customer", "==", null)
    .where("permissions", "array-contains", permissionRef)
    .get();
  const customRolesQuerySnap = await getCollection(RoleModel)
    .where("type", "==", "custom")
    .where("customer", "==", customer.ref)
    .where("permissions", "array-contains", permissionRef)
    .get();

  const acceptableRoles = presetRolesQuerySnap.docs.concat(customRolesQuerySnap.docs).map((docSnap) => docSnap.ref.id);

  // Filter users that have a role that includes the required permission
  // if they don't have a role, check if they have the legacy permission
  return usersDocSnaps.filter((docSnap) => {
    const user = docSnap.data();
    const userRoles = user?.roles ?? [];
    if (userRoles.length > 0) {
      return userRoles.some((role) => acceptableRoles.includes(role.id));
    }
    return user.permissions?.includes(permissionId as Permission) ?? false;
  });
};

export function getRoles(roleId?: string) {
  return getCollection(RoleModel).doc(roleId);
}

export const PresetRoles = {
  get ADMIN_REF() {
    return getRoles("59w2TJPTCa3XPsJ3KITY");
  },
  get POWER_USER_REF() {
    return getRoles("Y2IN1X2rmWoZhTJDsYAN");
  },
  get FINANCE_USER_REF() {
    return getRoles("vkrmC1ioimecNyJ6vjPZ");
  },
  get STANDARD_USER_REF() {
    return getRoles("e66fNltAJjdtpHwaXb6I");
  },
  get IT_MANAGER_REF() {
    return getRoles("K634tvqvUvQGLPnqtubh");
  },
  get SUPPORT_USER_REF() {
    return getRoles("YcZKjDZJHHUAl9yPCBmy");
  },
  get PARTNER_ACCOUNT_MANAGER_REF() {
    return getRoles("K8fE5kgTdEhQotv39Bcc");
  },
  get SAAS_ADMIN_REF() {
    return getRoles("4x4AeO6fprj8iYjnFoDq");
  },
};

export const isAdmin = (roles: { id: string }[] | undefined) =>
  !!roles?.map((r) => r.id).includes(PresetRoles.ADMIN_REF.id);

/** @description: the default role ref for new users */
export const getDefaultRoleRefByCustomer = (customer: Pick<Customer, "type" | "defaultRole">) => {
  if (customer.defaultRole) {
    return getRoles(customer.defaultRole.id);
  }
  switch (customer.type) {
    case "standalone":
      return PresetRoles.SAAS_ADMIN_REF;
    default:
      return PresetRoles.SUPPORT_USER_REF;
  }
};
