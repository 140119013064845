import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { setFirestoreSingleton } from "@doitintl/models-firestore";
import { hasFeature } from "./features";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const customAuthConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_CUSTOM_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

firebase.firestore.setLogLevel("error");
firebase.initializeApp(config);

const demoConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY_DEMO,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_DEMO,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL_DEMO,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_DEMO,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_DEMO,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_DEMO,
  appId: process.env.REACT_APP_FIREBASE_APP_ID_DEMO,
};

function isLongPollingCandidate(idToken: firebase.auth.IdTokenResult) {
  if (hasFeature("enableLongPolling")) {
    return true;
  }

  const ezugiCustomerId = "c9sxgx5dF6U0o0Ps1P7f";
  const lamarkCustomerId = "kaAd72Hz3TScxZPITMDQ";
  const isExperimentalLongPollCustomer = [ezugiCustomerId, lamarkCustomerId].includes(idToken.claims.customerId);

  return isExperimentalLongPollCustomer && !hasFeature("disableLongPolling");
}

export let firestore: firebase.firestore.Firestore;
export const demoApp = firebase.initializeApp(demoConfig, "demo");
export let customAuthApp: firebase.app.App | undefined;
if (customAuthConfig.authDomain) {
  customAuthApp = firebase.initializeApp(customAuthConfig, "customAuth");
}

export function slimInitFirestore(isDemoApp?: boolean, idToken?: firebase.auth.IdTokenResult) {
  if (firestore) {
    return firestore; // single time init
  }

  if (isDemoApp) {
    firestore = demoApp.firestore();
  } else if (customAuthApp && !(firebase?.auth && firebase?.auth().currentUser)) {
    firestore = customAuthApp.firestore();
  } else {
    firestore = firebase.firestore();
  }

  // for integration mode (cypress tests) use firestore emulator
  if (process.env.CYPRESS_INTEGRATION_MODE === "true") {
    firestore.useEmulator("localhost", parseInt(process.env.FIRESTORE_EMULATOR_PORT || "7070"));
    firestore.settings({ experimentalForceLongPolling: true, experimentalAutoDetectLongPolling: false, merge: true });
  } else if (idToken) {
    firestore.settings({ experimentalAutoDetectLongPolling: isLongPollingCandidate(idToken) });
  }

  let params = {};
  if (process.env.NODE_ENV === "development") {
    const listenErrorCallback = (path, err, marker) => {
      // eslint-disable-next-line no-console
      console.warn("Firestore error at", path, err, marker.stack);
    };

    params = {
      listenErrorCallback,
    };
  }

  setFirestoreSingleton(firestore, params);

  return firestore;
}
