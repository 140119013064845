import { JSX } from "react";
import difference from "lodash/difference";
import sortBy from "lodash/sortBy";
import concat from "lodash/concat";
import startCase from "lodash/startCase";
import orderBy from "lodash/orderBy";

import { CreateFilterOptionsConfig } from "@mui/material/useAutocomplete";
import { MenuProps as MenuPropsType } from "@mui/material/Menu";

import { DateTime } from "luxon";
import {
  Aggregator,
  AlertCondition,
  AttributionFilter,
  CalcMetricFormat,
  CloudAnalyticsModelAlertModel,
  Collaborator,
  ComparativeFeature,
  CurrenciesMap,
  CurrencyCodes,
  CustomerModel,
  CustomerModelOrganizationModel,
  Feature,
  Metadata,
  Metric,
  MetricFilter,
  MetricFilterOperator,
  OrganizationsModel,
  ProductEnum,
  PublicAccess,
  Renderer,
  ReportConfig,
  Roles,
  Sort,
  TimeInterval,
  TimeSettingsMode,
} from "@doitintl/cmp-models";
import {
  asModelRefFromFirestoreRef,
  DocumentSnapshotModel,
  getCollection,
  ModelReference,
  ModelTimestamp,
  WithFirebaseModel,
} from "@doitintl/models-firestore";
import { CalendarPickerView } from "@mui/x-date-pickers";
import cloneDeep from "lodash/cloneDeep";
import { firestoreTimestamp, TimestampNow } from "../../utils/firebase";
import { PresetOrganizations } from "../../utils/common";
import {
  attributionGroupsText,
  attributionText,
  cloudAnalyticsText,
  metricFiltersText,
  reportText,
  statusesTexts,
} from "../../assets/texts";

import {
  AttributionMetadataOption,
  AttributionWRef,
  Budget,
  CustomTimeRange,
  MetadataOption,
  MetricWSnap,
} from "../../types";
import mixpanel from "../../utils/mixpanel";
import { convertHighchartToTreemap, highchartColors } from "../../cmpBaseColors";
import { ThemeMode } from "../../muiTheme";
import { OrganizationWSnap } from "../IAM/Organizations/Types";
import { doitDomain, doitPreviousDomain } from "../../utils/domains";
import { reportExportTxt } from "../../assets/texts/CloudAnalytics";
import { DATE_RANGE_FORMAT, IDateRange } from "./../../Components/DateRange/dateRangeUtils";
import { Split } from "./../../Components/SplitCostModal/types";
import { createTargetsFromAttributions } from "./../../Components/SplitCostModal/utils";
import { isGKECostAllocationOptionKey } from "./GKECostAllocationFlowHandler";
import { AnalyticsAlertWithRef } from "./alerts";
import { ShareableEntity } from "./dialogs/shareDialog/ShareDialog";

export enum ErrorCode {
  UNKNOWN = "unknown_error",
  EMPTY = "result_empty",
  TOO_LARGE = "result_too_large",
  EMPTY_DUE_TO_TREND = "result_empty_trend",
  QUERY_TIMEOUT = "query_timeout",
  SERIES_TOO_LARGE = "series_count_too_large",
}

export enum Positions {
  UNUSED = "unused",
  ROW = "row",
  COL = "col",
  COUNT = "count",
}

export enum ReportMode {
  GKE = "gke",
  BILLING = "billing",
  ALL = "all",
}

export enum AttributionMode {
  GKE = "gke",
  BILLING = "billing",
  ALL = "all",
}

export const CalcMetricFormatOptions = [
  { value: CalcMetricFormat.NUMERIC, label: "Number" },
  { value: CalcMetricFormat.PRECENTAGE, label: "%" },
];

export const defaultTimezone = "America/Los_Angeles";

export const ComparativeSign = "∆";

export enum CloudAnalyticsEntities {
  REPORT = "report",
  BUDGET = "budget",
  ALERT = "alert",
  ATTRIBUTION = "attribution",
  ATTRIBUTION_GROUP = "attribution_group",
  METRIC = "metric",
  REPORTS = "reports",
  BUDGETS = "budgets",
  ATTRIBUTIONS = "attributions",
  METRICS = "metrics",
}

export enum FixedFilters {
  CLOUD = "cloud_provider",
  ACCOUNT = "billing_account_id",
  PROJECT = "project_id",
  SERVICE = "service_description",
  SKU = "sku_description",
  OPERATION = "operation",
  RESOURCE = "resource_id",
  COUNTRY = "country",
  REGION = "region",
  ZONE = "zone",
  COST_TYPE = "cost_type",
  UNIT = "pricing_unit",
  CREDIT = "credit",
  CUSTOMER = "csp_primary_domain",
}

export enum CloudProviders {
  GOOGLE_CLOUD = "google-cloud",
  AMAZON_WEB_SERVICES = "amazon-web-services",
}

export enum Origin {
  LIST = "list",
  REPORT = "report",
}

export const MetricOptions = [
  {
    value: Metric.COST,
    label: "Cost",
  },
  {
    value: Metric.USAGE,
    label: "Usage",
  },
  {
    value: Metric.SAVINGS,
    label: "Savings",
  },
];

export const getMetricsCount = (isCSP: boolean, metric: Metric, isCount: boolean) =>
  MetricOptions.length +
  Number(isCSP) +
  Number(metric === Metric.CALCULATED || metric === Metric.EXTENDED) +
  Number(isCount);

export const getMetricOptions = (
  metricOffset: number,
  metric: Metric,
  isCSP: boolean,
  isCount: boolean
): null | number[] => {
  if (isCount) {
    return [metricOffset + getMetricsCount(isCSP, metric, true) - 1];
  }

  switch (metric) {
    case Metric.COST:
      return [metricOffset, metricOffset + 1];
    case Metric.USAGE:
      return [metricOffset + 1, metricOffset + 1];
    case Metric.SAVINGS:
      return [metricOffset + 2, metricOffset];
    case Metric.MARGIN:
      return [metricOffset + 3, metricOffset];
    case Metric.CALCULATED:
      return [metricOffset + 3 + Number(isCSP)];
    case Metric.EXTENDED:
      return [metricOffset + 3 + Number(isCSP)];
    default:
      return null;
  }
};

export const CSPMetric = {
  value: Metric.MARGIN,
  label: "Margin",
};

export const getMetricLabel = (metric: Metric, extendedMetricLabel: string, calculatedMetricLabel: string): string => {
  switch (metric) {
    case Metric.MARGIN:
      return CSPMetric.label;
    case Metric.CALCULATED:
      return calculatedMetricLabel;
    case Metric.EXTENDED:
      return extendedMetricLabel;
    default:
      return MetricOptions[metric].label;
  }
};

export const AlertsOpertatorOptions = [
  {
    value: MetricFilterOperator.GREATER_THAN,
    label: "greater than",
  },
  {
    value: MetricFilterOperator.LESS_THAN,
    label: "less than",
  },
];

export const MetricFiltersOptions = [
  {
    value: MetricFilterOperator.GREATER_THAN,
    label: "Greater than (>)",
  },
  {
    value: MetricFilterOperator.LESS_THAN,
    label: "Less than (<)",
  },
  {
    value: MetricFilterOperator.GREATER_THAN_OR_EQUAL,
    label: "Greater than or equals (>=)",
  },
  {
    value: MetricFilterOperator.LESS_THAN_OR_EQUAL,
    label: "Less than or equals (<=)",
  },
  {
    value: MetricFilterOperator.BETWEEN,
    label: "Between",
  },
  {
    value: MetricFilterOperator.NOT_BETWEEN,
    label: "Not between",
  },
  {
    value: MetricFilterOperator.EQUALS,
    label: "Equals (=)",
  },
  {
    value: MetricFilterOperator.NOT_EQUALS,
    label: "Not equals (≠)",
  },
];

export const Period = {
  DAILY: 0,
  MONTHLY: 1,
  QUARTERLY: 2,
  ANNUALLY: 3,
};

export const PeriodOptions = [
  {
    value: Period.DAILY,
    label: "Daily",
  },
  {
    value: Period.MONTHLY,
    label: "Monthly",
  },
  {
    value: Period.QUARTERLY,
    label: "Quarterly",
  },
  {
    value: Period.ANNUALLY,
    label: "Annually",
  },
];

export const TimeRangePeriodOptions = [
  {
    value: Period.MONTHLY,
    label: "Month",
  },
  {
    value: Period.QUARTERLY,
    label: "Quarter",
  },
  {
    value: Period.ANNUALLY,
    label: "Year",
  },
];

export enum BudgetTypes {
  FIXED,
  RECURRING,
}

export const BudgetTypesOptions = [
  {
    value: BudgetTypes.FIXED,
    label: "Fixed",
  },
  {
    value: BudgetTypes.RECURRING,
    label: "Recurring",
  },
];

export const BudgetAlertTriggerTypes = {
  ACTUAL: 0,
  FORECASTED: 1,
};

export const BudgetAlertTriggerOptions = [
  {
    value: BudgetAlertTriggerTypes.ACTUAL,
    label: "Actual",
  },
  {
    value: BudgetAlertTriggerTypes.FORECASTED,
    label: "Forecasted",
  },
];

export const CurrencyOptions = [
  CurrencyCodes.USD,
  CurrencyCodes.EUR,
  CurrencyCodes.GBP,
  CurrencyCodes.ILS,
  CurrencyCodes.AUD,
  CurrencyCodes.CAD,
  CurrencyCodes.DKK,
  CurrencyCodes.NOK,
  CurrencyCodes.SEK,
  CurrencyCodes.BRL,
  CurrencyCodes.SGD,
  CurrencyCodes.MXN,
  CurrencyCodes.CHF,
  CurrencyCodes.MYR,
  CurrencyCodes.TWD,
];

export type AggregatorOptionType = {
  value: Aggregator;
  label: string | ((metric: any) => "Unit Cost" | "Discount Pct." | "Margin Pct." | "N/A");
};

export const AggregatorOptions: AggregatorOptionType[] = [
  {
    value: Aggregator.TOTAL,
    label: "Total",
  },
  {
    value: Aggregator.PERCENT_TOTAL,
    label: "% of Total",
  },
  {
    value: Aggregator.PERCENT_ROW,
    label: "% of Row",
  },
  {
    value: Aggregator.PERCENT_COL,
    label: "% of Column",
  },
  {
    value: Aggregator.TOTAL_OVER_TOTAL,
    label: (metric) => {
      switch (metric) {
        case Metric.COST:
          return "Unit Cost";
        case Metric.SAVINGS:
          return "Discount Pct.";
        case Metric.MARGIN:
          return "Margin Pct.";
        default:
          return "N/A";
      }
    },
  },
  {
    value: Aggregator.COUNT,
    label: "Count",
  },
];

export const getAvailableAggregators = (
  aggregatorOptions: AggregatorOptionType[],
  aggregator: Aggregator,
  isCountAggregatorVisible: boolean
) =>
  aggregatorOptions.filter(
    (aggregatorOption) =>
      !(
        aggregatorOption.value !== aggregator &&
        aggregatorOption.value === Aggregator.COUNT &&
        !isCountAggregatorVisible
      )
  );

export const IsValidAggregator = (aggregator, metric) =>
  !(aggregator === Aggregator.TOTAL_OVER_TOTAL && (metric === Metric.USAGE || metric === Metric.CALCULATED));

const sanitizeDate = (dt) => dt.toUTC().startOf("day");

type TimeIntervalOption = {
  value: TimeInterval;
  label: string;
  format: string;
  available: string[];
  visible: string[];
  alternateLabel: string;
  views: CalendarPickerView[];
  mask?: string;
};

export const AlertConditionOptions: { value: AlertCondition; label: string }[] = [
  { value: AlertCondition.VALUE, label: "is" },
  { value: AlertCondition.FORECAST, label: "is forecasted to be" },
  { value: AlertCondition.PERCENTAGE, label: "percentage change is" },
];

export const timeIntervalOptions: TimeIntervalOption[] = [
  {
    value: TimeInterval.HOUR,
    label: "Hour",
    alternateLabel: "Hourly",
    views: ["day"],
    format: "dd LLLL, yyyy",
    available: ["year", "month", "day", "week_day", "hour"],
    visible: ["year", "month", "day", "hour"],
    mask: "__ _________, ____",
  },
  {
    value: TimeInterval.DAY,
    label: "Day",
    alternateLabel: "Daily",
    views: ["day"],
    format: "dd LLLL, yyyy",
    available: ["year", "month", "day", "week_day"],
    visible: ["year", "month", "day"],
    mask: "__ _________, ____",
  },
  {
    value: TimeInterval.WEEK,
    label: "Week",
    alternateLabel: "Weekly",
    views: ["day"],
    format: "kkkk 'Week' W",
    available: ["year", "week", "week_day"],
    visible: ["year", "week"],
    mask: "__ _________, ____",
  },
  {
    value: TimeInterval.MONTH,
    label: "Month",
    alternateLabel: "Monthly",
    views: ["month", "year"],
    format: "LLLL, yyyy",
    available: ["year", "month"],
    visible: ["year", "month"],
    mask: "__ _________, ____",
  },
  {
    value: TimeInterval.QUARTER,
    label: "Quarter",
    alternateLabel: "Quarterly",
    views: ["month", "year"],
    format: "LLLL, yyyy",
    available: ["year", "quarter"],
    visible: ["year", "quarter"],
    mask: "__ _________, ____",
  },
  {
    value: TimeInterval.YEAR,
    label: "Year",
    alternateLabel: "Yearly",
    views: ["year"],
    format: "yyyy",
    available: ["year"],
    visible: ["year"],
    mask: "__ _________, ____",
  },
];

export const selectedTimeInterval = (timeInterval) =>
  timeIntervalOptions.find((option) => option.value === timeInterval);

export const INVALID_DATE_ERROR_MSG = "Invalid Date";
export enum TimeRangeTabsLabel {
  Last = "Last",
  Current = "Current",
  Fixed = "Fixed dates",
}

export const convertTimeSettingsModeToIndex = (mode: TimeSettingsMode) => {
  switch (mode) {
    case TimeSettingsMode.Last:
      return 0;
    case TimeSettingsMode.Current:
      return 1;
    case TimeSettingsMode.Fixed:
      return 2;
  }
};
export interface TimeRangeOption {
  label?: string;
  mode: TimeSettingsMode;
  time: TimeInterval;
  amount?: number;
  range?: IDateRange;
  includeCurrent?: boolean;
}

export const CUSTOM_RANGE_LABEL = "Custom range";
export const TimeRangeOptions: TimeRangeOption[] = [
  {
    label: "Last 7 days",
    mode: TimeSettingsMode.Last,
    time: TimeInterval.DAY,
    amount: 7,
    includeCurrent: true,
  },
  {
    label: "Last 14 days",
    mode: TimeSettingsMode.Last,
    time: TimeInterval.DAY,
    amount: 14,
    includeCurrent: true,
  },
  {
    label: "Last 28 days",
    mode: TimeSettingsMode.Last,
    time: TimeInterval.DAY,
    amount: 28,
    includeCurrent: true,
  },
  {
    label: "Last month",
    mode: TimeSettingsMode.Last,
    time: TimeInterval.MONTH,
    amount: 1,
    includeCurrent: false,
  },
  {
    label: "Last month to date",
    mode: TimeSettingsMode.Last,
    time: TimeInterval.MONTH,
    amount: 2,
    includeCurrent: true,
  },
  {
    label: "Last 3 months",
    mode: TimeSettingsMode.Last,
    time: TimeInterval.MONTH,
    amount: 3,
    includeCurrent: true,
  },
  {
    label: "Last 6 months",
    mode: TimeSettingsMode.Last,
    time: TimeInterval.MONTH,
    amount: 6,
    includeCurrent: true,
  },
  {
    label: "Last 12 months",
    mode: TimeSettingsMode.Last,
    time: TimeInterval.MONTH,
    amount: 12,
    includeCurrent: true,
  },
  {
    label: "Current month",
    mode: TimeSettingsMode.Current,
    time: TimeInterval.MONTH,
    amount: 0,
    includeCurrent: false,
  },
  {
    label: "Current quarter",
    mode: TimeSettingsMode.Current,
    time: TimeInterval.QUARTER,
    amount: 0,
    includeCurrent: false,
  },
  {
    label: "Current year",
    mode: TimeSettingsMode.Current,
    time: TimeInterval.YEAR,
    amount: 0,
    includeCurrent: false,
  },
];

export const CurrentTimeRangeCheckBoxOptions = [
  {
    value: TimeInterval.MONTH,
    label: "Month",
  },
  {
    value: TimeInterval.QUARTER,
    label: "Quarter",
  },
  {
    value: TimeInterval.YEAR,
    label: "Year",
  },
];

export const RendererOptions = [
  {
    value: Renderer.TABLE,
    label: "Table",
  },
  {
    value: Renderer.HEATMAP,
    label: "Heatmap",
  },
  {
    value: Renderer.ROW_HEATMAP,
    label: "Row Heatmap",
  },
  {
    value: Renderer.COL_HEATMAP,
    label: "Col Heatmap",
  },
  {
    value: Renderer.COLUMN_CHART,
    label: "Column Chart",
  },
  {
    value: Renderer.STACKED_COLUMN_CHART,
    label: "Stacked Column",
  },
  {
    value: Renderer.STACKED_COLUMN_CHART_2,
    label: "Stacked Column 2.0",
  },
  {
    value: Renderer.BAR_CHART,
    label: "Bar Chart",
  },
  {
    value: Renderer.STACKED_BAR_CHART,
    label: "Stacked Bar",
  },
  {
    value: Renderer.TREEMAP,
    label: "Treemap",
  },
  {
    value: Renderer.LINE_CHART,
    label: "Line Chart",
  },
  {
    value: Renderer.SPLINE_CHART,
    label: "Spline Chart",
  },
  {
    value: Renderer.AREA_CHART,
    label: "Area Chart",
  },
  {
    value: Renderer.AREA_SPLINE_CHART,
    label: "Area Spline",
  },
  {
    value: Renderer.STACKED_AREA_CHART,
    label: "Stacked Area",
  },
];

export const ExportOptions = {
  sheets_export: {
    value: Renderer.SHEETS_EXPORT,
    label: reportText.EXPORT_TO_GOOGLE_SHEETS,
  },
  csv_export: {
    value: Renderer.CSV_EXPORT,
    label: reportText.EXPORT_TO_CSV,
  },
  csv_clipboard: {
    value: Renderer.CSV_CLIPBOARD,
    label: reportText.COPY_CSV_TO_CLIPBOARD,
  },
};

export const HighchartsDownloadOptions = {
  pdf_download: {
    value: Renderer.PDF_DOWNLOAD,
    label: "Download PDF",
  },
  png_download: {
    value: Renderer.PNG_DOWNLOAD,
    label: "Download PNG",
  },
};

export const Labels = {
  ML_FORECAST: "Forecast",
  FORECAST: "Forecast",
};

export const FeatureOptions = [
  {
    value: Feature.TREND_UP,
    label: "Trending Up",
  },
  {
    value: Feature.TREND_DOWN,
    label: "Trending Down",
  },
  {
    value: Feature.TREND_NONE,
    label: "Not Trending",
  },
  {
    value: Feature.FORECAST,
    label: "Forecast",
  },
];

export const comparativeValues = {
  [ComparativeFeature.NONE]: {
    value: ComparativeFeature.NONE,
    label: "Actuals Only",
    isComparative: false,
  },
  [ComparativeFeature.VALUES]: {
    value: ComparativeFeature.VALUES,
    label: "Absolute Change",
    key: "val",
    headerLabel: (label) => `${label} ${ComparativeSign}`,
    isComparative: true,
  },
  [ComparativeFeature.PERCENT]: {
    value: ComparativeFeature.PERCENT,
    label: "Percentage Change",
    key: "pct",
    headerLabel: (label) => `${label} ${ComparativeSign}%`,
    isComparative: true,
  },
  [ComparativeFeature.BOTH]: {
    value: ComparativeFeature.BOTH,
    label: "Absolute and Percentage",
    headerLabel: () => "",
    isComparative: true,
    renderer: [Renderer.TABLE],
  },
};

export const getComparativeHeader = (comparativeType: string, label: string): string => {
  if (comparativeType in comparativeValues) {
    return comparativeValues[comparativeType].headerLabel(label);
  }
  return "";
};

export const isComparative = (comparativeType: ComparativeFeature): boolean => {
  if (comparativeType in comparativeValues) {
    return comparativeValues[comparativeType].isComparative;
  }
  return false;
};

export const KeepReportDraftCases = {
  PDF_DOWNLOAD: "PDF Download",
  PNG_DOWNLOAD: "PNG Download",
};

export const getColors = (mode: ThemeMode) => highchartColors[mode];

export const getTreeMapColors = (mode: ThemeMode) => convertHighchartToTreemap(mode);

export const sharedFixedFields = [FixedFilters.PROJECT, FixedFilters.SKU, FixedFilters.UNIT, FixedFilters.CLOUD];

// TODO(dror): this should be set as a field on the metadata doc
export const getReportMode = (mdData) => {
  if (mdData.type === ReportMode.GKE || mdData.type === Metadata.GKE_LABEL) {
    return ReportMode.GKE;
  }
  if (
    (mdData.type === Metadata.LABEL || mdData.type === Metadata.TAG || mdData.type === Metadata.FIXED) &&
    !sharedFixedFields.includes(mdData.key)
  ) {
    return ReportMode.BILLING;
  }
  return ReportMode.ALL;
};

export const getAttributeType = (attr) => {
  if (!attr) {
    return ReportMode.ALL;
  }
  return attr.data?.mode;
};

export const isGKEMeteringAttribution = (attribution): boolean => {
  if (attribution.data?.mode !== undefined) {
    return attribution.data.mode !== AttributionMode.GKE;
  }
  if (attribution.data?.filters !== undefined) {
    return attribution.data.filters.filter((f) => f.type === Metadata.GKE || f.type === Metadata.GKE_LABEL).length > 0;
  }
  return false;
};

export const getAttributionById = (attrId, attributions) => attributions.find((a) => a.ref.id === attrId);

export const buildNAField = (type) => {
  const capitalizedType = type.charAt(0).toUpperCase() + type.slice(1);
  return `[${capitalizedType} N/A]`;
};

export const sanitizeCustomTimeRange = (customTimeRange): CustomTimeRange | null => {
  if (customTimeRange) {
    return {
      from: DateTime.fromJSDate(customTimeRange.from.toDate()),
      to: DateTime.fromJSDate(customTimeRange.to.toDate()),
    };
  }
  return null;
};
export const MenuProps: Partial<MenuPropsType> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
};

export const getExistingUsers: (
  allUsersAndInvites: {
    allInvites: string[];
    allUsers: string[];
  },
  newUsers: string[]
) => string[] = (allUsersAndInvites, newUsers) =>
  newUsers.filter((newUser) =>
    concat(allUsersAndInvites.allInvites, allUsersAndInvites.allUsers).find((existing) => existing === newUser)
  );

export const getNewUsers: ({
  collaborators,
  users,
  invites,
  baseEntity,
  allUsersAndInvites,
}: {
  collaborators: Pick<Collaborator, "email">[];
  users: string[];
  invites: string[];
  baseEntity: any;
  allUsersAndInvites: any;
}) => Promise<string[]> = ({ collaborators, users, invites, baseEntity, allUsersAndInvites }) => {
  const allExistingEmails = concat(
    users,
    invites,
    baseEntity.data.collaborators.map((c) => c.email)
  );
  const emails: string[] = collaborators
    .filter((c) => !allExistingEmails.includes(c.email) && !c.email.endsWith("@doit-intl.com"))
    .map((c) => c.email);
  if (emails.length) {
    const existingUsers = getExistingUsers(allUsersAndInvites, emails);
    if (existingUsers.length) {
      const emailsString = existingUsers.join(", ");
      const usersNoun = existingUsers.length > 1 ? "Users" : "User";
      const message = `${usersNoun} ${emailsString} has no Cloud Analytics permission`;
      return Promise.reject(message);
    } else {
      return Promise.resolve(emails);
    }
  }
  return Promise.resolve([]);
};
export type TimeSettings = {
  from: DateTime;
  to: DateTime;
  interval?: string;
};

export const getTimeSettings = (timeRangeOption: TimeRangeOption): TimeSettings => {
  const { mode, time: timeInterval, amount, includeCurrent, range } = timeRangeOption;
  const today = DateTime.utc();
  const responseRange: TimeSettings = { from: today, to: today, interval: timeInterval };
  switch (mode) {
    case TimeSettingsMode.Last:
      {
        const startAmount = includeCurrent && amount ? amount - 1 : amount;
        responseRange.from = today.minus({ [timeInterval]: startAmount }).startOf(timeInterval);
        responseRange.to = includeCurrent ? today : today.minus({ [timeInterval]: 1 }).endOf(timeInterval);
      }
      break;
    case TimeSettingsMode.Current:
      responseRange.from = today.startOf(timeInterval);
      responseRange.to = today.endOf("month");
      break;
    case TimeSettingsMode.Fixed:
      if (range?.start?.isValid && range?.end?.isValid) {
        responseRange.from = range.start;
        responseRange.to = range.end;
      } else {
        // invalid custom time range
        throw new Error("invalid custom time range");
      }
      break;
  }
  responseRange.from = sanitizeDate(responseRange.from);
  responseRange.to = sanitizeDate(responseRange.to);
  return responseRange;
};

export const lastPeriodOptions = [
  {
    value: TimeInterval.DAY,
    label: "Days",
  },
  {
    value: TimeInterval.WEEK,
    label: "Weeks",
  },
  {
    value: TimeInterval.MONTH,
    label: "Months",
  },
  {
    value: TimeInterval.QUARTER,
    label: "Quarter",
  },
  {
    value: TimeInterval.YEAR,
    label: "Years",
  },
];

export const timeRangeInputValue = (timeRangeOption: TimeRangeOption) => {
  const includeCurrentStr = timeRangeOption.includeCurrent ? " w. current" : "";
  switch (timeRangeOption.mode) {
    case TimeSettingsMode.Last: {
      if (timeRangeOption.amount) {
        const { amount, time } = timeRangeOption;
        const amountVal = amount > 1 ? `${amount} ${time}s` : time;
        return `${TimeRangeTabsLabel.Last} ${amountVal}${includeCurrentStr}`;
      } else {
        return `${TimeRangeTabsLabel.Last} ${timeRangeOption.time}${includeCurrentStr}`;
      }
    }
    case TimeSettingsMode.Current:
      return `${TimeRangeTabsLabel.Current} ${timeRangeOption.time}`;
    case TimeSettingsMode.Fixed:
      return `${timeRangeOption.range?.start?.toFormat(DATE_RANGE_FORMAT)} - ${timeRangeOption.range?.end?.toFormat(
        DATE_RANGE_FORMAT
      )}`;
  }
};

export const getFilterLabel = (type) => {
  switch (type) {
    case Positions.ROW:
      return "Add grouping";
    case Positions.COL:
      return "Add dimension";
    case Positions.UNUSED:
      return "Add filter";
    case Positions.COUNT:
      return "Add count";
  }
};

export const getNewPosition = (prevPosition: Positions, fromList: boolean): string => {
  if (fromList) {
    return prevPosition;
  }
  switch (prevPosition) {
    case Positions.UNUSED:
      return Positions.ROW;
    case Positions.ROW:
      return Positions.COL;
    case Positions.COL:
      return Positions.ROW;
    default:
      return Positions.ROW;
  }
};

export const getTypeString = (option: MetadataOption): string => {
  switch (option.data.type) {
    case Metadata.DATETIME:
      return "Date/Time";
    case Metadata.ATTRIBUTION:
      return "Standard";
    case Metadata.ATTRIBUTION_GROUP:
      return "Attribution Groups";
    case Metadata.GKE:
      return "GKE Usage Metering (deprecated)";
    case Metadata.FIXED:
      if (isGKECostAllocationOptionKey(option.data.key)) {
        return "Google Kubernetes Engine";
      }
      return "Standard";
    case Metadata.GKE_LABEL:
      return "GKE Usage Metering Labels (deprecated)";
    case Metadata.LABEL:
      if (option.data.subType === Metadata.GKE_LABEL) {
        return "GKE Labels";
      }
      return "Labels";
    case Metadata.TAG:
      return "Tags";
    case Metadata.SYSTEM_LABEL:
      return "System Labels";
    case Metadata.PROJECT_LABEL:
      return "Project Labels";
    case Metadata.METRIC:
      return "Metric";
    case Metadata.LIMITS:
      return "Metric";
  }
  return "";
};

const COUNT_AGGREGATOR_OPTIONS_ALLOW_LIST = [
  Metadata.FIXED,
  Metadata.SYSTEM_LABEL,
  Metadata.PROJECT_LABEL,
  Metadata.LABEL,
  Metadata.TAG,
];

export const optionalMetadataTypes = [
  Metadata.SYSTEM_LABEL,
  Metadata.PROJECT_LABEL,
  Metadata.LABEL,
  Metadata.TAG,
  Metadata.GKE_LABEL,
  Metadata.ATTRIBUTION_GROUP,
];

export const isCountItemAllowed = (type) => COUNT_AGGREGATOR_OPTIONS_ALLOW_LIST.includes(type);

export const isTypeAllowedToDrop = (type, item) => {
  switch (type) {
    case Positions.COUNT:
      return isCountItemAllowed(item.dataType);
    case Positions.UNUSED:
      return item.dataType !== Metadata.DATETIME;
    default:
      return true;
  }
};

export const getGroupOrder = (type: string, optionGroups: Record<string, MetadataOption[]>): MetadataOption[] => {
  const defaultOrder = [
    ...(optionGroups["Attribution Groups"] ?? []),
    ...(optionGroups["Google Kubernetes Engine"] ?? []),
    ...(optionGroups["GKE Usage Metering (deprecated)"] ?? []),
    ...(optionGroups.Tags ?? []),
    ...(optionGroups.Labels ?? []),
    ...(optionGroups["System Labels"] ?? []),
    ...(optionGroups["Project Labels"] ?? []),
    ...(optionGroups["GKE Labels"] ?? []),
    ...(optionGroups["GKE Usage Metering Labels (deprecated)"] ?? []),
  ];
  switch (type) {
    case Positions.ROW:
      return [...(optionGroups.Standard ?? []), ...defaultOrder, ...(optionGroups["Date/Time"] ?? [])];
    case Positions.COL:
      return [...(optionGroups["Date/Time"] ?? []), ...(optionGroups.Standard ?? []), ...defaultOrder];
    case Positions.COUNT:
      return [
        ...(optionGroups.Standard ?? []).filter((option) => option.typeLabel || isCountItemAllowed(option.data.type)),
        ...(optionGroups["Attribution Groups"] ?? []),
        ...(optionGroups.Tags ?? []),
        ...(optionGroups.Labels ?? []),
        ...(optionGroups["System Labels"] ?? []),
        ...(optionGroups["Project Labels"] ?? []),
      ];
  }
  return [...(optionGroups.Metric ?? []), ...(optionGroups.Standard ?? []), ...defaultOrder];
};

const reportGroupSort = <T extends AttributionMetadataOption>(optionGroups: Record<string, T[]>) =>
  sortBy(
    [
      ...(optionGroups?.[Metadata.DATETIME] ?? []),
      ...(optionGroups?.[Metadata.FIXED] ?? []),
      ...(optionGroups?.[Metadata.GKE] ?? []),
      ...(optionGroups?.[Metadata.ATTRIBUTION] ?? []),
      ...(optionGroups?.[Metadata.ATTRIBUTION_GROUP] ?? []),
      ...(optionGroups?.[Metadata.TAG] ?? []),
      ...(optionGroups?.[Metadata.LABEL] ?? []),
      ...(optionGroups?.[Metadata.SYSTEM_LABEL] ?? []),
      ...(optionGroups?.[Metadata.PROJECT_LABEL] ?? []),
      ...(optionGroups?.[Metadata.GKE_LABEL] ?? []),
    ],
    (metric) => [metric.data.order, metric.data.label]
  );

export const getGroupOrderForMetadata = <T extends AttributionMetadataOption>(
  optionGroups: Record<string, T[]>,
  isReport?: boolean
): T[] => {
  if (isReport) {
    return reportGroupSort(optionGroups);
  }
  return sortBy(
    [
      ...(optionGroups?.[Metadata.ATTRIBUTION] ?? []),
      ...(optionGroups?.[Metadata.FIXED] ?? []),
      ...(optionGroups?.[Metadata.GKE] ?? []),
      ...(optionGroups?.[Metadata.LABEL] ?? []),
      ...(optionGroups?.[Metadata.PROJECT_LABEL] ?? []),
      ...(optionGroups?.[Metadata.SYSTEM_LABEL] ?? []),
      ...(optionGroups?.[Metadata.GKE_LABEL] ?? []),
      ...(optionGroups?.[Metadata.TAG] ?? []),
      ...(optionGroups?.[Metadata.DATETIME] ?? []),
    ],
    (metric) => metric.data.order
  );
};

export const getChipsOrderForMetadata = (dimensions: MetadataOption[], config: ReportConfig) =>
  orderBy(
    dimensions,
    [
      "_position",
      (d) => {
        switch (d._position) {
          case Positions.ROW:
            return config.rows?.findIndex((r) => r === d.id);
          case Positions.COL:
            return config.cols?.findIndex((r) => r === d.id);
          default:
            return d.data.order < 0 ? Infinity : d.data.order;
        }
      },
    ],
    ["desc", "asc"]
  );

export const createDefaultMDOption = (): MetadataOption => ({
  id: "",
  _disabled: false,
  _filter: null,
  _inverse: false,
  _isDragging: false,
  _limit: null,
  _limitOrder: null,
  _limitMetric: null,
  _position: "unused",
  _regexp: null,
  _values: { awsValues: [], gcpValues: [] },
  _visible: false,
  data: {
    id: "",
    cloud: [],
    customer: null,
    organization: null,
    disableRegexpFilter: false,
    field: "",
    key: "",
    label: "",
    nullFallback: null,
    order: 0,
    plural: "",
    subType: "",
    timestamp: firestoreTimestamp(),
    type: "",
    values: null,
  },
});

export const labelTagList = [
  Metadata.LABEL,
  Metadata.PROJECT_LABEL,
  Metadata.SYSTEM_LABEL,
  Metadata.GKE_LABEL,
  Metadata.TAG,
];
export const gkeLabelPrefix = "k8s-label/";

export const convertSavingsTotalOverTotalValue = (value) => 1 - 1 / (value + 1);

export const refactorReportDataToCsv = (data, aggregator = Aggregator.TOTAL, metric = Metric.COST) => {
  if (!data) {
    return null;
  }
  const result: any[] = [];
  const headers: string[] = [reportExportTxt.VALUE];
  const rowKeys: string[][] = data.getRowKeys();
  const colKeys: string[][] = data.getColKeys();

  if (rowKeys.length === 0) {
    rowKeys.push([]);
  }
  if (colKeys.length === 0) {
    colKeys.push([]);
  }

  for (const row of data.rows) {
    headers.push(row.label);
  }
  // When no grouping or filters are selected
  if (colKeys.length === 1 && colKeys[0].length === 0) {
    headers.push("Total");
  } else {
    for (const colKey of colKeys) {
      headers.push(colKey.join("-"));
    }
  }

  rowKeys.forEach((rowKey) => {
    const row: any[] = [reportExportTxt.ACTUAL];
    rowKey.forEach((k: string) => {
      row.push(k.includes(",") ? `"${k}"` : k);
    });
    colKeys.forEach((colKey) => {
      let value: number | null = data.getAggregator(rowKey, colKey).value();
      if (value !== null && aggregator === Aggregator.TOTAL_OVER_TOTAL && metric === Metric.SAVINGS) {
        value = convertSavingsTotalOverTotalValue(value);
      }
      row.push(value ?? "");
    });
    result.push(row);
  });

  // Create Row label and values for forecast
  if (data?.forecasts) {
    const forecastRow = [Labels.FORECAST];
    if (data.rows.length > 0) {
      for (let i = 0; i < data.rows.length; i++) {
        forecastRow.push("");
      }
    }
    data.forecasts.forEach((forecast) => {
      forecastRow.push(forecast.value ?? "");
    });
    result.push(forecastRow);
  }

  return { headers, data: result };
};

/**
 * The functions validates whether users should have access to some data. There are 4 situations the function handles:
 * 1. The users are a member of the organization and the data is also from that organization. In this case the function will an empty array.
 * 2  Neither the users or the data (report or other resource) have any organizations in which cases the function will also return an empty array.
 * 3. The users are not a member of the organization and the data is from an organization. In this case the function will return all the new users emails in an array.
 * 4. The users are a member of the organization and the data is not from that organization. In this case the function will return all the new users emails in an array.
 * @param allOrgUsers - All users in the org (retrieved from a query, this is the "users"+"invites").
 * @param addedUsers - New users being validated whether they should access the data in the org.
 * @param organization - The report being accessed.
 * @param hasUserOrg - If the current user has organization.
 * @returns {string[]} - an array of user emails that should NOT have access to the data.
 */
export const validateUsersFromOrg = (
  allOrgUsers: string[],
  addedUsers: string[],
  organization?: ModelReference<CustomerModelOrganizationModel> | null,
  hasUserOrg?: boolean
): string[] => {
  if (hasUserOrg) {
    if (organization) {
      const nonDoitSubscribers = addedUsers.filter((sub) => !sub.endsWith("@doit-intl.com"));
      if (nonDoitSubscribers.length > 0) {
        // Checks whether the non doit users added to subscribe are all from the users in the current user's organization.
        return difference(nonDoitSubscribers, allOrgUsers);
      } else {
        return addedUsers;
      }
    } else {
      if (organization !== null) {
        return addedUsers.filter((u) => u.endsWith("@doit-intl.com"));
      }
    }
  } else {
    if (organization) {
      return addedUsers.filter((u) => u.endsWith("@doit-intl.com"));
    }
  }
  // If there is no organization for the current user and report, all users can subscribe to any report. OR the resource is not a report.
  return [];
};

export enum AttributionsMenuOptionsValues {
  SUBSCRIBE = "subscribe",
  UNSUBSCRIBE = "unsubscribe",
  SHARE = "share",
  DUPLICATE = "duplicate",
  DELETE = "delete",
  EDIT = "edit",
  ANOMALY_DETECTION = "anomalyDetection",
}

export enum ReportMenuOptionsValues {
  COPY_LINK = "copy_link",
  SHARE = "share",
  DUPLICATE = "duplicate",
  DELETE = "delete",
  SCHEDULE = "schedule",
}

export type AttributionsMenuOption = {
  label: string;
  value: AttributionsMenuOptionsValues;
  order: number;
  secondary?: boolean;
};

export enum BasicMenuOptions {
  DELETE = "Delete",
  DUPLICATE = "Duplicate",
  SHARE = "Share",
}

export enum AnalyticsAlertMenuOptions {
  DELETE = "Delete",
  DUPLICATE = "Duplicate",
  SHARE = "Share alert",
  INVESTIGATE = "Investigate",
  SUBSCRIBE = "Subscribe",
}

export type AnalyticsAlertMenuOptionProps = {
  label: string;
  value: string;
  disabled?: boolean;
  color?: string;
};

export enum BudgetMenuOptions {
  SUBSCRIBE = "Subscribe",
  INVESTIGATE = "Investigate",
  SHARE = "Share budget",
  DUPLICATE = "Duplicate",
  DELETE = "Delete",
}

export type BudgetMenuOptionProps = {
  label: string;
  value: string;
  disabled?: boolean;
  secondary?: boolean;
};

export type BudgetRowActions = {
  setOpenDeleteDialog: (open: boolean) => void;
  setShareDialogOpen: (open: boolean) => void;
  setSelected: (budgets: Budget[]) => void;
};

export type ReportMenuOption = {
  label: string;
  value: ReportMenuOptionsValues;
  order: number;
  secondary?: boolean;
  disabled?: boolean;
};

export const attributionsMenuOptions: AttributionsMenuOption[] = [
  {
    label: attributionText.SUBSCRIBE_TO_DAILY_DIGEST,
    value: AttributionsMenuOptionsValues.SUBSCRIBE,
    order: 1,
  },
  {
    label: attributionText.UNSUBSCRIBE_FROM_DAILY_DIGEST,
    value: AttributionsMenuOptionsValues.UNSUBSCRIBE,
    order: 1,
  },
  {
    label: attributionText.SHARE,
    value: AttributionsMenuOptionsValues.SHARE,
    order: 2,
  },
  // TODO: Implement copy handler
  // {
  //   label: attributionText.DUPLICATE,
  //   value: AttributionsMenuOptionsValues.DUPLICATE,
  //   order: 3,
  // },
  {
    label: attributionText.DELETE,
    value: AttributionsMenuOptionsValues.DELETE,
    order: 4,
    secondary: true,
  },
  {
    label: attributionText.ANOMALY_DETECTION,
    value: AttributionsMenuOptionsValues.ANOMALY_DETECTION,
    order: 3,
  },
  // {
  //   label: attributionText.EDIT,
  //   value: AttributionsMenuOptionsValues.EDIT,
  //   order: 5,
  // },
];

export const alertMenuOptions: AnalyticsAlertMenuOptionProps[] = [
  {
    label: AnalyticsAlertMenuOptions.SHARE,
    value: AnalyticsAlertMenuOptions.SHARE,
  },
  {
    label: AnalyticsAlertMenuOptions.DUPLICATE,
    value: AnalyticsAlertMenuOptions.DUPLICATE,
  },
  {
    label: AnalyticsAlertMenuOptions.DELETE,
    value: AnalyticsAlertMenuOptions.DELETE,
    color: "error",
  },
  {
    label: AnalyticsAlertMenuOptions.SUBSCRIBE,
    value: AnalyticsAlertMenuOptions.SUBSCRIBE,
  },
];

export const budgetMenuOptions: BudgetMenuOptionProps[] = [
  {
    label: BudgetMenuOptions.INVESTIGATE,
    value: BudgetMenuOptions.INVESTIGATE,
  },
  {
    label: BudgetMenuOptions.SUBSCRIBE,
    value: BudgetMenuOptions.SUBSCRIBE,
  },
  {
    label: BudgetMenuOptions.SHARE,
    value: BudgetMenuOptions.SHARE,
  },
  {
    label: BudgetMenuOptions.DUPLICATE,
    value: BudgetMenuOptions.DUPLICATE,
  },
  {
    label: BudgetMenuOptions.DELETE,
    value: BudgetMenuOptions.DELETE,
    secondary: true,
  },
];

export const reportMenuOptions: ReportMenuOption[] = [
  {
    label: reportText.MENU.COPY_LINK,
    value: ReportMenuOptionsValues.COPY_LINK,
    order: 1,
  },
  {
    label: reportText.MENU.SHARE,
    value: ReportMenuOptionsValues.SHARE,
    order: 2,
  },
  {
    label: reportText.MENU.SCHEDULE,
    value: ReportMenuOptionsValues.SCHEDULE,
    order: 3,
  },
  {
    label: reportText.MENU.DUPLICATE,
    value: ReportMenuOptionsValues.DUPLICATE,
    order: 4,
  },
  {
    label: reportText.MENU.DELETE,
    value: ReportMenuOptionsValues.DELETE,
    order: 5,
    secondary: true,
  },
];

export const disableDimension = (md) => {
  md._visible = false;
  md._disabled = true;
  md._position = Positions.UNUSED;
  return md;
};

export const getMetricFilterMD = (metricFilters) => {
  const md = createDefaultMDOption();
  md.id = `${Metadata.METRIC}:${Metadata.METRIC}`;
  md.data.type = Metadata.METRIC;
  md.data.label = metricFiltersText.TITLE;
  if (metricFilters?.length > 0) {
    md._visible = true;
    md._metricFilter = true;
  }
  return md;
};

export const getLimitsFilterMD = (limitsExist: boolean): MetadataOption => {
  const md = createDefaultMDOption();

  md.id = `${Metadata.LIMITS}:${Metadata.LIMITS}`;
  md.data.type = Metadata.LIMITS;
  md.data.label = cloudAnalyticsText.LIMITS.TITLE;

  if (limitsExist) {
    md._visible = true;
    md._metricFilter = true;
  } else {
    md._disabled = true;
  }
  return md;
};

export const limitsExist = (metadata: MetadataOption[]): boolean => metadata.some((md) => !!md._limit);

export const getMetricFilterName = (filter: MetricFilter, calculatedMetric: MetricWSnap, extendedMetric: string) => {
  if (filter.metric === Metric.EXTENDED) {
    return extendedMetric;
  }
  if (filter.metric === Metric.CALCULATED) {
    return calculatedMetric?.data?.name ?? "";
  }
  return filter.metric === Metric.MARGIN ? CSPMetric.label : MetricOptions[filter.metric].label;
};

export const defaultMetricFilterOperator = MetricFiltersOptions[0].value;

export const getMetricFilterOperatorSymbol = (op: MetricFilterOperator): string => {
  switch (op) {
    case MetricFilterOperator.NOT_BETWEEN:
      return "not between";
    case MetricFilterOperator.NOT_EQUALS:
      return "≠";
    default:
      return op;
  }
};

export enum AxisTypeValue {
  Linear = "linear",
  Logarithmic = "logarithmic",
  Datetime = "datetime",
  Category = "category",
  Treegrid = "treegrid",
}

export const getAxisType = (logScale: boolean): AxisTypeValue =>
  logScale ? AxisTypeValue.Logarithmic : AxisTypeValue.Linear;

export enum ExportType {
  CSV,
  GOOGLE_SHEETS,
  PDF,
  PNG,
  CLIPBOARD,
}

export const notifyMixpanelOnExport = (reportId: string, exportType: ExportType) => {
  mixpanel.track("analytics.reports.export", {
    reportId,
    "export-type": exportType,
  });
};

export const WeekdaySortOrder = new Map<string, number>([
  ["Monday", 1],
  ["Tuesday", 2],
  ["Wednesday", 3],
  ["Thursday", 4],
  ["Friday", 5],
  ["Saturday", 6],
  ["Sunday", 7],
]);

export const isTimeSeriesReport = (metadata) => {
  if (!metadata) {
    return true;
  }
  const colDimensions = metadata.filter((md) => md._position === Positions.COL);
  if (colDimensions.length > 0) {
    return colDimensions.every((md) => md.data.type === Metadata.DATETIME);
  }
  return false;
};

export const advancedOptionsTitle = (metadata, features) => {
  const featuresCondition =
    features.length > 1 ? features.map((feat) => FeatureOptions?.find((f) => f.value === feat)?.label).join(", ") : "";
  return isTimeSeriesReport(metadata)
    ? featuresCondition
    : "Advanced Analysis is only enabled for reports with time dimension";
};

export const minDate = DateTime.utc(2018, 1, 1);

export const maxChips = 4;

export const isTable = (renderer: Renderer) =>
  [Renderer.TABLE, Renderer.HEATMAP, Renderer.COL_HEATMAP, Renderer.ROW_HEATMAP].includes(renderer);

export const getMetricFilterCondition = (f, values, calculatedMetric, extendedMetric) => {
  let condition = `${getMetricFilterName(f, calculatedMetric, extendedMetric)} ${getMetricFilterOperatorSymbol(
    f.operator
  )} `;
  if (values.length > 0) {
    condition = `${condition}${values[0]}`;
  }
  if (values.length > 1) {
    condition = `${condition} AND ${values[1]}`;
  }
  return condition;
};

type HasCollaborators = {
  collaborators: Collaborator[];
};

export const doesUserHaveRole = <T extends HasCollaborators>(email: string, obj: T, roles: Roles[]) =>
  !!obj.collaborators?.find((c) => c.email === email && c.role && roles.includes(c.role));

type HasPublicAccess = {
  public?: PublicAccess;
};

export const isOwner = <T extends HasCollaborators>(email: string, obj: T) =>
  doesUserHaveRole(email, obj, [Roles.OWNER]);
export const isEditor = <T extends HasCollaborators & HasPublicAccess>(email: string, obj: T) =>
  doesUserHaveRole(email, obj, [Roles.OWNER, Roles.EDITOR]) || obj.public === Roles.EDITOR;

export const isSharedWith = <T extends HasCollaborators & HasPublicAccess>(email: string, obj: T) =>
  !!obj.public || doesUserHaveRole(email, obj, [Roles.OWNER, Roles.EDITOR]);

export const isUserOwner = <T extends HasCollaborators>(
  email: string,
  rowData: {
    collaborators: Collaborator[];
  }
) => {
  const { collaborators } = rowData;
  return collaborators && isOwner(email, { collaborators } as T);
};

export const isUserEditor = <T extends HasCollaborators & HasPublicAccess>(
  email: string,
  rowData: {
    collaborators: Collaborator[];
    publicAccess?: PublicAccess;
  }
) => {
  const { collaborators, publicAccess } = rowData;
  return isEditor(email, { collaborators, public: publicAccess } as T);
};

export const updateColKeySort = (setColKeySort, colKey) => {
  setColKeySort((prev) =>
    prev === null || prev.order === Sort.DESC ? { order: Sort.ASC, key: colKey } : { order: Sort.DESC, key: colKey }
  );
};

export enum ReportStatus {
  aws = "amazon-web-services",
  gcp = "google-cloud",
  gke = "gke",
}

export const StatusOptions = {
  [ReportStatus.aws]: statusesTexts.aws,
  [ReportStatus.gcp]: statusesTexts.gcp,
  [ReportStatus.gke]: statusesTexts.gke,
};

export const optionsGroupLabel = (option: AttributionMetadataOption): string => {
  const type = option.data.type;
  switch (type) {
    case Metadata.ATTRIBUTION:
      return "Basic Dimensions";
    case Metadata.FIXED:
      if (isGKECostAllocationOptionKey(option.data.key)) {
        return `${Metadata.GKE.toUpperCase()} Dimensions`;
      }
      return "Basic Dimensions";
    case Metadata.GKE:
      return `${Metadata.GKE.toUpperCase()} Dimensions`;
    case Metadata.LABEL:
      if (option.data.subType === Metadata.GKE_COST_ALLOCATION_LABEL) {
        return "GKE Labels";
      }
      return "Labels";
    case Metadata.PROJECT_LABEL:
      return "Project Labels";
    case Metadata.SYSTEM_LABEL:
      return "System Labels";
    case Metadata.GKE_LABEL:
      return "GKE Labels";
    case Metadata.TAG:
      return "Tags";
    default:
      return startCase(type);
  }
};

export const filterBase = (
  md: AttributionMetadataOption
): {
  id: string;
  field: string;
  type: Metadata | "";
  key: string;
  allowNull: boolean;
  inverse: boolean;
  regexp: string | null;
  values: string[] | null;
} => ({
  id: md.id,
  field: md.data.field,
  type: md.data.type,
  key: md.data.key,
  allowNull: false,
  inverse: false,
  regexp: null,
  values: [],
});

export const getBaseAttributionFilter = (): AttributionFilter => ({
  allowNull: false,
  field: "",
  id: "",
  inverse: false,
  key: "",
  type: "",
  values: null,
});

export const getBaseMetadataOption = (): AttributionMetadataOption => ({
  id: "",
  _filter: null,
  _visible: true,
  _values: { awsValues: [], gcpValues: [] },
  _disabled: false,
  data: {
    id: "",
    cloud: [],
    customer: null,
    organization: null,
    disableRegexpFilter: false,
    field: "",
    key: "",
    label: "",
    nullFallback: null,
    order: 0,
    plural: "",
    subType: "",
    timestamp: TimestampNow(),
    type: "",
    values: null,
  },
});

export const prepareLabelsFilters = (filters: AttributionFilter[]) =>
  filters
    .filter((f) => f.type && labelTagList.includes(f.type))
    .map((f) => ({
      key: f.key,
      type: f.type,
    }));

export const defaultMetadataValues = () => ({
  _isDragging: false,
  _filter: null,
  _regexp: null,
  _inverse: false,
  _limit: null,
});

export const formatFirestoreDate = (
  timeStamp: ModelTimestamp | null,
  dateFormat: Intl.DateTimeFormatOptions = DateTime.DATETIME_MED_WITH_SECONDS
) => {
  if (!timeStamp) {
    return "";
  }
  const date = DateTime.fromJSDate(timeStamp.toDate());
  if (date.isValid) {
    return date.toLocaleString(dateFormat);
  }
  return "";
};

export type organizationRef = ModelReference<OrganizationsModel> | ModelReference<CustomerModelOrganizationModel>;
export const getOrganizationRef = (
  doitEmployee: boolean,
  userOrganization: OrganizationWSnap | null | undefined,
  customerId: string,
  organizationId = "root"
): organizationRef => {
  if (doitEmployee) {
    return getCollection(OrganizationsModel).doc(PresetOrganizations.DOITOrg);
  }

  if (userOrganization) {
    return asModelRefFromFirestoreRef<CustomerModelOrganizationModel>(userOrganization.snapshot.ref);
  }

  if (customerId !== "") {
    return getCollection(CustomerModel).doc(customerId).subCollection(CustomerModel.Organizations).doc(organizationId);
  }

  throw Error("invalid user organization");
};

export const getCurrencyOption = (currencyCode: CurrencyCodes) => `${currencyCode} (${CurrenciesMap[currencyCode]})`;

export const getReportRowSort = (renderer: Renderer): Sort => {
  switch (renderer) {
    case Renderer.COLUMN_CHART:
    case Renderer.STACKED_COLUMN_CHART:
    case Renderer.BAR_CHART:
    case Renderer.STACKED_BAR_CHART:
    case Renderer.LINE_CHART:
    case Renderer.AREA_CHART:
    case Renderer.AREA_SPLINE_CHART:
    case Renderer.STACKED_AREA_CHART:
      return Sort.ASC;
    default:
      return Sort.A_TO_Z;
  }
};

export const alertsTransform = (
  data: WithFirebaseModel<CloudAnalyticsModelAlertModel>,
  snapshot: DocumentSnapshotModel<CloudAnalyticsModelAlertModel>
): AnalyticsAlertWithRef => ({
  data,
  ref: asModelRefFromFirestoreRef(snapshot.ref),
  transform: {
    owner: data.collaborators.find((c) => c.role === Roles.OWNER)?.email,
    lastSent: formatFirestoreDate(data.timeLastAlerted),
    timeCreated: formatFirestoreDate(data.timeCreated),
    timeModified: formatFirestoreDate(data.timeModified),
  },
});

function stripDiacritics(string) {
  return typeof string.normalize !== "undefined" ? string.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : string;
}

export function createFilterOptions<T>(config: CreateFilterOptionsConfig<T>) {
  const { ignoreAccents = true, ignoreCase = true, limit, matchFrom = "any", stringify, trim = false } = config;

  return (
    options: T[],
    { inputValue, getOptionLabel }: { inputValue: string; getOptionLabel: (option: T) => string }
  ) => {
    const inputBase = trim ? inputValue.trim() : inputValue;
    let input = inputBase.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, " ");
    if (ignoreCase) {
      input = input.toLowerCase();
    }
    if (ignoreAccents) {
      input = stripDiacritics(input);
    }

    let filteredOptions = !input
      ? options
      : options.filter((option) => {
          let candidate = (stringify || getOptionLabel)(option);
          if (ignoreCase) {
            candidate = candidate.toLowerCase();
          }
          if (ignoreAccents) {
            candidate = stripDiacritics(candidate);
          }
          const inputWords = input.split(" ");
          for (let i = 0; i < inputWords.length; i++) {
            const match =
              matchFrom === "start" ? candidate.indexOf(inputWords[i]) === 0 : candidate.indexOf(inputWords[i]) > -1;
            if (!match) {
              return false;
            }
          }

          return true;
        });

    // place all the exact matches at the top
    if (input) {
      const exactMatches = filteredOptions.filter((option) => {
        const candidate = (stringify || getOptionLabel)(option);
        return candidate.toLowerCase() === input;
      });
      const otherMatches = filteredOptions.filter((option) => {
        const candidate = (stringify || getOptionLabel)(option);
        return candidate.toLowerCase() !== input;
      });
      filteredOptions = [...exactMatches, ...otherMatches];
    }

    return typeof limit === "number" ? filteredOptions.slice(0, limit) : filteredOptions;
  };
}

const isAllowedSubDomain = (email: string, domain: string): boolean => {
  const subdomain = email.slice(email.indexOf("@"));
  const r = new RegExp(`@[^.\\s]{1,100}\\.${domain}$`);
  return r.test(subdomain);
};

export const isDoitCompanyDomain = (email: string): boolean => {
  const domain = email.split("@")[1];
  return domain === doitDomain || domain === doitPreviousDomain;
};

export const checkAllowedSubdomains = (email: string): boolean =>
  isDoitCompanyDomain(email) || isAllowedSubDomain(email, "slack.com") || isAllowedSubDomain(email, "teams.ms");

export const MD_LIMITS_ID = `${Metadata.LIMITS}:${Metadata.LIMITS}`;
export const MD_METRIC_ID = `${Metadata.METRIC}:${Metadata.METRIC}`;

export const limitResultsIds = [MD_METRIC_ID, MD_LIMITS_ID];
export const dimensionsIsOptionDisabled = (option, comparative: ComparativeFeature) =>
  option._disabled || (isComparative(comparative) && option.data.type !== "datetime");

export const advancedOptionsRenderValue = (metadata, values) =>
  isTimeSeriesReport(metadata) && values.length > 0
    ? values.map((feat) => FeatureOptions?.find((f) => f.value === feat)?.label).join(", ")
    : "None";

export const REPORT_LEFT_PANEL_WIDTH = 280;
export const REPORT_LEFT_PANEL_WIDTH_CLOSED = 45;
export const REPORT_LEFT_PANEL_BOTTOM_HEIGHT = 55;

export type ReportItemClick = (
  option: MetadataOption[],
  simulateClick?: boolean,
  fromList?: boolean,
  openDialog?: boolean,
  filterExistingChip?: boolean
) => (event: any) => void;

export type ReportItemRemove = (option: MetadataOption[]) => void;

export type RenderDraggableContent = (
  sectionId: string,
  disabled: boolean,
  isHidden: boolean
) => (items: string[]) => JSX.Element;

export const getCloudFromAssets = (
  assets?: ProductEnum[]
): ProductEnum.AmazonWebServices | ProductEnum.GoogleCloud | null => {
  if (!assets || assets.length === 0) {
    return null;
  }

  const hasGoogleCloud =
    !!assets?.includes(ProductEnum.GoogleCloud) ||
    !!assets?.includes(ProductEnum.GoogleCloudDirect) ||
    !!assets?.includes(ProductEnum.GoogleCloudStandalone);

  const hasAmazonWebServices =
    !!assets?.includes(ProductEnum.AmazonWebServices) || !!assets?.includes(ProductEnum.AmazonWebServicesStandalone);

  if (hasGoogleCloud !== hasAmazonWebServices) {
    return hasGoogleCloud ? ProductEnum.GoogleCloud : ProductEnum.AmazonWebServices;
  }

  return null;
};

export function mergeCollaboratorsLists(shareableEntities: ShareableEntity[]): Collaborator[] {
  const result: { [email: string]: { collaborator: Collaborator; count: number } } = {};

  for (const entity of shareableEntities) {
    for (const collaborator of entity.collaborators) {
      if (!result[collaborator.email]) {
        result[collaborator.email] = { collaborator: { ...collaborator }, count: 0 };
      } else if (result[collaborator.email].collaborator.role !== collaborator.role) {
        result[collaborator.email].collaborator.role = Roles.MIXED;
      }

      result[collaborator.email].count++;
    }
  }

  const collaborators = Object.values(result).map(({ collaborator, count }) =>
    count === shareableEntities.length ? { ...collaborator } : { ...collaborator, role: Roles.MIXED }
  );

  return collaborators;
}

export function mergePublicAccessList(permissions: PublicAccess[]): PublicAccess {
  const permissionsSet = new Set(permissions);

  if (permissionsSet.size === 0) {
    return null;
  }

  if (permissionsSet.size === 1) {
    return permissions[0];
  }

  return "mixed";
}

/**
 * Returns a new array of collaborators based on the input arrays of new and old collaborators.
 * In case of new owner, previous owner would be downgraded to an editor.
 */
export function getNewCollaborators(
  newCollaborators: Collaborator[],
  oldCollaborators: Collaborator[]
): Collaborator[] {
  let newCollaboratorsCopy = cloneDeep(newCollaborators);
  const hasNewOwner = newCollaborators.some((collaborator) => collaborator.role === Roles.OWNER);
  const oldOwner = oldCollaborators.find((collaborator) => collaborator.role === Roles.OWNER);

  if (!oldOwner) {
    throw new Error("Old owner must be defined");
  }

  newCollaboratorsCopy.forEach((collaborator, index) => {
    if (collaborator.role === Roles.MIXED) {
      const prevCollaborator = oldCollaborators.find((x) => x.email === collaborator.email);
      if (prevCollaborator) {
        newCollaboratorsCopy[index] = prevCollaborator;
      }
    } else if (collaborator.role === Roles.OWNER) {
      if (oldOwner && oldOwner?.email !== collaborator.email) {
        const oldOwnerIndex = newCollaboratorsCopy.findIndex((x) => x.email === oldOwner.email);
        newCollaboratorsCopy[oldOwnerIndex] = { ...oldOwner, role: Roles.EDITOR };
      }
    } else {
      // If there is no new owner, the previous owner would be kept
      if (!hasNewOwner && collaborator.email === oldOwner?.email) {
        newCollaboratorsCopy[index] = { ...collaborator, role: Roles.OWNER };
      }
    }
  });

  newCollaboratorsCopy = newCollaboratorsCopy.filter((collaborator) => collaborator.role !== Roles.MIXED);
  return newCollaboratorsCopy;
}

export const parseMetricSplitsToSplitObj = (
  splits: any[],
  attributions: AttributionWRef[],
  metadata: MetadataOption[]
) =>
  splits?.reduce((prevSplit, currSplit) => {
    const item = metadata.find((option) => option.id === currSplit.id);
    const targetsObj = currSplit.targets.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {});
    const targets = createTargetsFromAttributions(item, attributions, targetsObj);
    const origin = targets.find(
      (t) =>
        t?.id === (currSplit.origin === item?.data.nullFallback ? `${currSplit.origin}-${item?.id}` : currSplit.origin)
    );
    if (!origin) {
      return prevSplit;
    }
    return {
      ...prevSplit,
      [origin.id]: {
        ...currSplit,
        origin,
        targets,
      },
    };
  }, {}) || {};

export const parseNullFallbackId = (id?: string, isNullFallback?: boolean) => {
  if (id?.startsWith(attributionGroupsText.UNALLOCATED) || (id && isNullFallback)) {
    return attributionGroupsText.UNALLOCATED;
  }

  return id;
};

export const parseMetricSplitsForReport = (splits: { [key: string]: Split } = {}) =>
  Object.values(splits).map((split) => ({
    ...split,
    origin: parseNullFallbackId(split.origin?.id, split.origin?.isNullFallback),
    targets: split?.targets
      .map((target) => {
        if (target.id !== split.origin?.id && target.checked && +target.value !== 0) {
          return {
            id: parseNullFallbackId(target.id, target.isNullFallback),
            value: +target.value,
          };
        }
      })
      .filter((t) => t),
  }));

export const getMetadataMode = (md: MetadataOption) => {
  // GKE fields
  if (md.data.type === Metadata.GKE || md.data.type === Metadata.GKE_LABEL) {
    return ReportMode.GKE;
  }

  // Shared fixed fields
  if (md.data.type === Metadata.FIXED && sharedFixedFields.includes(md.data.key as FixedFilters)) {
    return ReportMode.ALL;
  }

  // Other fields that are used in both modes
  if ([Metadata.ATTRIBUTION, Metadata.DATETIME].includes(md.data.type as Metadata)) {
    return ReportMode.ALL;
  }

  return ReportMode.BILLING;
};
