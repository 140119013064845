import { collection, doc, Reference, subCollection, Timestamp } from "@doitintl/models-types";
import type { EmojiData } from "emoji-mart";
import { CustomerModel, CustomerModelOrganizationModel, Widget } from "./Customer";
import { EntityModel } from "./Entity";
import { CloudAnalyticsModelMetricModel, LimitAggregation } from "./CloudAnalytics";
import { InvoiceData, ProductEnum } from "./Collection";
import { AssetType } from "./AssetSetting";
import { CurrencyCodes } from "./Currency";
import { Permission } from "./User";

export enum Roles {
  OWNER = "owner",
  VIEWER = "viewer",
  EDITOR = "editor",
  MIXED = "mixed",
}

export type DashboardRole = `${Roles}`;

export type Collaborator = {
  email: string;
  role: Roles;
};

export enum Positions {
  UNUSED = "unused",
  ROW = "row",
  COL = "col",
  COUNT = "count",
}

export enum Relation {
  OR = "OR",
  AND = "AND",
}

export enum Metadata {
  FIXED = "fixed",
  OPTIONAL = "optional",
  DATETIME = "datetime",
  LABEL = "label",
  PROJECT_LABEL = "project_label",
  SYSTEM_LABEL = "system_label",
  ATTRIBUTION = "attribution",
  ATTRIBUTION_GROUP = "attribution_group",
  GKE = "gke",
  GKE_LABEL = "gke_label",
  TAG = "tag",
  METRIC = "metric",
  LIMITS = "limits",
  GKE_COST_ALLOCATION_LABEL = "gke_cost_allocation_label",
}

export type AttributionFilter = {
  allowNull: boolean;
  field: string;
  id: string;
  inverse: boolean;
  key: string;
  regexp?: string | null;
  type: Metadata | "";
  values: string[] | null;
};

export enum Aggregator {
  TOTAL = "total",
  TOTAL_OVER_TOTAL = "total_over_total",
  PERCENT_TOTAL = "percent_total",
  PERCENT_ROW = "percent_row",
  PERCENT_COL = "percent_col",
  COUNT = "count",
}

export enum Sort {
  A_TO_Z = "a_to_z",
  ASC = "asc",
  DESC = "desc",
}

export enum Metric {
  COST,
  USAGE,
  SAVINGS,
  MARGIN,
  CALCULATED,
  EXTENDED,
}

export type CustomTimeRange = {
  to: Timestamp;
  from: Timestamp;
};

export type ReportOptionalField = {
  key: string;
  type: Metadata | "";
};

export enum Renderer {
  TABLE = "table",
  HEATMAP = "table_heatmap",
  ROW_HEATMAP = "table_row_heatmap",
  COL_HEATMAP = "table_col_heatmap",
  COLUMN_CHART = "column_chart",
  STACKED_COLUMN_CHART = "stacked_column_chart",
  STACKED_COLUMN_CHART_2 = "stacked_column_chart_2",
  BAR_CHART = "bar_chart",
  STACKED_BAR_CHART = "stacked_bar_chart",
  LINE_CHART = "line_chart",
  SPLINE_CHART = "spline_chart",
  AREA_CHART = "area_chart",
  AREA_SPLINE_CHART = "area_spline_chart",
  STACKED_AREA_CHART = "stacked_area_chart",
  SHEETS_EXPORT = "sheets_export",
  CSV_EXPORT = "csv_export",
  CSV_CLIPBOARD = "csv_clipboard",
  TREEMAP = "treemap_chart",
  PDF_DOWNLOAD = "pdf_download",
  PNG_DOWNLOAD = "png_download",
}

export enum Feature {
  TREND_UP = "increasing",
  TREND_DOWN = "decreasing",
  TREND_NONE = "none",
  FORECAST = "forecast",
}

export enum ComparativeFeature {
  NONE = "none",
  VALUES = "values",
  PERCENT = "percent",
  BOTH = "both",
}

export enum TimeInterval {
  HOUR = "hour",
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  QUARTER = "quarter",
  YEAR = "year",
}

export type ReportFilter = {
  type?: Metadata;
  position?: Positions;
  id: string;
  field: string;
  key: string;

  // Filter options
  includeInFilter: boolean;
  allowNull: boolean;
  inverse: boolean;
  regexp: string;
  values: string[];
  limit: number;
  limitOrder?: string | null;
  limitMetric?: Metric | null;

  // attributions are composed of other fields
  composite: AttributionFilter[];
};

export type AttributionGroupFilter = ReportFilter & { attributions: ReportFilter[] };

export enum MetricFilterOperator {
  GREATER_THAN = ">",
  LESS_THAN = "<",
  GREATER_THAN_OR_EQUAL = ">=",
  LESS_THAN_OR_EQUAL = "<=",
  BETWEEN = "between",
  NOT_BETWEEN = "not_between",
  EQUALS = "=",
  NOT_EQUALS = "!=",
}

export type MetricFilter = {
  metric: number;
  operator: MetricFilterOperator;
  values: number[];
};

export type AnalyticsMode = "all" | "billing" | "gke";

export enum TimeSettingsMode {
  Last = "last",
  Current = "current",
  Fixed = "custom",
}

export type TimeSettingsConfig = {
  amount: number;
  mode: TimeSettingsMode;
  unit: TimeInterval;
  includeCurrent: boolean;
};

export type EmptyReportFallback = {
  text: string;
  url: string;
};

export type ReportConfig = {
  aggregator: Aggregator;
  calculatedMetric: Reference<CloudAnalyticsModelMetricModel> | null;
  colOrder: Sort;
  cols: string[] | null;
  comparative: ComparativeFeature | null;
  count: string | null;
  currency?: CurrencyCodes | null;
  customQuery?: string;
  customTimeRange: CustomTimeRange | null;
  emptyReportFallback?: EmptyReportFallback;
  excludePartialData?: boolean;
  includeCredits?: boolean;
  extendedMetric?: string | null;
  features?: Feature[] | null;
  filters: ReportFilter[];
  limitAggregation?: LimitAggregation;
  logScale?: boolean;
  metric: Metric;
  metricFilters?: MetricFilter[] | null;
  mode?: AnalyticsMode;
  optional: ReportOptionalField[];
  renderer: Renderer;
  rowOrder: Sort;
  rows: string[] | null;
  timeInterval: TimeInterval;
  timezone?: string | null;
  timeSettings?: TimeSettingsConfig;
};

export type ReportSchedule = {
  to: string[];
  from: string;
  body: string;
  timezone: string;
  frequency: string;
  subject: string;
};

export enum DashboardType {
  Pulse = "pulse",
  AwsLens = "AWS",
  BqLens = "superquery",
  GcpLens = "gcp-lens",
  GkeLens = "gke-lens",
  GkeLensV2 = "gke-lens-v2",
}

export class DashboardModelUserModelAttachedDashboardModel {
  publicDashboardId!: string;
  customerId!: string;
  isPublic!: true;
  dashboardType?: DashboardType;
  sortNumber?: number;
  defaultDashboard?: true;
}

@subCollection("dashboards")
export class DashboardModelUserModelDashboardModel {
  allowToEdit?: boolean;
  customerId!: string;
  dashboardType?: DashboardType | null;
  email!: string;
  isPublic?: boolean;
  name!: string;
  publicDashboardId?: string;
  sortNumber?: number;
  widgetHeight?: number | null;
  widgets?: Widget[];
  hasCloudReports?: boolean;
  defaultDashboard?: true;
  icon?: string | EmojiData | null;
}

export type DashboardModelUserModelDashboardModelCustomerModel = Omit<
  DashboardModelUserModelDashboardModel,
  "publicDashboardId" | "isPublic" | "email" | "dashboardType" | "widgets"
> & { isPublic?: false; widgets: Widget[] };

export type DashboardModelUserModelAttachedDashboardModelCustomerModel = Omit<
  DashboardModelUserModelAttachedDashboardModel,
  "publicDashboardId" | "isPublic" | "email"
> & { isPublic: true };

export class DashboardModelUserModelCustomersModel {
  subCollections!: {
    dashboards:
      | DashboardModelUserModelDashboardModelCustomerModel
      | DashboardModelUserModelAttachedDashboardModelCustomerModel;
  };
}

@subCollection("users")
export class DashboardModelUserModel {
  static Dashboard = DashboardModelUserModelDashboardModel;
  subCollections!: {
    dashboards: DashboardModelUserModelDashboardModel;
    duc: DashboardModelUserModelCustomersModel; // DashboardsUsersCustomers
  };
}

@subCollection("public-dashboards")
export class DashboardModelPublicDashboardModel {
  customerId!: string;
  dashboardType!: DashboardType | null;
  isPublic?: boolean;
  name!: string;
  publicDashboardId?: string;
  widgets?: Widget[];
}

export type PublicAccess = `${Roles}` | null;

export type Collaborators = {
  email: string;
  role: Roles;
}[];

@subCollection("savedReports")
export class DashboardModelSavedReportsModel {
  collaborators!: Collaborators;
  config!: ReportConfig | null;
  customer!: Reference<CustomerModel> | null;
  description!: string;
  draft?: boolean;
  hidden?: boolean;
  name!: string;
  organization?: Reference<CustomerModelOrganizationModel> | null;
  public!: PublicAccess;
  schedule?: ReportSchedule | null;
  timeCreated!: Timestamp;
  timeModified!: Timestamp;
  type!: "custom" | "preset";
  widgetEnabled?: boolean;
  cloud?: ProductEnum[] | null;
}

@subCollection("attributions")
export class DashboardModelAttributionModel {
  timeModified!: Timestamp;
  timeCreated!: Timestamp;
  type!: "custom" | "preset" | "managed";
  classification?: "invoice";
  customer?: Reference<CustomerModel> | null;
  description?: string;
  mode!: AnalyticsMode;
  name!: string;
  collaborators!: Collaborator[];
  hidden?: boolean;
  filters!: AttributionFilter[] | null;
  formula?: string;
  relation?: Relation;
  anomalyDetection?: boolean;
  public!: `${Roles}` | null;
  draft?: boolean;
  cloud?: ProductEnum[] | null;
}

@subCollection("ids")
export class DashboardModelIdsModel {
  customer!: Reference<CustomerModel>;
  entity?: Reference<EntityModel> | null;
  timestamp!: Timestamp;
  endTime!: Timestamp;
  forecast!: number;
  data!: {
    x: string;
    y: number;
  }[];
}

class DashboardModelInvoices {
  customer!: Reference<CustomerModel>;
  entity!: Reference<EntityModel>;
  invoices!: InvoiceData[];
}

export enum AnalyticsResourceType {
  CUSTOM = "custom",
  PRESET = "preset",
  MANAGED = "managed",
}

@subCollection("invoicesLatest")
export class DashboardModelInvoicesLatest extends DashboardModelInvoices {}

@subCollection("invoicesOverdue")
export class DashboardModelInvoicesOverdue extends DashboardModelInvoices {}

@subCollection("widgetsEmptyState")
export class WidgetsEmptyState {
  emptyReportFallback!: EmptyReportFallback;
}

@subCollection("ticketStatistics")
export class DashboardModelTicketStatistics {
  history!: Record<number, Record<number, number>>;
  satisfactionRate!: number;
}

export type SkuName = "g-suite-basic" | "google-vault";

export type CompanySku = Record<
  SkuName,
  {
    domain: string;
    platform: AssetType;
    quantity: Record<string, number>;
    skuName: string;
  }
>;

export type DashboardModelCompaniesModelDomain = {
  domain: string;
  platform: AssetType;
  quantity: Record<string, number>; // key e.g 2022-09-28
  skuName: string;
};

export type DashboardModelCompaniesModelInfo = {
  customer: Reference<CustomerModel>;
};

@subCollection("companies")
export class DashboardModelCompaniesModel {
  info!: {
    customer: Reference<CustomerModel>;
  };
  readonly [key: string]: Record<string, DashboardModelCompaniesModelDomain> | DashboardModelCompaniesModelInfo;
}

export class CloudHealthModel {
  customer!: Reference<CustomerModel>;
}

@subCollection("commitmentContracts")
export class DashboardModelCommitmentContracts {
  customer!: Reference<CustomerModel>;
  [x: string]: any;
}

export class CustomizationDoc {
  subCollections!: {
    users: DashboardModelUserModel;
    "public-dashboards": DashboardModelPublicDashboardModel;
  };
}

class LicenseChartDoc {
  subCollections!: {
    companies: DashboardModelCompaniesModel;
  };
}

class GoogleCloudReportsDoc {
  subCollections!: {
    attributions: DashboardModelAttributionModel;
    savedReports: DashboardModelSavedReportsModel;
  };
}

export type WidgetConfig = {
  key: string;
  cardSubheader: string;
  cardTitle: string;
  emptyState?: {
    buttonLink: string;
    buttonText: string;
    description: string;
    message: string;
  };
};

export type WidgetsConfig = {
  widgets: WidgetConfig[];
};

class WidgetDoc {
  widgets!: WidgetConfig[];
  subCollections!: {
    widgetsEmptyState: WidgetsEmptyState;
  };
}

type AssetsRenewalsIdsModel = {
  customer: Reference<CustomerModel>;
  endTime: Timestamp;
};

class AssetsRenewalsDoc {
  subCollections!: {
    ids: AssetsRenewalsIdsModel;
  };
}

class InvoicesLatestDoc {
  subCollections!: {
    invoicesLatest: DashboardModelInvoicesLatest;
  };
}

class InvoicesOverdueDoc {
  subCollections!: {
    invoicesOverdue: DashboardModelInvoicesOverdue;
  };
}

class AzureGraphDoc {
  subCollections!: {
    ids: DashboardModelIdsModel;
  };
}

class DashboardModelTicketStatisticsDoc {
  subCollections!: {
    ticketStatistics: DashboardModelTicketStatistics;
  };
}

type CommitmentPeriod = {
  current: boolean;
  endDate: Timestamp;
  ended: boolean;
  estimated: number;
  rollover: number;
  startDate: Timestamp;
  total: number;
  value: number;
};

export type CommitmentContract = {
  commitmentPeriods: CommitmentPeriod[] | null;
  commitmentRollover: boolean;
  customer: Reference<CustomerModel>;
  entity: null | Reference<EntityModel>;
  timestamp: Timestamp;
  type: "amazon-web-services" | "google-cloud";
};

@doc("commitment-contracts")
class CommitmentContractsDoc {
  subCollections!: {
    commitmentContracts: CommitmentContract;
  };
}

export enum QuickLinkWhenCollectionIsEmpty {
  SHOW = "show",
  HIDE = "hide",
}

export type QuickLinkCondition = {
  whenCollectionIsEmpty: QuickLinkWhenCollectionIsEmpty;
  collectionPath: string;
  collectionCustomerRefFieldName?: string;
  collectionFilters?: string;
};

@collection("quickLinks")
export class QuickLinkDescriptorModel {
  name!: string;
  title!: string;
  description!: string;
  icon!: string;
  target!: string;
  sortOrder!: number;

  permissions!: Reference<Permission>[];
  userMaturityFrom?: number;
  userMaturityTo?: number;
  conditions?: QuickLinkCondition[];
}

export enum DiscoveryTileColor {
  RED = "red",
  BLUE = "blue",
  NAVY = "navy",
  PURPLE = "purple",
  TEAL = "teal",
}

@collection("discoveryTiles")
export class DiscoveryTileDescriptorModel {
  name!: string;
  title!: string;
  description!: string;
  color!: DiscoveryTileColor;
  linkLabel!: string;
  target!: string;
  sortOrder!: number;
  permissions?: Reference<Permission>[];
}
@collection("discoveryTileCompletion")
export class DiscoveryTileCompletion {
  completedAt!: Timestamp;
}

@collection("discoveryTileCompletionByUser")
export class DiscoveryTileCompletionByUserModel {
  subCollections!: {
    discoveryTileCompletion: DiscoveryTileCompletion;
  };
}

@doc("home")
export class HomeDoc {
  subCollections!: {
    quickLinks: QuickLinkDescriptorModel;
    discoveryTiles: DiscoveryTileDescriptorModel;
    discoveryTileCompletionByUser: DiscoveryTileCompletionByUserModel;
  };
}

@collection("dashboards")
export class DashboardModel {
  static User = DashboardModelUserModel;
  static SavedReports = DashboardModelSavedReportsModel;
  static Ids = DashboardModelIdsModel;
  static InvoicesLatest = DashboardModelInvoicesLatest;
  static InvoicesOverdue = DashboardModelInvoicesOverdue;
  static TicketStatistics = DashboardModelTicketStatistics;
  static Companies = DashboardModelCompaniesModel;
  static CommitmentContracts = DashboardModelCommitmentContracts;
  static PublicDashboard = DashboardModelPublicDashboardModel;

  docs!: {
    customization: CustomizationDoc;
    licenseChart: LicenseChartDoc;
    "google-cloud-reports": GoogleCloudReportsDoc;
    widgets: WidgetDoc;
    assetsRenewals: AssetsRenewalsDoc;
    "invoices-latest": InvoicesLatestDoc;
    "invoices-overdue": InvoicesOverdueDoc;
    azureGraph: AzureGraphDoc;
    "ticket-statistics": DashboardModelTicketStatisticsDoc;
    "commitment-contracts": CommitmentContractsDoc;
    home: HomeDoc;
  };
}
