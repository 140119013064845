import { collection } from "@doitintl/models-types";

@collection("gSlack")
export class GSlackModel {
  disabled!: boolean;
  test!: string;
  message!: string;
  attachments!: any[] | null;
  channel!: string;
}
