import { Reference, Timestamp, subCollection } from "@doitintl/models-types";
import { CustomerModel, CustomerModelOrganizationModel } from "./Customer";
import { OrganizationsModel } from "./Organizations";
import { Metadata } from "./Dashboard";

@subCollection("reportOrgMetadata")
export class ReportOrgMetadataModel {
  cloud!: string | string[];
  customer!: Reference<CustomerModel> | null;
  disableRegexpFilter!: boolean;
  field!: string;
  id!: string;
  key!: string;
  label!: string;
  nullFallback!: string | null;
  order!: number;
  organization!: Reference<OrganizationsModel> | Reference<CustomerModelOrganizationModel>;
  plural!: string;
  subType!: Metadata | "";
  timestamp!: Timestamp;
  type!: Metadata | "";
  values!: string[] | null;
}
