import React, { createContext, useContext, useEffect, useState } from "react";
import { getCollection, ModelIdRef } from "@doitintl/models-firestore";
import { CustomerModel, CustomerModelCloudConnectModel } from "@doitintl/cmp-models";
import { Customer } from "../../types";
import { arrayFromDocChange } from "./arrayFromDocChange";

export type CloudConnectType = ModelIdRef<CustomerModelCloudConnectModel>[] | undefined;
const cloudConnectContext = createContext<CloudConnectType>([]);

export const CloudConnectProvider = ({
  children,
  customer,
}: {
  children?: React.ReactNode;
  customer: Customer | undefined | null;
}) => {
  const [cloudConnect, setCloudConnect] = useState<ModelIdRef<CustomerModelCloudConnectModel>[]>();

  useEffect(() => {
    if (!customer?.id) {
      return;
    }

    return getCollection(CustomerModel)
      .doc(customer.id)
      .collection("cloudConnect")
      .onSnapshot((querySnapshot) => {
        setCloudConnect((prev) => {
          const newCloudConnect = [...(prev ?? [])];
          arrayFromDocChange(newCloudConnect, querySnapshot, (doc) => ({
            id: doc.id,
            ref: doc.modelRef,
            ...doc.asModelData(),
          }));
          return newCloudConnect;
        });
      });
  }, [customer?.id]);

  return <cloudConnectContext.Provider value={cloudConnect}>{children}</cloudConnectContext.Provider>;
};

export const useCloudConnectContext = () => useContext(cloudConnectContext);
