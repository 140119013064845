import React from "react";
import axios, { AxiosInstance } from "axios";
import { getDisplayName } from "recompose";
import { globalText } from "../assets/texts";
import { useAuthContext } from "../Context/AuthContext";
import { useSnackbar } from "../Components/SharedSnackbar/SharedSnackbar.context";
import { initAxiosClient } from "./axiosClient";

const instance = axios.create({
  baseURL: "/api",
});

const apiContext = React.createContext(instance);

export function useApiContext(): AxiosInstance {
  return React.useContext(apiContext);
}

export const ApiContextProvider = ({ children }: { children?: React.ReactNode }) => {
  const { currentUser } = useAuthContext();
  const snackbar = useSnackbar();

  const getToken = React.useCallback(
    async (forceRefresh?: boolean) => currentUser?.getIdTokenResult(forceRefresh),
    [currentUser]
  );

  const onTokenExpired = React.useCallback(async () => {
    snackbar.onOpen({
      message: globalText.AUTH_TOKEN_EXPIRED,
      variant: "info",
      autoHideDuration: 30000,
      withClose: true,
    });
  }, [snackbar]);

  React.useEffect(
    () =>
      initAxiosClient(instance, {
        getToken,
        onTokenExpired,
      }),
    [getToken, onTokenExpired]
  );

  return <apiContext.Provider value={instance}>{children}</apiContext.Provider>;
};

export const ApiContextConsumer = apiContext.Consumer;

export const ApiContextProviderForTesting = ({
  children,
  value,
}: {
  children?: React.ReactNode;
  value?: Partial<AxiosInstance>;
}) => (
  // the Provider gives access to the context to its children

  <apiContext.Provider value={(value || instance) as AxiosInstance}>{children}</apiContext.Provider>
);

type Props = {
  api: AxiosInstance;
};

export type WithApi = Props;

export function withApi<P extends object>(Component: React.ComponentType<P & Props>) {
  const WrappedComponent: React.FC<P> = (props) => (
    <ApiContextConsumer>{(context) => <Component api={context} {...props} />}</ApiContextConsumer>
  );

  WrappedComponent.displayName = `withApi(${getDisplayName(WrappedComponent)})`;

  return WrappedComponent;
}
